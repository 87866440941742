import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Avatar, Grid, Typography, Select, Box, MenuItem } from '@mui/material'
import moment from 'moment'
import { WORKSPACE_ANALYTICS_SUMMARY, WORKSPACE_ANALYTICS_DAILY_REPORT } from '../../../shared/utils/urls'
import { useGetRequest } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import ModalHeader from '../../../components/common/ModalHeader'
import WeeklyChart from './WeeklyChart'
import RoiList from './RoiList'
import { COLORS } from '../../../shared/utils/colors'
import { CalendarIcon } from '../../../components/svgs/Svgs'

const periods = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
]

export default function AnalyticsModal({ item, modalRef }) {
    const { workspaceFilter } = useFilter()
    const fullName = `${item.identity.firstName} ${item.identity.lastName}`
    const { email, photo } = item.identity
    const modalTitle = `Analytics > ${fullName}`

    // by default we show current week (start from -7 days) in format YYYY-MM-DD
    // and end to current day
    const [startPeriod, setStartPeriod] = useState(moment().subtract(6, 'days'))
    const [endPeriod, setEndPeriod] = useState(moment())

    const [totalWorkTime, setTotalWorkTime] = useState(0)
    const [totalServiceTime, setTotalServiceTime] = useState(0)

    const getSummary = useGetRequest({ url: WORKSPACE_ANALYTICS_SUMMARY,
        params: { identity_id: item.identity.id,
            start_period: moment(startPeriod).format('YYYY-MM-DD'),
            end_period: moment(endPeriod).format('YYYY-MM-DD'),
            limit: 10 } })
    const data = getSummary.response ? getSummary.response : []

    const getDaily = useGetRequest({ url: WORKSPACE_ANALYTICS_DAILY_REPORT,
        params: { identity_id: item.identity.id,
            start_period: moment(startPeriod).format('YYYY-MM-DD'),
            end_period: moment(endPeriod).format('YYYY-MM-DD'),
            limit: 10 } })
    const dataDaily = getDaily.response ? getDaily.response : []

    useEffect(() => {
        getSummary.request()
        getDaily.request()
    }, [workspaceFilter, startPeriod, endPeriod])

    useEffect(() => {
        if (!data && !data.analyticsReport) return
        const analytics = data.analytics || []
        const calculateTotal = (key) => analytics.reduce((acc, curr) => {
            if (curr.roi.workspaceType === key) {
                return acc + curr.minutesInsideRoi
            }
            return acc
        }, 0)
        const formatValue = (value) => (value % 1 === 0 ? value.toFixed(0) : value.toFixed(2))
        const totalWorkTimeCount = calculateTotal('worker')
        const totalServiceTimeCount = calculateTotal('client')
        setTotalWorkTime(formatValue(totalWorkTimeCount))
        setTotalServiceTime(formatValue(totalServiceTimeCount))
    }, [data])
    return (
        <div className={css(s.modalWrapper)}>
            <ModalHeader title={modalTitle} onClose={() => modalRef.current.close()} />
            <div className={css(s.modalBody)}>
                <Grid container spacing={2}>
                    <Grid item md={7} xs={12}>
                        <div className={css(s.filterWrapper, s.seperateItem)}>
                            <FilterSelect setStartPeriod={setStartPeriod} setEndPeriod={setEndPeriod} />
                        </div>
                        <div className={css(s.list, s.seperateItem)}>
                            <RoiList data={data} />
                        </div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={css(s.identity, s.seperateItem)}>
                            <Avatar alt={fullName} src={photo} sx={{ width: '70px', height: '70px' }} />
                            <Typography sx={{ fontWeight: 600,
                                fontSize: '16px',
                                margin: '0 10px 0 5px' }}>
                                {fullName}
                            </Typography>
                            <Typography sx={{ fontWeight: 400,
                                fontSize: '14px',
                                margin: '0 10px 0 5px' }}>
                                {email}
                            </Typography>
                            <div className={css(s.statistics)}>
                                <div className={css(s.statisticsItem)}>
                                    <Typography className={css(s.statisticsValue)}>{totalWorkTime} min</Typography>
                                    <Typography className={css(s.statisticsTitle)}>Total work time</Typography>
                                </div>
                                <div className={css(s.statisticsItem)}>
                                    <Typography className={css(s.statisticsValue)}>{totalServiceTime} min</Typography>
                                    <Typography className={css(s.statisticsTitle)}>Total service time</Typography>
                                </div>
                            </div>
                        </div>

                        <div className={css(s.seperateItem, s.chart)}>
                            <WeeklyChart data={dataDaily} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function FilterSelect({ setStartPeriod, setEndPeriod }) {
    const handleChange = (event) => {
        const period = event.target.value
        if (period === 'weekly') {
            setStartPeriod(moment().subtract(6, 'days'))
            setEndPeriod(moment())
        } else if (period === 'monthly') {
            setStartPeriod(moment().subtract(29, 'days'))
            setEndPeriod(moment())
        }
    }
    return (
        <div>
            <Select
                sx={{
                    width: 'auto',
                    borderRadius: '5px',
                    padding: '0px',
                    fontSize: '14px',
                    backgroundColor: COLORS.mainBlueBg,
                    border: 'none',
                    color: COLORS.white,
                    '.MuiSelect-icon': {
                        color: COLORS.white,
                    },
                }}
                size="small"
                defaultValue="weekly"
                displayEmpty
                renderValue={(value, label) => (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CalendarIcon />
                        {value}
                        {label}
                    </Box>
                )}
                onChange={handleChange}
            >
                {periods.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{
                        backgroundColor: COLORS.mainBlueBg,
                        color: COLORS.white,
                        fontSize: '14px',
                        // selected
                        '&.Mui-selected': {
                            backgroundColor: COLORS.mainBlue,
                            '&:hover': {
                                backgroundColor: COLORS.mainBlue,
                            },
                        },
                        // hover
                        '&:hover': {
                            backgroundColor: COLORS.mainBlue,
                        },
                    }}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

const s = StyleSheet.create({
    modalWrapper: {
        width: '60vw',
        maxWidth: '80vw',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    modalBody: {
        padding: '0 30px 20px 30px',
    },
    seperateItem: {
        border: '1px solid #EEE',
        borderRadius: '5px',
    },
    filterWrapper: {
        padding: '7px',
    },
    list: {
        marginTop: '14px',
    },
    identity: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    statistics: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
    },
    statisticsItem: {
        borderRight: '1px solid #EEE',
        textAlign: 'center',
        padding: '0 14px',
        // last-child
        ':nth-child(2)': {
            borderRight: 'none',
        },
    },
    statisticsTitle: {
        fontWeight: 400,
        fontSize: '10px',
    },
    statisticsValue: {
        fontWeight: 500,
        fontSize: '16px',
        marginBottom: '4px',
    },
    chart: {
        width: '100%',
        height: '210px',
        marginTop: '14px',
    },
})
