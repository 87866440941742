import React, { useState, useCallback } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { usePostRequest } from '../../../shared/hooks/requests'
import { CUSTOMER_USER_ACTIVATION_LINK, IDENTITIES, TENANT_USER } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import EntityUserForm from '../components/EntityUserForm'
import ActivationLinkModal from '../../../shared/ui/ActivationLinkModal'

const POSTURL = {
    users: TENANT_USER.replace('{id}', ''),
    identity: IDENTITIES.replace('{id}', ''),
}

export default function Create({ setData, type }) {
    const { id } = useParams()
    const [activationLink, setActivationLink] = useState('')
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [open, setOpen] = useState(false)

    const createUser = usePostRequest({ url: POSTURL[type] })
    const requestCode = usePostRequest({ url: CUSTOMER_USER_ACTIVATION_LINK })

    const onClose = () => {
        setOpen(false)
        setActivationLink('')
    }

    const handleSuccess = useCallback(
        async (response) => {
            if (type === 'users') {
                const { success, response: codeResponse } = await requestCode.request({
                    params: { userId: response.id },
                })
                if (success) {
                    setActivationLink(`${window.location.origin}/new-password?code=${codeResponse.code}`)
                    setData((prev) => ({
                        ...prev,
                        items: [...(prev.items || []), response],
                    }))
                }
                return
            }
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response],
            }))
            showMessage('Successfully added', 'success-msg')
            onClose()
        },
        [requestCode, setData, showMessage, type],
    )

    const handleCreate = useCallback(
        async (values) => {
            if (createUser.loading || !values) return

            const { success, response, error } = await createUser.request({ data: { ...values, tenantEntityId: +id } })

            if (success) {
                handleSuccess(response)
            } else if (error) {
                handleErrorMsg(error)
            }
        },
        [createUser, handleSuccess, id, handleErrorMsg],
    )

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={onClose}>
                <div className="flex items-center justify-between pl-3 py-1 pr-1
                 bg-main-100 text-white text-lg font-medium">
                    <h3>Create {type === 'users' ? 'User' : 'Identity'}</h3>

                    <IconButton onClick={onClose}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    {activationLink ? (
                        <ActivationLinkModal url={activationLink} onClose={onClose} />
                    ) : (
                        <EntityUserForm
                            isUser={type === 'users'}
                            onClose={onClose}
                            onSubmit={handleCreate}
                            loading={createUser.loading} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}
