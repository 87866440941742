/* eslint-disable */
import React, { useEffect } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment/moment'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ALLOWED_REGIONS, DISTRICTS_LIST } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const uniqueOptions = [
    {
        value: 'asceding',
        label: 'asceding',
    },
    {
        value: 'descending',
        label: 'descending',
    },
]

const sortRole = [
    {
        value: 'employee',
        label: 'employee',
    },
    {
        value: 'kid',
        label: 'kid',
    },
]

const sortQuantity = [
    {
        value: 'count',
        label: 'count',
    },
    {
        value: 'ratio',
        label: 'ratio',
    },
]

// eslint-disable-next-line import/prefer-default-export
export function AttendanceAnalyticsFilter() {
    const { attendFilter, setAttendFilters } = useFilter()
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: attendFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []

    const regionsOption = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOption = districts.map((district) => ({ value: district.id, label: district.name }))

    useEffect(() => {
        if (attendFilter.region) {
            getDistricts.request()
        }
    }, [attendFilter.region])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="w-[100%] flex items-center gap-2">
                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="region-label">Region</InputLabel>
                        <Select
                            labelId="region-label"
                            id="region-select"
                            label="Region"
                            disabled={!regionsOption.length}
                            value={attendFilter.region}
                            onChange={(e) => setAttendFilters({ region: e.target.value })}
                            variant="outlined"
                        >
                            {!loadRegions.loading && regionsOption.length ? regionsOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            )) : loadRegions.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="district-label">District</InputLabel>
                        <Select
                            labelId="district-label"
                            id="district-select"
                            label="Region"
                            disabled={!districtOption.length}
                            value={attendFilter.district}
                            onChange={(e) => setAttendFilters({ district: e.target.value })}
                            variant="outlined"
                        >
                            {!getDistricts.loading && districtOption.length ? districtOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            )) : loadRegions.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>
                    </FormControl>

                    <div className="w-[150px]">
                        <DatePicker
                            label="End Date"
                            sx={{
                                height: 37,
                                width: '150px',
                                '& .MuiInputBase-root': { height: 37 },
                                '& .MuiFormLabel-root': { top: '-8px' },
                            }}
                            inputFormat="DD-MM-YYYY"
                            value={attendFilter.date ? moment(attendFilter.date) : null}
                            onChange={(e) => {
                                const formattedEndDate = e ? moment(e).format('YYYY-MM-DD') : null
                                setAttendFilters({ date: formattedEndDate })
                            }}
                            // renderInput={(params) => <TextField {...params} />}
                            initialFocusedDate={null}
                        />
                    </div>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="attendance_sort_type">sort_type</InputLabel>
                        <Select
                            labelId="attendance_sort_type"
                            id="sort_type"
                            label="sort_type"
                            disabled={!uniqueOptions.length}
                            value={attendFilter.sort_type}
                            onChange={(e) => setAttendFilters({ sort_type: e.target.value })}
                            variant="outlined"
                        >
                            {uniqueOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="sort_role">sort_role</InputLabel>
                        <Select
                            labelId="sort_role"
                            id="sort_role"
                            label="sort_role"
                            disabled={!sortRole.length}
                            value={attendFilter.sort_role}
                            onChange={(e) => setAttendFilters({ sort_role: e.target.value })}
                            variant="outlined"
                        >
                            {sortRole.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="sort_quantity">sort_quantity</InputLabel>
                        <Select
                            labelId="sort_quantity"
                            id="sort_quantity"
                            label="sort_quantity"
                            disabled={!sortQuantity.length}
                            value={attendFilter.sort_quantity}
                            onChange={(e) => setAttendFilters({ sort_quantity: e.target.value })}
                            variant="outlined"
                        >
                            {sortQuantity.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="flex flex-col items-start gap-[6px]">
                        <input
                            type="text"
                            className="w-[150px] h-[37px] border p-[10px] rounded-[6px]"
                            placeholder="Comp score"
                            value={attendFilter.ratio_from}
                            onChange={(e) => setAttendFilters({ ratio_from: e.target.value })}
                        />
                    </div>

                    {attendFilter.ratio_from !== '' ? (
                        <div className="flex flex-col items-start gap-[6px]">
                            <input
                                type="text"
                                className="w-[150px] h-[37px] border p-[10px] rounded-[6px]"
                                placeholder="00"
                                value={attendFilter.ratio_to}
                                onChange={(e) => setAttendFilters({ ratio_to: e.target.value })}
                            />
                        </div>
                    ) : null}
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
