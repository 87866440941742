import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Box, Select, MenuItem } from '@mui/material'
import moment from 'moment'
import { LineChart } from '@mui/x-charts/LineChart'
import ModalHeader from '../../../components/common/ModalHeader'
import { COLORS } from '../../../shared/utils/colors'
import { CalendarIcon } from '../../../components/svgs/Svgs'

const periods = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
]

const dataset = [
    { x: '17:01', series1: 0, series2: 10 },
    { x: '17:02', series1: 5.5, series2: 18 },
    { x: '17:03', series1: 2, series2: 14 },
    { x: '17:04', series1: 8.5, series2: 10 },
    { x: '17:05', series1: 1.5, series2: 14 },
    { x: '17:06', series1: 5, series2: 22 },
    { x: '17:07', series1: 19, series2: 21 },
]

export default function AnalyticsModal({ item, modalRef }) {
    const [startPeriod, setStartPeriod] = useState(moment().subtract(7, 'days'))
    const [endPeriod, setEndPeriod] = useState(moment())

    const fullName = `${item.identity.firstName} ${item.identity.lastName}`
    const modalTitle = `Analytics > ${fullName}`

    useEffect(() => {
        console.log('Period changed', startPeriod, endPeriod)
    }, [startPeriod, endPeriod])
    return (
        <div className={css(s.modalWrapper)}>
            <ModalHeader title={modalTitle} onClose={() => modalRef.current.close()} />
            <div className={css(s.modalBody)}>
                <div className={css(s.filterWrapper, s.seperateItem)}>
                    <FilterSelect setStartPeriod={setStartPeriod} setEndPeriod={setEndPeriod} />
                </div>
                <div className={css(s.chartWrapper)}>
                    <LineChart
                        xAxis={[{ dataKey: 'x', scaleType: 'point' }]}
                        grid={{ horizontal: true }}
                        series={[
                            {
                                id: 'series1',
                                dataKey: 'series1',
                                label: 'Series 1',
                                color: '#F94144',
                                area: true,
                            },
                            {
                                id: 'series2',
                                dataKey: 'series2',
                                label: 'Series 2',
                                color: '#4A3AFF',
                                area: true,
                            },
                        ]}
                        sx={{
                            '& .MuiAreaElement-series-series1': {
                                stroke: '#F94144',
                                fill: 'rgba(255, 0, 0, 0.2)',
                                strokeWidth: 2,
                            },
                            '& .MuiAreaElement-series-series2': {
                                stroke: '#4A3AFF',
                                fill: 'rgba(0, 0, 255, 0.1)',
                                strokeWidth: 2,
                            },
                        }}
                        slotProps={{
                            legend: {
                                position: { vertical: 'bottom', horizontal: 'center' },
                                padding: 0,
                                itemMarkWidth: 8,
                                itemMarkHeight: 8,
                                itemMarkStyle: {
                                    borderRadius: '50%',
                                },
                                labelStyle: {
                                    fontSize: 16,
                                },
                            },
                        }}
                        dataset={dataset}
                    />
                </div>
            </div>
        </div>
    )
}

function FilterSelect({ setStartPeriod, setEndPeriod }) {
    const handleChange = (event) => {
        const period = event.target.value
        if (period === 'weekly') {
            setStartPeriod(moment().subtract(7, 'days'))
            setEndPeriod(moment())
        } else if (period === 'monthly') {
            setStartPeriod(moment().subtract(30, 'days'))
            setEndPeriod(moment())
        }
    }
    return (
        <div>
            <Select
                sx={{
                    width: 'auto',
                    borderRadius: '5px',
                    padding: '0px',
                    fontSize: '14px',
                    backgroundColor: COLORS.mainBlueBg,
                    border: 'none',
                    color: COLORS.white,
                    '.MuiSelect-icon': {
                        color: COLORS.white,
                    },
                }}
                size="small"
                defaultValue="weekly"
                displayEmpty
                renderValue={(value, label) => (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CalendarIcon />
                        {value}
                        {label}
                    </Box>
                )}
                onChange={handleChange}
            >
                {periods.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                            backgroundColor: COLORS.mainBlueBg,
                            color: COLORS.white,
                            fontSize: '14px',
                            '&.Mui-selected': {
                                backgroundColor: COLORS.mainBlue,
                                '&:hover': {
                                    backgroundColor: COLORS.mainBlue,
                                },
                            },
                            '&:hover': {
                                backgroundColor: COLORS.mainBlue,
                            },
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

const s = StyleSheet.create({
    modalWrapper: {
        width: '100vh',
        maxWidth: '1050px',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    modalBody: {
        padding: '0 30px 20px 30px',
    },
    seperateItem: {
        border: '1px solid #EEE',
        borderRadius: '5px',
    },
    filterWrapper: {
        padding: '7px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    chartWrapper: {
        width: '100%',
        height: '50vh',
        marginTop: '14px',
    },
})
