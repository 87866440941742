/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    attendances: {
        0: [], // дети
        1: [], // сотрудники
        2: [], // посетители
        3: [], // разыскиваемые
    },
}

const realTimeMapSlice = createSlice({
    name: 'realTimeMap',
    initialState,
    reducers: {
        addAttendances: (state, action) => {
            const attendancesArray = action.payload
            attendancesArray.forEach((_attendance) => {
                const { attendance } = _attendance
                console.log(attendance)
                const { identity } = attendance

                // Используем имена свойств в snake_case
                const identityGroup = identity.identity_group
                const isWanted = identity.is_wanted

                let groupKey = identityGroup

                if (isWanted) {
                    groupKey = 3 // Присваиваем группу 3, если человек разыскивается
                }

                // Проверяем, что groupKey определен и является допустимым
                if (groupKey === undefined || groupKey === null) {
                    console.warn(`Undefined groupKey for attendance id ${attendance.id}`)
                    return // Пропускаем этот элемент
                }

                // Убеждаемся, что массив для этой группы существует
                if (!state.attendances[groupKey]) {
                    state.attendances[groupKey] = []
                }

                // Проверяем на дубликаты по `attendance.id`
                const existingIndex = state.attendances[groupKey].findIndex(
                    (item) => item.id === attendance.id,
                )

                if (existingIndex === -1) {
                    state.attendances[groupKey].push(attendance)
                } else {
                    state.attendances[groupKey][existingIndex] = attendance
                }
            })
        },
    },
})

export const { addAttendances } = realTimeMapSlice.actions

export default realTimeMapSlice.reducer
