/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next'
import ProductModal from '../../components/ProductModal'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function BusinessCard({ data }) {
    const { img, title, text, demo, id } = data
    const { t } = useTranslation()
    return (
        <div className="flex flex-col  gap-[20px] w-auto h-auto">
            <div className={`w-auto ${img === null ? 'bg-[#F4F4F4]'
                + ' dark:bg-[#515151]' : ''} h-[278px] overflow-hidden`}
            >
                {img !== null && (
                    <img
                        src={img}
                        loading="lazy"
                        alt="img of the mine"
                        className="w-[100%] h-[100%] object-cover"
                    />
                )}
            </div>
            <div className="flex flex-col items-start gap-[30px]">
                <div className="flex flex-col items-start gap-[6px]">
                    <span className="lg:text-[25px] sm:text-[18px] font-bold text-black dark:text-white">{title}</span>
                    <span className="lg:text-[18px] sm:text-[14px]
                    font-normal text-[#525252] dark:text-white"
                    >
                        {text}
                    </span>
                </div>
                <div className="flex flex-row gap-[10px]">
                    <ProductModal id={id} />
                    {demo && (
                        <button
                            type="button"
                            className="px-[25px] py-[10px] border border-[#0068B5]
         hover:bg-transparent hover:text-[#0068B5] dark:text-black dark:bg-white dark:hover:bg-transparent
         dark:hover:text-white dark:border-white transition-all duration-500 bg-[#0068B5] text-white"
                            onClick={() => window.open(demo, '_blank')}
                        >
                            {t('btn.demo')}
                        </button>
                    )}
                </div>

            </div>
        </div>
    )
}
