import React from 'react'
import { Paper } from '@mui/material'
import Filter from './Filter'
import LogItem from './LogItem'

const logs = [
    {
        id: '1',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-01',
    },
    {
        id: '2',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-02',
    },
    {
        id: '3',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-03',
    },
    {
        id: '4',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-03',
    },
    {
        id: '5',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-03',
    },
    {
        id: '6',
        // eslint-disable-next-line max-len, quotes
        log: `2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host')) 1.2024-06-10 00:00:02,873 - main - ERROR - Server Error: HTTPConnectionPool(host='192.168.1.107', port=8008): Max retries exceeded with url: /jetson/tasks (Caused by NewConnectionError('<urllib3.connection.HTTPConnection object at 0xffff81305d90>: Failed to establish a new connection: [Errno 113] No route to host'))`,
        date: '2021-08-03',
    },
]

export default function LogList() {
    return (
        <div>
            <Filter />
            <Paper square className="flex flex-col gap-2.5 p-[20px] mt-[17px]">
                {logs.map((log) => (
                    <LogItem key={log.id} item={log} />
                ))}
            </Paper>
        </div>
    )
}
