/* eslint-disable */
import React, { useEffect } from 'react'
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material'
import { css, StyleSheet } from 'aphrodite'
import Skeleton from 'react-loading-skeleton'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import {
    DISTRICTS_LIST,
    ALLOWED_REGIONS,
} from '../../../shared/utils/urls'

export default function AttendanceAnalyticsFilter() {
    const {
        mapFilter,
        setMapFilters
    } = useFilter()
    const loadRegions = useLoad({
        url: ALLOWED_REGIONS,
        params: { country_id: '1' }
    }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({
        url: DISTRICTS_LIST,
        params: { regionId: mapFilter.region }
    })
    const districts = getDistricts.response ? getDistricts.response : []

    const regionsOption = regions.map((region) => ({
        value: region.id,
        label: region.name
    }))
    const districtOption = districts.map((district) => ({
        value: district.id,
        label: district.name
    }))

    useEffect(() => {
        if (mapFilter.region) {
            getDistricts.request()
        }
    }, [mapFilter.region])

    return (
        <div className={css(s.filter)}>
            <h3 className={css(s.title)}><span className={css(s.blue)}>Hududlar bo'yicha</span> kameralar orqali kuzatuv va tahlillar</h3>
            <div className={'w-[100%] flex items-center gap-5'}>
                <FormControl
                    sx={{
                        minWidth: 150,
                        background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.2) 10%, rgba(0, 0, 0, 0.7) 100%)',
                        backdropFilter: 'blur(5px)',
                        border: '0.5px solid #00FCFC',
                        '& .MuiInputBase-root': {
                            color: '#fff',
                        },
                        '& .MuiSelect-icon': {
                            color: '#D4D4D4',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    size="small"
                >
                    <InputLabel
                        id="district-label"
                        sx={{
                            color: '#fff',
                            fontSize: '1rem',
                            top: 0,
                            background: 'transparent',
                            '&.Mui-focused': {
                                color: '#00FCFC',
                                top: '-12px',
                            },
                            '&.MuiInputLabel-shrink': {
                                top: '-12px',
                            },
                        }}
                    >
                        Region
                    </InputLabel>
                    <Select
                        labelId="region-label"
                        id="region-select"
                        label="Region"
                        disabled={!regionsOption.length}
                        value={mapFilter.region}
                        onChange={(e) => setMapFilters({ region: e.target.value, district: '' })}
                        variant="outlined"
                        MenuProps={{
                            disablePortal: true, // Отключаем портал
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            sx: {
                                zIndex: 2000,
                                width: '250px',
                                height: '100px',
                                position: 'absolute',
                                top: '40px',
                                '& .MuiPaper-root': {
                                    background: 'rgba(0, 0, 0, 0.9)',
                                    border: '0.5px solid #00FCFC',
                                    borderRadius: 0,
                                    color: '#fff',
                                    marginTop: '8px',
                                    position: 'absolute',
                                    top: '0!important',
                                    left: '0!important',
                                    minHeight: '300px',
                                },
                                '& .MuiMenuItem-root': {
                                    '&:hover': {
                                        background: '#00FCFC17',
                                    },
                                },
                            },
                        }}
                        sx={{
                            color: '#fff',
                            '& .MuiSelect-select': {
                                padding: '10px',
                            },
                        }}
                    >
                        {!loadRegions.loading && regionsOption.length ? (
                            regionsOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))
                        ) : loadRegions.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30}/>
                            </MenuItem>
                        ) : (
                            <MenuItem value="">Nothing Found</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl
                    sx={{
                        minWidth: 150,
                        background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.2) 10%, rgba(0, 0, 0, 0.7) 100%)',
                        backdropFilter: 'blur(10px)',
                        border: '0.5px solid #00FCFC',
                        '& .MuiInputBase-root': {
                            color: '#fff',
                        },
                        '& .MuiSelect-icon': {
                            color: '#00FCFC',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    size="small"
                >
                    <InputLabel
                        id="district-label"
                        sx={{
                            color: '#fff',
                            fontSize: '1rem',
                            top: 0,
                            background: 'transparent',
                            '&.Mui-focused': {
                                color: '#00FCFC',
                                top: '-12px',
                            },
                            '&.MuiInputLabel-shrink': {
                                top: '-12px',
                            },
                        }}
                    >
                        District
                    </InputLabel>
                    <Select
                        labelId="district-label"
                        id="district-select"
                        label="District"
                        disabled={!districtOption.length}
                        value={mapFilter.district}
                        onChange={(e) => setMapFilters({ district: e.target.value })}
                        variant="outlined"
                        MenuProps={{
                            disablePortal: true, // Отключаем портал
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            sx: {
                                zIndex: 2000,
                                width: '250px',
                                height: '100px',
                                position: 'absolute',
                                top: '40px',
                                '& .MuiPaper-root': {
                                    background: 'rgba(0, 0, 0, 0.9)',
                                    border: '0.5px solid #00FCFC',
                                    borderRadius: 0,
                                    color: '#fff',
                                    marginTop: '8px',
                                    position: 'absolute',
                                    top: '0!important',
                                    left: '0!important',
                                    minHeight: '300px',
                                },
                                '& .MuiMenuItem-root': {
                                    '&:hover': {
                                        background: '#00FCFC17',
                                    },
                                },
                            },
                        }}
                        sx={{
                            color: '#fff',
                            '& .MuiSelect-select': {
                                padding: '10px',
                            },
                        }}
                    >
                        {!getDistricts.loading && districtOption.length ? (
                            districtOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))
                        ) : getDistricts.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30}/>
                            </MenuItem>
                        ) : (
                            <MenuItem value="">Nothing Found</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    filter: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '30px',
        left: '30px',
    },
    title: {
        color: '#fff',
        fontWeight: 600,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        marginBottom: '30px',
        display: 'inline-block',
        background: 'linear-gradient(to right, rgba(0, 0, 0, 0.0) 10%, rgba(0, 252, 252, 0.2) 30%, rgba(0, 252, 252, 0.2) 70%, rgba(0, 0, 0, 0.0) 90%)',
    },
    blue: {
        color: '#00FCFC',
    }
})
