import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import mapboxgl from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { addAttendances } from '../../../shared/store/realTimeMapSlice'
import { CameraMapTag } from '../../../components/svgs/Svgs'
import { useLoad } from '../../../shared/hooks/requests'
import { SCAMERA_LIST_MAP, ATTENDANCE_LIVE } from '../../../shared/utils/urls'
import useWebSocket from '../../../shared/hooks/websocket'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const createCameraIcon = () => {
    const svgElement = CameraMapTag({ color: '#00FCFC' })
    const svgString = ReactDOMServer.renderToStaticMarkup(svgElement)
    const img = new Image()
    img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`

    return new Promise((resolve, reject) => {
        img.onload = () => resolve(img)
        img.onerror = (err) => reject(err)
    })
}

function FullScreenMap() {
    const dispatch = useDispatch()
    const { mapFilter } = useFilter()
    const params = {
        country_id: '1',
        region_id: mapFilter?.region,
        district_id: mapFilter?.district,
    }

    const filteredParams = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null && v !== ''),
    )
    const mapContainerRef = useRef(null)
    const mapRef = useRef(null)
    const [mapLoaded, setMapLoaded] = useState(false)
    const { filteredData: socketData = [] } = useWebSocket(
        process.env.REACT_APP_BASE_URL + ATTENDANCE_LIVE,
        filteredParams,
    )
    const getCameras = useLoad({ url: SCAMERA_LIST_MAP, params: filteredParams }, [mapFilter])
    const sCameras = getCameras.response || []

    useEffect(() => {
        if (!socketData.length) return

        dispatch(addAttendances(socketData))
    }, [socketData])

    // Получаем данные посещений из Redux store
    const attendances = useSelector((state) => state.attendances?.attendances)
    const [newAttendances, setNewAttendances] = useState([])
    const prevAttendancesRef = useRef([])

    // Отслеживаем новые посещения
    useEffect(() => {
        if (!attendances) return

        // Собираем все посещения в один массив
        let data = []
        if (attendances) {
            data = Object.values(attendances).flat()
        }

        // Определяем новые посещения
        const prevData = prevAttendancesRef.current || []
        const prevIds = new Set(prevData.map((item) => item.id))
        const newItems = data.filter((item) => !prevIds.has(item.id))

        if (newItems.length > 0) {
            setNewAttendances(newItems)
        }

        // Обновляем предыдущие данные
        prevAttendancesRef.current = data
    }, [attendances])

    // Анимация новых посещений на карте
    useEffect(() => {
        if (!mapRef.current || !mapLoaded || newAttendances.length === 0) return

        const map = mapRef.current

        newAttendances.forEach((attendance) => {
            const { identity } = attendance
            const lat = attendance.lat || attendance.latitude
            const lon = attendance.lon || attendance.longitude

            if (!lat || !lon) {
                console.warn(`Attendance ${attendance.id} missing coordinates.`)
                return
            }

            // Создаем HTML-элемент с изображением
            const imgElement = document.createElement('img')
            imgElement.src = identity.photo
            imgElement.className = 'map-attendance-image' // Добавим класс для стилизации
            imgElement.style.position = 'absolute'
            imgElement.style.opacity = '0' // Начальное состояние для fade-in
            imgElement.style.transition = 'opacity 1s ease' // Настраиваем анимацию

            // Преобразуем координаты в пиксельные значения
            const point = map.project([lon, lat])

            // Устанавливаем позицию элемента
            imgElement.style.left = `${point.x}px`
            imgElement.style.top = `${point.y}px`

            // Добавляем элемент в контейнер карты
            map.getCanvasContainer().appendChild(imgElement)

            // Применяем анимацию появления
            requestAnimationFrame(() => {
                imgElement.style.opacity = '1'
            })

            // Через 3 секунды применяем анимацию исчезновения и удаляем элемент
            setTimeout(() => {
                imgElement.style.opacity = '0'

                // Удаляем элемент после завершения анимации
                setTimeout(() => {
                    imgElement.remove()
                }, 1000) // Длительность анимации исчезновения
            }, 3000) // Время до начала исчезновения
        })

        // Очистим список новых посещений после обработки
        setNewAttendances([])
    }, [newAttendances, mapLoaded])

    // Инициализируем карту один раз
    useEffect(() => {
        if (mapRef.current) return

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/sanchesq/cm3o3ak2j00v301se7qhqa32e',
            center: [64.5853, 41.3775],
            zoom: 5,
            maxBounds: [
                [55.0, 37.0],
                [75.0, 45.0],
            ],
            attributionControl: false,
        })

        mapRef.current = map

        map.on('load', async () => {
            try {
                const cameraIcon = await createCameraIcon()
                map.addImage('camera-icon', cameraIcon, { pixelRatio: 1.5 })

                map.addSource('cameras', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                })

                // Слои
                map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'cameras',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#00FCFC',
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40,
                        ],
                    },
                })

                map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'cameras',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 16,
                    },
                    paint: {
                        'text-color': '#00FCFC',
                    },
                })

                map.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'cameras',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': 'camera-icon',
                        'icon-size': 1,
                    },
                })

                // Обработчики событий
                map.on('click', 'clusters', (e) => {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: ['clusters'],
                    })
                    const clusterId = features[0].properties.cluster_id
                    map.getSource('cameras').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return

                        map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom,
                        })
                    })
                })

                map.on('mouseenter', 'clusters', () => {
                    map.getCanvas().style.cursor = 'pointer'
                })
                map.on('mouseleave', 'clusters', () => {
                    map.getCanvas().style.cursor = ''
                })

                setMapLoaded(true)
            } catch (error) {
                console.error('Failed to create or add the camera icon:', error)
            }
        })

        // eslint-disable-next-line consistent-return
        return () => map.remove()
    }, [])

    // Обновляем данные на карте при изменении sCameras
    useEffect(() => {
        if (!mapRef.current || !mapLoaded) return

        const map = mapRef.current

        const cameraFeatures = sCameras
            .filter((camera) => {
                const lat = camera.deviceLat
                const lng = camera.deviceLong

                return (
                    lat >= 37.0 && lat <= 45.0 // Широта Узбекистана
                    && lng >= 55.0 && lng <= 75.0 // Долгота Узбекистана
                )
            })
            .map((camera) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [camera.deviceLong, camera.deviceLat],
                },
                properties: {
                    id: camera.id,
                    tenant_id: camera.tenant_id,
                },
            }))

        if (!cameraFeatures.length) {
            console.warn('Нет допустимых камер для отображения на карте.')
            map.getSource('cameras').setData({
                type: 'FeatureCollection',
                features: [],
            })
            return
        }

        map.getSource('cameras').setData({
            type: 'FeatureCollection',
            features: cameraFeatures,
        })
    }, [sCameras, mapLoaded])

    return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
}

export default FullScreenMap
