/* eslint-disable */
import React, { useState } from 'react'
import moment from 'moment'
import { Box, Chip, Modal, TableCell, Tooltip } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import noPhoto from '../../../static/images/no-photo.png'

// eslint-disable-next-line react/prop-types
export default function IdentityInfoTableItem({ item, cameraCount }) {
    const [open, setOpen] = useState(false)
    const [modalImage, setModalImage] = useState(null)

    const errors = item.errors || []

    const handleOpen = (image) => {
        setModalImage(image)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setModalImage(null)
    }
    return (
        <>
            <TableCell className="cursor-pointer">
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={item.photo || noPhoto}
                        alt="Main"
                        className="w-[100%] h-[100%] object-cover"
                        onClick={() => handleOpen(item.photo)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell className="cursor-pointer">
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={item.croppedImage512 || noPhoto}
                        alt="Main"
                        className="w-[100%] h-[100%] object-cover"
                        onClick={() => handleOpen(item.croppedImage512)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.id}
            </TableCell>
            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.firstName}
            </TableCell>
            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.lastName}
            </TableCell>
            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] cursor-pointer">
                {item.version}
            </TableCell>
            <TableCell className="cursor-pointer">
                {item.externalId}
            </TableCell>
            <TableCell className="cursor-pointer">
                {item.pinfl || '-'}
            </TableCell>

            <TableCell className="cursor-pointer">
                {item.identitySmartCameras.length}
            </TableCell>
            <TableCell className="cursor-pointer">
                {cameraCount - item.identitySmartCameras.length}
            </TableCell>
            <TableCell className="cursor-pointer">
                {errors.length !== 0 ? (
                    <Tooltip title={errors[0]?.errorMessage}>
                        <Chip
                            key={errors[0]?.id} // Use a unique identifier
                            label={errors[0]?.errorMessage} // Use a string property for the label
                            color="error"
                            style={{ backgroundColor: COLORS.error }}
                        />
                    </Tooltip>
                ) : '-'}
            </TableCell>
            <TableCell className="cursor-pointer">
                {moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
            </TableCell>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="enlarged-image"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        outline: 'none',
                    }}
                >
                    {modalImage && (
                        <img
                            src={modalImage}
                            alt="Enlarged view"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    )}
                </Box>
            </Modal>
        </>
    )
}
