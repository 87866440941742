/* eslint-disable */
import * as React from 'react'
import Modal from '@mui/material/Modal'
import VisionAnalyticsImage from '../../../static/images/onesystemImage.jpg'
import UniPass from '../../../static/images/unipass.webp'
import MLModel from '../../../static/images/AiAdvokat.webp'
import { useTranslation } from 'react-i18next'

export default function ProductModal({ id, xl }) {
    const {t} = useTranslation()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const usData = [
        {
            id: 1,
            image: VisionAnalyticsImage,
            title: t('landing.main.modal.vision.title'),
            heroTitle: t('landing.main.modal.vision.heroTitle'),
            heroText: t('landing.main.modal.vision.heroText'),
            body: [
                {
                    id: 1,
                    title: t('landing.main.modal.vision.body.workspace.title'),
                    heroText: t('landing.main.modal.vision.body.workspace.heroText'),
                    p1: [
                        {
                            title: t('landing.main.modal.vision.body.workspace.p1.title1'),
                            text: t('landing.main.modal.vision.body.workspace.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.vision.body.workspace.p1.title2'),
                            text: t('landing.main.modal.vision.body.workspace.p1.text2'),
                        },
                        {
                            title: t('landing.main.modal.vision.body.workspace.p1.title3'),
                            text: t('landing.main.modal.vision.body.workspace.p1.text3'),
                        },
                    ],
                    conclusion: t('landing.main.modal.vision.body.workspace.conclusion'),
                },
                {
                    id: 2,
                    title: t('landing.main.modal.vision.body.zone.title'),
                    heroText: t('landing.main.modal.vision.body.zone.heroText'),
                    p1: [
                        {
                            title: t('landing.main.modal.vision.body.zone.p1.title1'),
                            text: t('landing.main.modal.vision.body.zone.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.vision.body.zone.p1.title2'),
                            text: t('landing.main.modal.vision.body.zone.p1.text2'),
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            image: UniPass,
            title: t('landing.main.modal.unipass.title'),
            heroTitle: t('landing.main.modal.unipass.heroTitle'),
            heroText: t('landing.main.modal.unipass.heroText'),
            body: [
                {
                    id: 1,
                    title: t('landing.main.modal.unipass.body.features.title'),
                    p1: [
                        {
                            title: t('landing.main.modal.unipass.body.features.p1.title1'),
                            text: t('landing.main.modal.unipass.body.features.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.unipass.body.features.p1.title2'),
                            text: t('landing.main.modal.unipass.body.features.p1.text2'),
                        },
                    ],
                },
                {
                    id: 2,
                    title: t('landing.main.modal.unipass.body.choose.title'),
                    p1: [
                        {
                            title: t('landing.main.modal.unipass.body.choose.p1.title1'),
                            text: t('landing.main.modal.unipass.body.choose.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.unipass.body.choose.p1.title2'),
                            text: t('landing.main.modal.unipass.body.choose.p1.text2'),
                        },
                        {
                            title: t('landing.main.modal.unipass.body.choose.p1.title3'),
                            text: t('landing.main.modal.unipass.body.choose.p1.text3'),
                        },
                    ],
                    conclusion: t('landing.main.modal.unipass.body.choose.conclusion'),
                },
            ],
        },
        {
            id: 3,
            image: MLModel,
            title: t('landing.main.modal.hub.title'),
            heroText: t('landing.main.modal.hub.heroText'),
            body: [
                {
                    id: 1,
                    title: t('landing.main.modal.hub.body.features.title'),
                    p1: [
                        {
                            title:  t('landing.main.modal.hub.body.features.p1.title1'),
                            text:  t('landing.main.modal.hub.body.features.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.hub.body.features.p1.title2'),
                            text: t('landing.main.modal.hub.body.features.p1.text2'),
                        },
                        {
                            title: t('landing.main.modal.hub.body.features.p1.title3'),
                            text: t('landing.main.modal.hub.body.features.p1.text3'),
                        },
                    ],
                },
                {
                    id: 2,
                    title: t('landing.main.modal.hub.body.choose.title'),
                    p1: [
                        {
                            title: t('landing.main.modal.hub.body.choose.p1.title1'),
                            text: t('landing.main.modal.hub.body.choose.p1.text1'),
                        },
                        {
                            title: t('landing.main.modal.hub.body.choose.p1.title2'),
                            text: t('landing.main.modal.hub.body.choose.p1.text2'),
                        },
                        {
                            title: t('landing.main.modal.hub.body.choose.p1.title3'),
                            text: t('landing.main.modal.hub.body.choose.p1.text3'),
                        },
                    ],
                    conclusion: t('landing.main.modal.hub.body.choose.conclusion'),
                },
            ],
        },
    ]

    const dataFind = usData.find((item) => item.id === id)

    return (
        <div>
            <button
                onClick={handleOpen}
                type="button"
                className={`${xl ? 'px-[42px]' : 'px-[25px]'} py-[10px] border border-[#0068B5] mr-[10px]
         hover:bg-[#0068B5] dark:text-white dark:bg-transparent dark:hover:bg-white dark:hover:text-black
         dark:border-white transition-all duration-500 hover:text-white bg-transparent text-[#0068B5]`}
            >
                {t('btn.details')}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <div className="bg-white dark:bg-gray-800 flex flex-col items-center gap-[10px] rounded-lg
                shadow-lg py-3 sm:px-2 lg:px-6 md:w-[600px] sm:w-[350px]  border
                 border-gray-300 dark:border-gray-700"
                >
                    <div className="w-full sm:h-[150px] overflow-hidden rounded-[10px] md:h-[200px]">
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={dataFind?.image} alt={`img of the ${dataFind?.title}`} className="w-[100%] h-[100%] object-cover" />
                    </div>

                    <div className="w-[100%] flex flex-col items-center gap-[10px]">
                        <span className="md:text-[25px] sm:text-[20px] font-[600] text-black
                         dark:text-white"
                        >
                            {dataFind?.title}
                        </span>
                        <span
                            className="text-[15px] font-[500] text-black dark:text-white text-center"
                        >
                            {dataFind?.heroTitle ? dataFind?.heroTitle : ''}
                        </span>
                        <span
                            className=" sm:text-[12px] md:text-[14px] font-normal text-black
                            dark:text-white text-center"
                        >
                            {dataFind?.heroText}
                        </span>
                    </div>
                    <div
                        className="w-[100%] sm:h-[200px] lg:h-[250px] border border-gray-100 rounded-[10px]
                        sm:px-[6px] md:px-[10px] py-[10px] flex flex-col gap-[20px] overflow-auto"
                    >
                        {dataFind?.body.map((item) => (
                            <div className="flex flex-col w-[100%]  items-center gap-[20px]">
                                <div className="flex w-[100%] justify-center items-center gap-[25px]">
                                    <span
                                        className="py-[6px] px-[15px] bg-black rounded-[50%] text-white
                                        dark:bg-white dark:text-black"
                                    >
                                        {item.id}
                                    </span>
                                    <span
                                        className="text-[20px] font-400 text-black dark:text-white "
                                    >
                                        {item.title}
                                    </span>
                                </div>
                                <span
                                    className="text-[16px] font-normal text-center text-black dark:text-white"
                                >
                                    {item.heroText ? item.heroText : ''}
                                </span>
                                {item.p1.map((para) => (
                                    <div className="flex flex-col items-start w-[100%] gap-[10px]">
                                        <span
                                            className="text-[17px] font-[500] text-black dark:text-white"
                                        >
                                            {para.title}
                                        </span>
                                        <span
                                            className="text-[15px] font-[400] text-black dark:text-white"
                                        >
                                            {para.text}
                                        </span>
                                    </div>
                                ))}
                                <span
                                    className="text-[15px] font-[400] text-black dark:text-white"
                                >
                                    {item.conclusion ? item.conclusion : ''}
                                </span>
                            </div>
                        ))}
                    </div>
                    {/* Add additional content here */}
                    <div className="w-[100%] flex items-start">
                        <button
                            onClick={handleClose}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            {t('btn.close')}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
