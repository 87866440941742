import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { CLIENTS } from '../../../shared/utils/urls'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'

const apiType = [
    { id: '', apiType: '-' },
    { id: 'face_auth', apiType: 'face_auth' },
    { id: 'face_auth_insight_face', apiType: 'face_auth_insight_face' },
    { id: 'face_match', apiType: 'face_match' },
    { id: 'face_match_insight_face', apiType: 'face_match_insight_face' },
    { id: 'face_search', apiType: 'face_search' },
    { id: 'login', apiType: 'login' },
]

const Boolean = [
    { id: '', username: '-' },
    { id: 'true', username: 'True' },
    { id: 'false', username: 'False' },
]

// eslint-disable-next-line import/prefer-default-export
export function AuthLogFilter() {
    const { filterState, setAuthFilters } = useFilter()
    const getClient = useFaceLoad({ url: CLIENTS }, [])
    const clients = getClient.response || []
    const clientsOption = [{ value: '', label: '-' }, ...clients.map((client) => ({
        value: client.id,
        label: client.clientName,
    }))]
    const apiTypeOption = apiType.map((api) => ({ value: api.id, label: api.apiType }))
    const booleanOption = Boolean.map((b) => ({ value: b.id, label: b.username }))
    return (
        <div className="flex items-center gap-[10px]">
            <div className="flex items-center gap-[10px]">
                <div className="flex flex-col items-start gap-[5px]">
                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="region-label">ApiType</InputLabel>
                        <Select
                            labelId="apiType-label"
                            id="apiType-select"
                            label="ApiType"
                            disabled={!apiTypeOption.length}
                            value={filterState.api}
                            onChange={(e) => setAuthFilters({ api: e.target.value })}
                            variant="outlined"
                        >
                            {!apiTypeOption.loading && apiTypeOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="flex flex-col items-start gap-[5px]">
                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="clients-label">Clients</InputLabel>
                        <Select
                            labelId="clients-label"
                            id="clients-select"
                            label="Clients"
                            disabled={!clientsOption.length}
                            value={filterState.authClient}
                            onChange={(e) => setAuthFilters({ authClient: e.target.value })}
                            variant="outlined"
                        >
                            {!clientsOption.loading && clientsOption.length && clientsOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="flex flex-col items-start gap-[5px]">
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="authenticated-label">Authenticated</InputLabel>
                        <Select
                            labelId="authenticated-label"
                            id="authenticated-select"
                            label="authenticated"
                            disabled={!booleanOption.length}
                            value={filterState.authenticated}
                            onChange={(e) => setAuthFilters({ authenticated: e.target.value })}
                            variant="outlined"
                        >
                            {!booleanOption.loading && booleanOption.length && booleanOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="flex flex-col items-start gap-[5px]">
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="facematch-label">Face Match</InputLabel>
                        <Select
                            labelId="facematch-label"
                            id="facematch-select"
                            label="facematch"
                            disabled={!booleanOption.length}
                            value={filterState.face_matched}
                            onChange={(e) => setAuthFilters({ face_matched: e.target.value })}
                            variant="outlined"
                        >
                            {!booleanOption.loading && booleanOption.length && booleanOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="flex flex-col items-start gap-[5px]">
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="spoofed-label">Spoofed</InputLabel>
                        <Select
                            labelId="spoofed-label"
                            id="spoofed-select"
                            label="spoofed"
                            disabled={!booleanOption.length}
                            value={filterState.is_spoofed}
                            onChange={(e) => setAuthFilters({ is_spoofed: e.target.value })}
                            variant="outlined"
                        >
                            {!booleanOption.loading && booleanOption.length && booleanOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}
