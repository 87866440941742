import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { StyleSheet, css } from 'aphrodite'
import moment from 'moment'
import CountUp from 'react-countup'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ATTENDANCE_STATISTICS } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

function Statistics() {
    const { mapFilter } = useFilter()
    const params = {
        attendance_date: moment().format('YYYY-MM-DD'),
        region_id: mapFilter?.region,
        district_id: mapFilter?.district,
    }

    const filteredParams = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null && v !== ''),
    )
    const getStatistics = useGetRequest({ url: ATTENDANCE_STATISTICS, params: filteredParams })

    const statistics = getStatistics.response || {}

    const studentTeachers = statistics?.studentsTeachers || '0'
    const visitors = statistics?.visitors || '0'
    const wanted = statistics?.wanted || '0'
    const total = +studentTeachers + +visitors + +wanted

    useEffect(() => {
        if (mapFilter?.region) {
            getStatistics.request()
        }
    }, [mapFilter])

    return (
        <div className={css(s.stats)}>
            <div className={css(s.totalStats)}>
                <h2 className={css(s.totalStatsTitle)}>
                    <CountUp end={total} duration={1} />
                </h2>
                <p className={css(s.totalStatsLabel)}>JAMI USHBU XUDUD <br /> BO'YICHA KAMERALAR</p>
            </div>

            <div className={css(s.statBar)}>
                <h3 className={css(s.statBarTitle)}>Statistikasi</h3>
                <div className={css(s.barItem)}>
                    <div className={css(s.barHead)}>
                        <p>Tashrif buyuruvchilar</p>
                        <span>
                            <CountUp end={visitors} duration={1} />
                        </span>
                    </div>
                    <div className={css(s.bar)} />
                    <Box
                        className={css(s.barFill)}
                        sx={{ width: `${(visitors / total) * 100}%` }}
                    />
                </div>
                <div className={css(s.barItem)}>
                    <div className={css(s.barHead)}>
                        <p>Qidiruvdagi shaxslar</p>
                        <span>
                            <CountUp end={wanted} duration={1} />
                        </span>
                    </div>
                    <div className={css(s.bar)} />
                    <Box
                        className={css(s.barFill)}
                        sx={{ width: `${(wanted / total) * 100}%` }}
                    />
                </div>
                <div className={css(s.barItem)}>
                    <div className={css(s.barHead)}>
                        <p>O’qituvchilar va o’quvchilar</p>
                        <span>
                            <CountUp end={studentTeachers} duration={1} />
                        </span>
                    </div>
                    <div className={css(s.bar)} />
                    <Box
                        className={css(s.barFill)}
                        sx={{ width: `${(studentTeachers / total) * 100}%` }}
                    />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    stats: {
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        width: '18vw',
        color: '#fff',
        fontFamily: 'Inter',
    },
    totalStats: {
        border: '0.5px solid #00FCFC',
        background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.2) 3%, rgba(0, 0, 0, 0.7) 100%)',
        backdropFilter: 'blur(5px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px',
    },
    totalStatsTitle: {
        fontSize: '2.5rem',
        fontWeight: 600,
        lineHeight: '1.8rem',
        borderRight: '1px solid #1F3737',
        padding: '10px',
    },
    totalStatsLabel: {
        color: '#00FCFC',
        fontSize: '1.05rem',
        lineHeight: '1.2rem',
        maxWidth: '250px',
        padding: '10px',
    },
    statBar: {
        marginTop: '15px',
        padding: '20px 20px 25px 20px',
        border: '0.5px solid #00FCFC',
        background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.2) 3%, rgba(0, 0, 0, 0.7) 100%)',
        backdropFilter: 'blur(5px)',
    },
    statBarTitle: {
        color: '#00FCFC',
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: '15px',
        padding: '2px 7px',
        borderLeft: '1px solid #fff',
        display: 'inline-block',
    },
    barItem: {
        marginBottom: '15px',
        position: 'relative',
    },
    bar: {
        height: '7px',
        backgroundColor: '#004141',
        width: '100%',
    },
    barHead: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
    },
    barFill: {
        height: '7px',
        backgroundColor: '#00FCFC',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: 'width 0.8s ease',
    },
})

export default Statistics
