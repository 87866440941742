import React, { createContext, useEffect, useState } from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { usePersistState } from '../../shared/hooks/state'
import { XIconSizeM } from '../../components/svgs/Svgs'
import AuthForm from '../../modules/face-auth/authForm'
import { checkTokenExpiration } from '../../shared/utils/session'

export const Context = createContext()

function GlobalContext({ children }) {
    const [open, setOpen] = useState(false)
    const [cameraFilter, setCameraFilter] = usePersistState('cameraFilter', { bId: '', rId: '' })
    const [lang, setLang] = usePersistState('lang', 'uz')

    const currentVersion = '1.0.0'

    useEffect(() => {
        checkTokenExpiration()

        if (!localStorage.version) {
            localStorage.clear()
            localStorage.setItem('version', currentVersion)
        } else if (localStorage.version > currentVersion) {
            localStorage.clear()
            localStorage.setItem('version', currentVersion)
        }
    }, [])

    return (
        <Context.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                lang,
                setLang,
                setOpenFaceAuthModal: setOpen,
                cameraFilter,
                setCameraFilter,
            }}
        >
            {children}

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>
                        Authorization for Face API
                    </Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <AuthForm
                        onClose={() => setOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </Context.Provider>
    )
}

export default GlobalContext
