/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, Select, Typography } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { ChevronNextSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import Card from './Card'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { DISTRICTS_LIST, ALLOWED_REGIONS, TENANT_ENTITIES_CHILDREN } from '../../../shared/utils/urls'
import { useGetRequest, useInfiniteScroll, useLoad } from '../../../shared/hooks/requests'
import Create from '../features/Create'
import SearchInput from '../../../shared/ui/SearchInput'

export default function Branches({ sections }) {
    const { sec_id, sec_name } = useQueryParams()
    const [filters, setFilters] = useState({
        rId: '',
        dId: '',
    })
    const [searchTerm, setSearchTerm] = useState('')
    const getBranches = useInfiniteScroll({
        url: TENANT_ENTITIES_CHILDREN.replace('{id}', sec_id),
        params: { search: searchTerm, region_id: filters.rId, district_id: filters.dId },
    }, Boolean(sec_id), [sec_id, searchTerm, filters.rId, filters.dId])
    const data = getBranches.response ? getBranches.response.items : []
    const meta = getBranches.response ? getBranches.response : {}
    const options = sections ? sections.map((s) => (
        { value: s.id, label: s.name })) : []

    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: filters.rId } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOptions = districts.length ? districts.map((district) => ({ value: district.id, label: district.name })) : []

    const handleSearch = (value) => {
        setSearchTerm(value)
    }

    useEffect(() => {
        setFilters((prevState) => ({ ...prevState, dId: '' }))
        if (filters.rId) {
            getDistricts.request()
        }
    }, [filters.rId])

    return sections && sections.length ? (
        <div className="flex-col gap-7_5">
            <div className="flex items-center justify-between">
                <div className="flex gap-5">
                    <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        Branches ( {data.length} )

                        <ChevronNextSizeM color={COLORS.disabled} />

                        <h3>{sec_name}</h3>
                    </Typography>
                    <div className="flex items-center gap-2">
                        <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={(value) => (value ? regionOptions.find((v) => v.value === value).label : 'Choose region')}
                            sx={{
                                height: 37,
                                width: '10rem',
                            }}
                            value={filters.rId}
                            onChange={(e) => setFilters((prevState) => ({
                                ...prevState,
                                rId: e.target.value,
                            }))}
                        >
                            {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem.label}
                                </MenuItem>
                            )) : loadRegions.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>

                        <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={(value) => (value ? districtOptions.find((v) => v.value === value).label : 'Choose district')}
                            sx={{
                                height: 37,
                                width: '10rem',
                            }}
                            value={filters.dId}
                            disabled={!districtOptions.length}
                            onChange={(e) => setFilters((prevState) => ({
                                ...prevState,
                                dId: e.target.value,
                            }))}
                        >
                            {!getDistricts.loading && districtOptions.length ? districtOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem.label}
                                </MenuItem>
                            )) : getDistricts.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>
                    </div>
                </div>

                <div className="flex-cent gap-4_5">
                    <Create hierarchyLevel={3} parentOptions={options} setData={getBranches.setResponse} />

                    <SearchInput onSearch={handleSearch} />
                </div>
            </div>

            {data.length ? (
                <Grid container columnGap={2} rowGap={2}>
                    {data.map((item) => (
                        <Card
                            item={item}
                            key={item.id}
                            setData={getBranches.setResponse}
                            loading={getBranches.loading}
                            parentOptions={options} />
                    ))}
                </Grid>
            ) : null}

            {getBranches.hasMore && meta.total > meta.size && <div ref={getBranches.ref} />}
        </div>
    ) : null
}
