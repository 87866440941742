import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { usePostRequest } from '../../../shared/hooks/requests'
import { UPDATE_DEEPSTREAM_CAMERAS } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

export default function StreamConf({ cameras, appName, version }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()
    const [checked, setChecked] = useState({})
    const [selected, setSelected] = useState([])
    const updateDeepstreamCamera = usePostRequest({ url: UPDATE_DEEPSTREAM_CAMERAS })

    // useEffect(() => {
    //     const initialCheckedState = cameras.reduce((acc, camera) => {
    //         acc[camera.id] = sources.some((url) => url === camera.rtspUrl)
    //         return acc
    //     }, {})

    //     setChecked(initialCheckedState)
    // }, [sources])

    const handleCheckboxChange = (cameraId, camera) => {
        const isChecked = !checked[cameraId]
        setChecked((prevState) => ({
            ...prevState,
            [cameraId]: isChecked,
        }))

        if (isChecked) {
            setSelected((prev) => [...prev, camera])
        } else {
            setSelected((prev) => prev.filter((v) => v.id !== camera.id))
        }
    }

    const submitChanges = async () => {
        if (!selected.length || updateDeepstreamCamera.loading) return

        const cameraRtspUrls = selected.map((item) => {
            console.log(item)
            const rtspUrl = `${item.rtspUrl}${item.streamUrl}`
            return rtspUrl
        })

        const { response, success, error } = await updateDeepstreamCamera.request({ data: {
            applicationName: appName,
            applicationVersion: version,
            jetsonDeviceId,
            cameraRtspUrls,
        } })

        if (success) {
            showMessage(response.description, response.status === 'success' ? 'success-msg' : 'error-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div className="py-3.5 px-5 flex flex-col items-end gap-2.5">
            {cameras.map((camera) => (
                <FormControlLabel
                    key={camera.id}
                    sx={{ margin: 0 }}
                    className="w-full flex items-center justify-between py-1 pl-4 pr-1 border border-sepia-100 rounded"
                    labelPlacement="start"
                    label={camera.name}
                    control={(
                        <Checkbox
                            checked={checked[camera.id]}
                            onClick={() => handleCheckboxChange(camera.id, camera)} />
                    )}
                />
            ))}

            <PrimaryBtn
                onClick={submitChanges}
                title="Apply changes"
                loading={updateDeepstreamCamera.loading}
                styles={{ width: 'fit-content', marginTop: '10px' }} />
        </div>
    )
}
