import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Outlet } from 'react-router-dom'
import { Howl } from 'howler'
import { COLORS } from '../../shared/utils/colors'
import { audioData } from '../../shared/utils/landingData'
import { Header } from './components/Header'
import { Footer } from './components/footer'

let sound

const getRandomAudio = () => {
    const audioIndex = localStorage.getItem('audio')
    let audioSrc
    if (audioIndex) {
        const newIndex = (parseInt(audioIndex, 10) + 1) % audioData.length
        audioSrc = audioData[newIndex].src
        localStorage.setItem('audio', newIndex.toString())
    } else {
        const index = Math.floor(Math.random() * audioData.length)
        audioSrc = audioData[index].src
        localStorage.setItem('audio', index.toString())
    }
    return audioSrc
}

function HomePage() {
    const [open, setOpen] = useState(false)
    const [isMuted, setIsMuted] = useState(true) // State to track if audio is muted

    useEffect(() => {
        // Autoplay muted audio when the page loads
        const selectedAudio = getRandomAudio()

        sound = new Howl({
            src: [selectedAudio],
            autoplay: true,
            loop: false,
            volume: 0.5,
            preload: true,
            mute: isMuted,
        })
    }, [isMuted])

    const unmuteAudio = () => {
        if (sound) {
            sound.mute(false)
            setIsMuted(false)
        }
    }

    useEffect(() => {
        if (!open) {
            document.body.style.overflow = 'unset'
        } else {
            document.body.style.overflow = 'hidden'
        }
    }, [open])
    return (
        <div className="relative bg-white dark:bg-black flex flex-col justify-between min-h-screen">
            {isMuted && (
                // eslint-disable-next-line react/button-has-type,no-use-before-define
                <button onClick={unmuteAudio} className={cn(css(s.unmuteButton), 'unmute-button')}>
                    Unmute Audio
                </button>
            )}
            <div className="flex flex-col">
                <header>
                    <Header open={open} setOpen={setOpen} />
                </header>
                <main>
                    <div className="w-full  bg-white dark:bg-[#262626] lg:m-auto">
                        <Outlet />
                    </div>
                </main>
            </div>
            <footer>
                <Footer />
            </footer>
            {/* <div className={cn(
                'absolute flex-col',
                css(s.sidebar, sidebar ? s.navActive : s.navOff),
            )}>
                <div className={cn(css(s.burgerTop), 'flex items-center justify-between')}>
                    <div className="flex items-center gap-5">
                        <HeaderLogoM />
                    </div>

                    <div className={css(s.closeBtn)} onClick={() => setSidebar(!sidebar)}>
                        <CloseBtn />
                    </div>
                </div>
                <LazyDrover setProp={setSidebar} />
            </div> */}
        </div>
    )
}

export default HomePage

const s = StyleSheet.create({
    unmuteButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px 20px',
        backgroundColor: COLORS.white,
        color: COLORS.black,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        zIndex: 1000,
        ':hover': {
            backgroundColor: COLORS.gray,
        },
    },
    closeBtn: {
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    wrapper: {
        height: '62.5rem',
        paddingTop: '2.563rem',
    },
    videoBg: {
        top: '0',
        left: '0',
        zIndex: -2,
    },
    video: {
        objectFit: 'cover',
    },
    gradient: {
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.60)',
        zIndex: -1,
    },
    headerSelector: {
        display: 'none',
        '@media (min-width: 48rem)': {
            display: 'block',
        },
    },
    header1: {
        width: '90%',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
        '@media (min-width: 62rem)': {
            maxWidth: '80%',
        },
    },
    headerWrapper: {
        maxWidth: '120rem',
    },
    headerM: {
        maxWidth: '90%',
        height: '70%',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
        },
        '@media (min-width: 62rem)': {
            maxWidth: '80%',
        },

    },
    headerMH: {
        maxWidth: '97%',
        '@media (min-width: 48rem)': {
            fontSize: '2rem',
        },
        '@media (min-width: 62rem)': {
            fontSize: '3rem',
            width: '70%',
        },
    },
    headerMP: {
        marginBottom: '3rem',
        maxWidth: '85%',
        lineHeight: '1.563rem',
        '@media (min-width: 48rem)': {
            fontSize: '1.125rem',
        },
    },
    headerMB: {
        paddingRight: '1.375rem',
        paddingLeft: '1.5rem',
        paddingTop: '0.688rem',
        paddingBottom: '0.75rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#fff',
        '@media (min-width: 48rem)': {
            paddingTop: '0.688rem',
            paddingBottom: '0.688rem',
            paddingLeft: '2rem',
            paddingRight: '1.938rem',
        },
    },
    headerNav: {
        position: 'relative',
        display: 'none',
        gap: '36px',
        '@media (min-width: 62rem)': {
            display: 'flex',
        },
    },
    headerBtn: {
        gap: '18px',
    },
    loginBtn: {
        display: 'none',
        paddingRight: '1.625rem',
        paddingLeft: '1.625rem',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        backgroundColor: COLORS.white,
        borderRadius: '8px',
        textColor: '#000',
        fontSize: '18px',
        fontWeight: '500',
        ':hover': {
            opacity: '0.7',
            transition: 'all 0.3s ease-in-out',
        },
        '@media (min-width: 62rem)': {
            display: 'inline',
        },
    },

    // statistic css style
    staticBg: {
        background: '#000',
    },
    statica: {
        maxWidth: '120rem',
        paddingBottom: '3.813rem',
        paddingTop: '5.25rem',
        backgroundColor: '#000',
    },
    staticDiv: {
        maxWidth: '90%',
        gap: '2.5rem',
        '@media (min-width: 48rem)': {
            maxWidth: '80%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },

    // form css styles
    forms: {
        background: COLORS.black,
    },

    // footer css styles
    footerLinks: {
        rowGap: '2.438rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '3.563rem',
        },
    },
    footerLink: {
        rowGap: '1.688rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '2.875rem',
        },
    },
    footerNav: {
        rowGap: '1.813rem',
        '@media (min-width: 62rem)': {
            flexDirection: 'row',
            gap: '2.875rem',

        },
    },
    infoTitle: {
        color: '#fff',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        textDecoration: 'none',
    },
    footerW: {
        width: '90%',
        paddingTop: '3.188rem',
        gap: '2.313rem',
        '@media (min-width: 76.125rem)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '80%',
            paddingBottom: '1.563rem',
        },
    },
    logoV: {
        display: 'inline-block',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    logoB: {
        display: 'none',
        '@media (min-width: 62rem)': {
            display: 'inline-block',
        },
    },
    footer: {
        maxWidth: '120rem',
        width: '100%',
        background: COLORS.black,

    },
    footerText: {
        paddingTop: '2.813rem',
        paddingBottom: '1.563rem',
        width: '80%',
    },

    // menu btn css styles
    menuBtn: {
        display: 'flex',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    sidebar: {
        top: 0,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '100%',
        paddingBottom: '20px',
        borderRadius: '8px',
        borderBottom: '1px solid #000',
        right: 0,
        zIndex: 2,
        backgroundColor: COLORS.black,
        transition: 'all 0.3s linear',
        '@media (min-width: 62rem)': {
            display: 'none',
        },
    },
    navActive: {
        left: 0,
    },
    navOff: {
        left: '100%',
    },
    burgerTop: {
        paddingTop: '2.563rem',
        width: '90%',
        margin: '0 auto',
        top: 0,

    },
})
