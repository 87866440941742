import React from 'react'
import ProductModal from '../../components/ProductModal'

// eslint-disable-next-line react/prop-types,import/prefer-default-export
export function ProductCard({ index, title, text, image }) {
    return (
        <div className={`flex sm:flex-col ${Number(index) % 2 === 0 ? ''
            + 'md:flex-row-reverse' : 'md:flex-row'} w-[100%] sm:gap-[14px] items-center md:gap-[50px]`}
        >
            <div className={`w-[100%] md:w-[500px] lg:w-[730px]
             ${!image ? 'bg-[#F4F4F4] dark:bg-[#515151]' : ''} h-[205px] md:h-[400px]`}
            >
                {image ? (
                    <img
                        src={image}
                        alt="img of the mine"
                        loading="lazy"
                        className="w-[100%] h-[100%] object-cover"
                    />
                ) : ''}
            </div>
            <div className="flex flex-col items-start gap-[22px]">
                <div className="flex flex-col w-[100%] md:w-[400px] lg:w-[615px] items-start gap-[10px]">
                    <span className="text-[14px] sm:hidden md:block font-[400] text-[#0068B5] dark:text-white">
                        №
                        {index}
                    </span>
                    <span className="text-[18px] lg:text-[50px] font-[700]
                    lg:font-[300] text-black dark:text-white"
                    >
                        {title}
                    </span>
                    <span className="text-[#525252] dark:text-white w-[100%] font-normal text-[16px]">
                        {text}
                    </span>
                </div>
                <ProductModal id={Number(index)} xl />
            </div>
        </div>
    )
}
