import { configureStore } from '@reduxjs/toolkit'
import polygonReducer from './polygonSlice' // Adjust the path as needed
import realTimeMapReducer from './realTimeMapSlice' // Adjust the path as needed

const store = configureStore({
    reducer: {
        polygon: polygonReducer,
        attendances: realTimeMapReducer,
    },
    devTools: true,
})

export default store
