/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { Like, NotLike } from '../../../components/svgs/Svgs'

const FEEDBACK_EXPIRY_MINUTES = 30 // Time after which user can interact again

function FeedbackSection() {
    const [feedback, setFeedback] = useState(null) // Stores 'like' or 'notLike'
    const [showFeedbackOptions, setShowFeedbackOptions] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])

    // Check if feedback exists and is valid
    useEffect(() => {
        const savedFeedback = localStorage.getItem('feedback')
        const feedbackTime = localStorage.getItem('feedbackTimestamp')

        if (savedFeedback && feedbackTime) {
            const timeElapsed = (Date.now() - parseInt(feedbackTime, 10)) / 60000 // Convert to minutes
            if (timeElapsed < FEEDBACK_EXPIRY_MINUTES) {
                setFeedback(savedFeedback) // Restore feedback
            } else {
                localStorage.removeItem('feedback') // Clear expired feedback
                localStorage.removeItem('feedbackTimestamp')
            }
        }
    }, [])

    // Handle Like button click
    const handleLikeClick = () => {
        setFeedback('like')
        setShowFeedbackOptions(true)
        localStorage.setItem('feedback', 'like')
        localStorage.setItem('feedbackTimestamp', Date.now().toString())
    }

    // Handle NotLike button click
    const handleNotLikeClick = () => {
        setFeedback('notLike')
        setShowFeedbackOptions(false)
        localStorage.setItem('feedback', 'notLike')
        localStorage.setItem('feedbackTimestamp', Date.now().toString())
    }

    // Handle option selection
    const handleOptionChange = (option) => {
        setSelectedOptions((prevOptions) => (prevOptions.includes(option)
            ? prevOptions.filter((o) => o !== option)
            : [...prevOptions, option]))
    }

    // Handle feedback submission
    const handleSendFeedback = () => {
        setShowFeedbackOptions(false) // Hide feedback options after sending
        setSelectedOptions([]) // Clear selected options
    }

    return (
        <section>
            {/* Show the initial text and icons only if no feedback has been given */}
            {!feedback ? (
                <div className="w-full py-[28px] flex items-center gap-[21px] justify-center bg-[#F5F6F7]
                 dark:bg-[#5A5A5A] px-[24px]"
                >
                    <span className="text-[#000000] dark:text-white lg:text-[21px] sm:text-[14px] font-normal">
                        How is your experience with this page?
                    </span>
                    <div className="flex items-center gap-[12px]">
                        <button type="button" className="px-[10px] py-[10px] bg-[#0068B5] rounded-[10px] dark:bg-white" onClick={handleLikeClick}>
                            <Like />
                        </button>
                        <button type="button" className="px-[10px] py-[10px] bg-[#0068B5] rounded-[10px] dark:bg-white" onClick={handleNotLikeClick}>
                            <NotLike />
                        </button>
                    </div>
                </div>
            ) : null}

            {/* Show Thank you message once feedback is selected */}
            {feedback && (
                <div className="w-full py-[28px] flex flex-col items-center gap-[10px] justify-center bg-[#F5F6F7]
                dark:bg-[#5A5A5A] px-[24px]"
                >
                    <div className="flex flex-col items-center gap-[10px]">
                        <span className="text-[20px] font-[700] text-[#0068B5] dark:text-white">
                            Thank you!
                        </span>
                        <span className="text-[14px] font-normal text-gray-300 dark:text-white">
                            Your feedback helps us create a better experience.
                        </span>
                    </div>
                    {/* Show feedback options only if feedback is 'like' */}
                    {feedback === 'like' && showFeedbackOptions && (
                        <div className="pt-[20px] flex flex-col items-start gap-[15px] border-t border-gray-100">
                            <div className="flex flex-col items-start gap-[6px]">
                                <span className="text-[16px] font-bold text-black dark:text-white">
                                    Please tell us how we can improve
                                </span>
                                <span className="text-[14px] font-normal text-gray-500 dark:text-white">
                                    Check all that apply
                                </span>
                            </div>
                            <div className="flex flex-col items-start gap-[6px]">
                                {[
                                    'I need more details',
                                    'The information is confusing or hard to understand',
                                    "I can't find what I'm looking for",
                                    "I'd like to have information in my language",
                                    'I have technical issues (broken links, display issues, page errors, etc.)',
                                    'Something else',
                                ].map((option) => (
                                    <div key={option} className="flex items-center gap-[10px]">
                                        <Checkbox
                                            checked={selectedOptions.includes(option)}
                                            onChange={() => handleOptionChange(option)}
                                        />
                                        <span className="text-[14px] font-normal text-gray-200 dark:text-white">
                                            {option}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <button
                                className={`text-[14px] font-semibold text-white bg-[#0068B5] dark:bg-white 
                                dark:text-black px-[10px] py-[10px] rounded-[10px] ${
                                    selectedOptions.length === 0
                                        ? 'opacity-50 cursor-not-allowed'
                                        : ''
                                }`}
                                onClick={handleSendFeedback}
                                disabled={selectedOptions.length === 0}
                            >
                                Send Feedback
                            </button>
                        </div>
                    )}
                </div>
            )}
        </section>
    )
}

export default FeedbackSection
