/* eslint-disable */
import React, { memo, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { AmericaFlag, ButtonIcon, RussianFlag, TopIcon, UzbFlag } from '../../../components/svgs/Svgs'
import { langData } from '../../../shared/utils/landingData'

function LangSelector() {
    const { i18n } = useTranslation() // Хук для работы с i18n
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLang') || 'Uzb')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropRef = useRef(null)

    const handleLanguageSelect = (language) => {
        const { lang, code } = language // Добавляем код языка
        setSelectedLanguage(lang)
        localStorage.setItem('selectedLang', lang)
        i18n.changeLanguage(code) // Меняем язык с помощью i18n
        setIsDropdownOpen(false)
    }

    useEffect(() => {
        const storedLang = localStorage.getItem('selectedLang')
        if (storedLang) {
            setSelectedLanguage(storedLang)
        }
    }, [])

    useEffect(() => {
        function handleClick(event) {
            if (dropRef.current && !dropRef.current.contains(event.target)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    return (
        <div
            className={cn(css(s.wrapper), 'flex-col items-center justify-center gap-2')}
            ref={dropRef}
        >
            <div
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="h-auto py-[8px] px-[0.6rem] flex items-center justify-center
            gap-[0.6rem] rounded-[10px] bg-[#F7F7F7] dark:bg-[#353535]"
            >
                {selectedLanguage === 'Rus' && <RussianFlag />}
                {selectedLanguage === 'Uzb' && <UzbFlag />}
                {selectedLanguage === 'Eng' && <AmericaFlag />}
                <span className="text-[#A5A5A5] dark:text-white">{selectedLanguage}</span>
                <span className="text-[#A5A5A5] dark:text-white">
                    {isDropdownOpen
                        ? (<TopIcon />) : (<ButtonIcon />)}
                </span>
            </div>
            {isDropdownOpen && (
                <div className="flex-col items-center translate-y-[70px] transition-all duration-500
                w-[6.75rem] py-[8px] gap-[0.6rem]
                rounded-[10px] bg-[#F7F7F7] dark:bg-[#353535] ease-in-out h-auto absolute"
                >
                    {langData.map((item) => {
                        if (item.lang === selectedLanguage) {
                            return null
                        }
                        return (
                            <div
                                onClick={() => handleLanguageSelect(item)}
                                key={item.id}
                                className={cn(css(s.langBox), 'flex items-center justify-center pointer gap-7')}
                            >
                                <span className="text-[#A5A5A5] dark:text-white">
                                    {item.lang}
                                </span>
                                <item.flag />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default memo(LangSelector)

const s = StyleSheet.create({
    wrapper: {
        position: 'relative',
        cursor: 'pointer',
    },
    dropdown: {
        transform: 'translateY(70px)',
        transition: 'all 0.5s ease',
        width: '6.75rem',
        paddingTop: '8px',
        paddingBottom: '8px',
        gap: '0.625rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#F7F7F7',
        background: '#F7F7F7',
    },
    langBox: {
        height: '100%',
        width: '100%',
        ':hover': {
            backgroundColor: 'rgba(118, 118, 128, 0.10)',
        },
    },
})
