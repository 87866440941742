/* eslint-disable */
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import React from 'react'
import { COLORS } from '../../../shared/utils/colors'
import TenantEntityManagmentDetails from '../components/TenantEntiyTableDetails'
import { usePutRequest } from '../../../shared/hooks/requests'
import { ENTITY_MANAGMENT_UPDATE } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'

// eslint-disable-next-line import/prefer-default-export
export function Update({ setData, item, isOpen, open, close }) {
    const showMessage = useMessage()
    const updateEntity = usePutRequest({ url: `${ENTITY_MANAGMENT_UPDATE}/${item.id}` })
    const update = async (values) => {
        const { success, error } = await updateEntity.request({
            data: {
                name: values.name,
                allowed_radius: values.allowedRadius,
                face_auth_threshold: values.faceAuthThreshold,
                spoofing_threshold: values.spoofingThreshold,
            },
        })
        if (success) {
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            console.log(error)
        }
    }
    return (
        <>
            <IconButton className="p-0" onClick={open}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>
            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '38rem' } }}
                anchor="right"
                open={isOpen}
                onClose={close}
            >
                <TenantEntityManagmentDetails
                    onEdit={update}
                    setData={setData}
                    onClose={close}
                    item={item}
                />
            </Drawer>
        </>
    )
}
