import React, { useEffect } from 'react'
import moment from 'moment'
import { MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export default function CameraFilter({ buildingUrl, roomUrl }) {
    const { queueAnalyticsFilter, setQueueAnalyticsFilter } = useFilter()
    const loadBuildings = useLoad({ url: buildingUrl }, [])
    const buildings = loadBuildings.response ? loadBuildings.response : []
    const getRooms = useGetRequest({ url: roomUrl, params: { buildingId: queueAnalyticsFilter.bId } })
    const rooms = getRooms.response ? getRooms.response : []
    const buildingOptions = buildings.map((b) => ({ value: b.id, label: b.name }))
    const roomOpts = rooms.length ? rooms.map((r) => ({ value: r.id, label: r.name })) : []

    useEffect(() => {
        if (queueAnalyticsFilter.bId) {
            getRooms.request()
        }
    }, [queueAnalyticsFilter.bId])

    return (
        <Paper square className="flex flex-col gap-2.5 p-3">
            <h3 className="text-m text-black font-500">Filter</h3>

            <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? buildingOptions.find((v) => v.value === value)
                            && buildingOptions.find((v) => v.value === value).label : 'Choose building')}
                        sx={{ height: 37, width: '10rem' }}
                        value={queueAnalyticsFilter.bId}
                        onChange={(e) => setQueueAnalyticsFilter(() => ({ bId: e.target.value }))}
                    >
                        {!loadBuildings.loading && buildingOptions.length ? buildingOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        )) : loadBuildings.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    {roomOpts.length ? (
                        <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={(value) => (value ? roomOpts.find((v) => v.value === value)
                                && roomOpts.find((v) => v.value === value).label : 'Choose room')}
                            sx={{ height: 37, width: '10rem' }}
                            value={queueAnalyticsFilter.rId}
                            onChange={(e) => setQueueAnalyticsFilter((prev) => ({ ...prev, rId: e.target.value }))}
                        >
                            {!getRooms.loading && roomOpts.length ? roomOpts.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem.label}
                                </MenuItem>
                            )) : roomOpts.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value={0}>Nothing Found</MenuItem>}
                        </Select>
                    ) : null}

                    <DatePicker
                        label="Select date"
                        sx={{ height: 37,
                            width: '11rem',
                            '& .MuiInputBase-root': { height: 37 },
                            // also move legend to the top
                            '& .MuiFormLabel-root': { top: '-8px' } }}
                        inputFormat="dd-MM-yyyy"
                        value={queueAnalyticsFilter.startPeriod ? moment(queueAnalyticsFilter.startPeriod) : null}
                        onChange={(e) => {
                            const formattedDate = e ? moment(e).format('YYYY-MM-DD') : null
                            setQueueAnalyticsFilter((old) => ({ ...old,
                                startPeriod: formattedDate,
                                endPeriod: formattedDate }))
                        }}
                        // display format should be dd-MM-yyyy
                        initialFocusedDate={moment()}
                    />

                </div>
            </LocalizationProvider>
        </Paper>
    )
}
