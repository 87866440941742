/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Box, Dialog, DialogContent, Grid, MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { Form, Formik } from 'formik'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import SmartCameraForm from './SmartCameraForm'
import SCameraClientsTable from '../../infrastructure/components/SCameraClientsTable'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ENABLE_RTMP, NO_REGISTERED_CAMERAS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import FaceParametres from './FaceParametres'
import ComparisonParameters from './ComparisonParameters'
import UpdatePassword from './UpdatePassword'
import Controls from './Controls'
import { OthersForm } from './OthersForm'
import { XIconSizeS } from '../../../components/svgs/Svgs'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const navItems = [
    { id: 'detail', name: 'Details' },
    { id: 'wanted', name: 'Blacklist users', count: 0 },
    { id: 'loadedIdentity', name: 'Loaded Identity', count: 0 },
    { id: 'unloadedIdentity', name: 'Unloaded Identity', count: 0 },
    { id: 'faceparams', name: 'Face Params' },
    { id: 'comparison', name: 'Comparison Params' },
    { id: 'security', name: 'Security' },
    { id: 'controls', name: 'Controls' },
    { id: 'others', name: 'Others' },
]

export default function SmartCameraDetails({ item, onClose, onEdit, loading, setData }) {
    const { identityFilter } = useFilter()
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [section, setSection] = useState('detail')
    const [openUnregistered, setOpenUnregistered] = useState(false)
    const [selectedUnregistered, setSelectedUnregistered] = useState(null)
    const enableStream = useGetRequest({ url: ENABLE_RTMP.replace('{id}', item.id) })
    const [initialValues, setInitialValues] = useState({
        deviceName: '',
        deviceId: '',
        deviceMac: '',
        libPlatformVersion: '',
        softwareVersion: '',
        libAiVersion: '',
        deviceIp: '',
        deviceLat: '',
        deviceLong: '',
        streamUrl: '',
        rtspUrl: '',
    })

    const loadUnregistered = useLoad({ url: NO_REGISTERED_CAMERAS }, [])
    const unregistered = loadUnregistered.response ? loadUnregistered.response : []
    const unregisteredOptions = unregistered.map((b) => ({ value: b.deviceId, label: b.deviceId || '-' }))

    const handleEnableRtmp = async (enable) => {
        if (enableStream.loading) return

        const { success, response, error } = await enableStream.request({ params: { enable } })

        if (success) {
            showMessage(response.message, 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    useEffect(() => {
        if (selectedUnregistered !== null) {
            const selected = unregistered.find((v) => v.deviceId === selectedUnregistered)
            if (selected) {
                setInitialValues((prevValues) => ({
                    ...prevValues,
                    ...item,
                    ...selected,
                }))
            }
        } else {
            setInitialValues(item)
        }
    }, [selectedUnregistered, unregistered, item])

    return (
        <>
            <DrawerLayout
                topic="Smart camera details"
                onClose={onClose}
                name={item.name}
                icons={[]}
            >
                <>
                    <div className={css(s.detailNav)}>
                        <ul className="w-full flex items-center">
                            {navItems.map(((i) => (
                                <li
                                    key={i.id}
                                    onClick={() => setSection(i.id)}
                                    className={css(s.navItem, section === i.id && s.active)}
                                >
                                    {i.name}
                                </li>
                            )))}
                        </ul>
                    </div>

                    <div className="h-layout overflow-y-auto">
                        {section === 'detail' && (
                            <div className="flex items-center gap-2_5 mt-3 ml-2.5">
                                <Button type="button" onClick={() => handleEnableRtmp(true)} className={css(s.actBtn)}>
                                    Enable RTMP stream
                                </Button>

                                <Button type="button" onClick={() => handleEnableRtmp(false)} className={css(s.actBtn)}>
                                    Disable RTMP stream
                                </Button>
                            </div>
                        )}
                        {section === 'detail' ? (
                            <SmartCameraForm
                                isEdit
                                loading={loading}
                                onClose={onClose}
                                openUnregistered={() => setOpenUnregistered(true)}
                                onSubmit={onEdit}
                                initialValues={initialValues}
                            />
                        ) : null}

                        { section === 'faceparams' ? <FaceParametres id={item.id} /> : null}

                        {section === 'comparison' ? <ComparisonParameters id={item.id} /> : null}

                        {section === 'security' ? (
                            <UpdatePassword
                                id={item.id}
                                setData={setData}
                                oldInfo={{
                                    username: item.username,
                                    password: item.password,
                                }}
                            />
                        ) : null}

                        {section === 'controls' ? <Controls item={item} /> : null}

                        {section === 'others' ? <OthersForm id={item.id} /> : null}

                        {['wanted', 'loadedIdentity', 'unloadedIdentity'].includes(section) ? (
                            <SCameraClientsTable section={section} id={item.id} tenantEntityId={identityFilter?.tenantEntityId} />
                        ) : null}
                    </div>
                </>
            </DrawerLayout>

            <Dialog open={openUnregistered} onClose={() => setOpenUnregistered(false)} fullWidth>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Select Unregistered Camera</h3>
                    <XIconSizeS className="cursor-pointer" onClick={() => setOpenUnregistered(false)} />
                </div>
                <DialogContent style={{ padding: 0 }}>
                    <Box p={3}>
                        <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={(value) => (value
                                ? unregisteredOptions.find((v) => v.value === value)?.label
                                : 'Select Camera')}
                            sx={{ height: 37, width: '10rem', margin: '0 auto' }}
                            value={selectedUnregistered}
                            onChange={(e) => setSelectedUnregistered(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '300px',
                                    },
                                },
                            }}
                        >
                            {!loadUnregistered.loading && unregisteredOptions.length ? (
                                unregisteredOptions.map((menuItem) => (
                                    <MenuItem key={menuItem.value} value={menuItem.value}>
                                        {menuItem.label}
                                    </MenuItem>
                                ))
                            ) : loadUnregistered.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : (
                                <MenuItem value="">Nothing Found</MenuItem>
                            )}
                        </Select>

                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={[]}
                        >
                            {({ handleChange, values }) => (
                                <Form className="flex flex-col justify-between p-4 gap-3">
                                    <Grid container spacing="12px" columnSpacing={2}>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceName"
                                                label="Device Name"
                                                value={values.deviceName}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                required
                                                name="deviceId"
                                                label="Device ID"
                                                value={values.deviceId}
                                                handleChange={handleChange}
                                            />
                                        </Grid>

                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceMac"
                                                label="Device Mac"
                                                value={values.deviceMac}
                                                handleChange={handleChange}
                                            />
                                        </Grid>

                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="libPlatformVersion"
                                                label="Lib Platform Version"
                                                value={values.libPlatformVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>

                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="softwareVersion"
                                                label="Software Version"
                                                value={values.softwareVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>

                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="libAiVersion"
                                                label="Lib AI Version"
                                                value={values.libAiVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>

                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceIp"
                                                label="Device IP"
                                                value={values.deviceIp}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    <div className="flex items-center justify-end gap-2.5 m-[10px]">
                        <PrimaryBtn
                            title="Apply"
                            iconProps={{ fill: '#fff' }}
                            onClick={() => {
                                setOpenUnregistered(false)
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

const s = StyleSheet.create({
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 14,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        transition: 'all 0.3s linear',
    },
    detailCont: {
        paddingTop: 9,
        height: 'calc(100% - 10rem)',
        overflowY: 'scroll',
    },
})
