/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import uuid from 'react-uuid'
import { COLORS } from '../utils/colors'
import { hexToRGBA } from '../utils/string'

export const PolygonInitialState = {
    id: uuid(),
    points: [],
    isFinished: false,
    label: 'Polygon 1',
    description: '',
    userId: null,
    colors: {
        vertexRadius: 2.5,
        lineColor: '#0B9E34',
        fillColor: hexToRGBA('#0B9E34', '0.30'),
        vertexColor: COLORS.white,
        vertexStrokeWidth: 1,
    },
    type: '',
}

const initialState = {
    history: {
        past: [],
        present: null,
        future: [],
    },
    polygons: [
        PolygonInitialState,
    ],
    activePolygonIndex: 0,
    shouldCloseForm: false,
}

const polygonSlice = createSlice({
    name: 'polygon',
    initialState,
    reducers: {
        setPolygons: (state, action) => {
            const { polygons, shouldUpdateHistory } = action.payload
            if (shouldUpdateHistory) {
                state.history.past.push([...state.polygons])
                state.history.future = []
            }
            state.polygons = polygons.length > 0 ? polygons : initialState.polygons
            const pointsLeft = state.polygons.some((polygon) => (polygon.points || []).length > 0)
            state.shouldCloseForm = !pointsLeft
        },
        setActivePolygonIndex: (state, action) => {
            state.activePolygonIndex = action.payload
        },
        updatePolygonLabel: (state, action) => {
            const { id, info } = action.payload
            const polygon = state.polygons.find((p) => p.id === id)
            if (polygon) {
                state.history.past.push([...state.polygons])
                state.polygons = state.polygons.map((p) => (p.id === id ? { ...p, ...info } : p))
                state.history.future = []
            }
        },
        updatePolygonColors: (state, action) => {
            const { id, color } = action.payload
            const polygon = state.polygons.find((p) => p.id === id)
            if (polygon) {
                state.history.past.push([...state.polygons])
                polygon.colors.lineColor = color
                polygon.colors.fillColor = hexToRGBA(color, '0.30')
                state.history.future = []
            }
        },
        deleteAll: (state) => {
            state.history.past.push([...state.polygons])
            state.polygons = []
            state.activePolygonIndex = 0
            state.history.future = []
            state.shouldCloseForm = true
        },
        undo: (state) => {
            const lastState = state.history.past.pop()
            if (lastState) {
                state.history.future.push([...state.polygons])
                state.polygons = lastState
                const pointsLeft = state.polygons.some((polygon) => polygon.points.length > 0)
                state.shouldCloseForm = !pointsLeft
            }
        },
        redo: (state) => {
            const nextState = state.history.future.pop()
            if (nextState) {
                state.history.past.push([...state.polygons])
                state.polygons = nextState
            }
        },
    },
})

export const {
    setPolygons,
    setActivePolygonIndex,
    updatePolygonLabel,
    updatePolygonColors,
    deleteAll,
    undo,
    redo,
} = polygonSlice.actions

export default polygonSlice.reducer
