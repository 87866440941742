/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderLogo from '../../../../static/images/blueLogo.png'
import BurgerMenu from './BurgerMenu'
import HeaderLogoLG from '../../../../static/images/blueLogoLG.png'
import LangSelector from '../LangSelector'
import { LangIcon, Moon, Sun, CancelBtn, BurgerBtn } from '../../../../components/svgs/Svgs'

export function Header({ open, setOpen }) {
    const [isDark, setIsDark] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()

    // Handle scrolling to add shadow to the header
    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 0)
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    // Initialize dark mode based on localStorage
    useEffect(() => {
        const savedMode = localStorage.getItem('darkMode')
        if (savedMode === 'true') {
            document.body.classList.add('dark')
            setIsDark(true)
        } else {
            document.body.classList.remove('dark')
            setIsDark(false)
        }
    }, [])

    // Toggle dark mode
    const toggleDarkMode = () => {
        const newMode = !isDark
        setIsDark(newMode)
        localStorage.setItem('darkMode', newMode.toString())
        if (newMode) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }

    return (
        <>
            <div
                className={`w-full pr-2 dark:bg-headerBlack relative z-50 flex items-center justify-between h-auto bg-white transition-shadow ${isScrolled ? 'fixed top-0 left-0 right-0 shadow-md' : ''
                }`}
            >
                {/* Logo */}
                <div onClick={() => navigate('/')} className="w-[59px] cursor-pointer lg:hidden h-[56px]">
                    <img src={HeaderLogo} alt="logo of the header" className="w-full h-full" />
                </div>
                <div onClick={() => navigate('/')} className="w-[237px] cursor-pointer hidden lg:block h-[69px]">
                    <img src={HeaderLogoLG} alt="logo of the header" className="w-full h-full" />
                </div>

                {/* Small Screen Buttons */}
                <div className="py-[18px] lg:hidden sm:flex items-center gap-[20px]">
                    <button
                        onClick={toggleDarkMode}
                        className="w-7 h-7 flex items-center text-[#0068B5] dark:text-white justify-center dark:bg-[#262626] bg-[#F7F7F7] rounded-[10px]"
                    >
                        {isDark ? <Sun /> : <Moon />}
                    </button>
                    <div className="w-5 h-5 text-[#0068B5] dark:text-white">
                        <LangIcon />
                    </div>
                    <div onClick={() => setOpen(!open)} className="w-5 h-5 text-[#0068B5] dark:text-white cursor-pointer">
                        {open ? (
                            <CancelBtn />
                        ) : (
                            <BurgerBtn />
                        )}
                    </div>
                </div>

                {/* Large Screen Links */}
                <div className="hidden lg:flex items-center gap-8">
                    <span onClick={() => navigate('/about')} className="text-sm dark:text-white cursor-pointer font-normal text-[#0068B5]">
                        {t('landing.navbar.about')}
                    </span>
                    <span onClick={() => navigate('/product')} className="text-sm dark:text-white cursor-pointer font-normal text-[#0068B5]">
                        {t('landing.navbar.product')}
                    </span>
                    <span onClick={() => navigate('/contact')} className="text-sm dark:text-white cursor-pointer font-normal text-[#0068B5]">
                        {t('landing.navbar.contact')}
                    </span>
                </div>

                {/* Dark Mode Toggle, LangSelector, and Login */}
                <div className="hidden lg:flex items-center gap-4">
                    <button
                        onClick={toggleDarkMode}
                        className="w-10 h-10 flex items-center justify-center rounded-full transition-all dark:bg-[#262626] bg-[#F7F7F7]"
                    >
                        <span className="text-[#0068B5] dark:text-white">
                            {isDark ? <Sun /> : <Moon />}
                        </span>

                    </button>
                    <LangSelector />
                    <button
                        onClick={() => navigate('/auth')}
                        className="px-6 py-2 bg-[#0068B5] text-white font-normal text-sm rounded-md hover:bg-blue-700"
                    >
                        {t('btn.login')}
                    </button>
                </div>
            </div>

            {/* Burger Menu */}
            <div className="w-full lg:hidden">
                <BurgerMenu open={open} close={() => setOpen(false)} />
            </div>

            {/* Prevent content hiding behind fixed header */}
            <div className={isScrolled ? 'pt-16' : ''}>{/* Page content goes here */}</div>
        </>
    )
}
