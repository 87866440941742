import React from 'react'
import moment from 'moment'
import Paper from '@mui/material/Paper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export default function AnalyticsFilters({ otherFilters = [] }) {
    const { logsFilter, setLogsFilter } = useFilter()

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3">
                <h3 className="text-m text-black font-500">Filter</h3>

                <div className="flex items-center gap-2">

                    <DatePicker
                        label="Select date"
                        sx={{ height: 37,
                            width: '11rem',
                            '& .MuiInputBase-root': { height: 37 },
                            // also move legend to the top
                            '& .MuiFormLabel-root': { top: '-8px' } }}
                        inputFormat="dd-MM-yyyy"
                        value={logsFilter.startPeriod ? moment(logsFilter.startPeriod) : null}
                        onChange={(e) => {
                            const formattedDate = e ? moment(e).format('YYYY-MM-DD') : null
                            setLogsFilter((old) => ({ ...old,
                                startPeriod: formattedDate,
                                endPeriod: formattedDate }))
                        }}
                        // display format should be dd-MM-yyyy
                        initialFocusedDate={moment()}
                    />
                    <DatePicker
                        label="Select date"
                        sx={{ height: 37,
                            width: '11rem',
                            '& .MuiInputBase-root': { height: 37 },
                            // also move legend to the top
                            '& .MuiFormLabel-root': { top: '-8px' } }}
                        inputFormat="dd-MM-yyyy"
                        value={logsFilter.endPeriod ? moment(logsFilter.endPeriod) : null}
                        onChange={(e) => {
                            const formattedDate = e ? moment(e).format('YYYY-MM-DD') : null
                            setLogsFilter((old) => ({ ...old,
                                startPeriod: formattedDate,
                                endPeriod: formattedDate }))
                        }}
                        // display format should be dd-MM-yyyy
                        initialFocusedDate={moment()}
                    />

                    {otherFilters.map((filterItem) => filterItem)}
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
