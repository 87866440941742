/* eslint-disable */
import React from 'react'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function Input({ type, placeholder, icon: IconComponent, register, name }) {
    const handleInput = (event) => {
        if (type === 'phone') {
            // eslint-disable-next-line no-param-reassign
            event.target.value = event.target.value.replace(/[^0-9+\-*/]/g, '')
        }
    }

    return (
        <div className="py-[15px] lg:py-[20px] w-[100%] flex items-center
        justify-between px-[24px] bg-[#F9F9F9] dark:bg-[#424242] dark:border-[#656565] border border-[#C2C2C2]"
        >
            <input
                type={type === 'phone' ? 'text' : type} // Use text type for custom validations
                className="w-[94%] bg-transparent h-[100%] dark:text-white text-black autofill:text-black dark:autofill:text-white outline-none"
                placeholder={placeholder}
                {...register(name, { required: true })}
                onInput={handleInput} // Apply the input handler for phone type
            />
            <div className="w-[6%]">
                {IconComponent && <IconComponent />}
            </div>
        </div>
    )
}
