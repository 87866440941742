import React, { useCallback, useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { debounce } from 'lodash'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { ALLOWED_REGIONS, DISTRICTS_LIST, TENANT_ENTITY_CUSTOMER_FILTER, TENANT_ENTITY_FILTER } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'

const identityGroupOptions = [
    {
        value: 0,
        label: 'Kids',
    },
    {
        value: 1,
        label: 'Staff',
    },
]

export default function IdentityInfoFilter({ otherFilters = [] }) {
    const { identityFilter, setIdentityFilters } = useFilter()
    const userType = localStorage.getItem('userType')
    const entityListUrl = userType === 'tenant_admin' ? TENANT_ENTITY_FILTER : TENANT_ENTITY_CUSTOMER_FILTER
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: identityFilter.rId } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOptions = districts.length ? districts.map((district) => ({ value: district.id, label: district.name })) : []

    const [searchTerm, setSearchTerm] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const getAllEntities = useGetRequest({ url: entityListUrl, params: { search: searchTerm, regionId: identityFilter.rId, districtId: identityFilter.dId } }, [])
    const data = getAllEntities.response ? getAllEntities.response : []
    const options = data ? data.map((e) => ({ value: e.id, label: e.name, col1: e.name, col2: e.region.name, col3: e.district.name })) : []

    // Debounce the onSearch callback
    const handleSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 300),
        [], // only create the debounce function once
    )

    // Handle search input changes
    const onSearchChange = (value) => {
        setSearchValue(value)
        handleSearch(value)
    }
    useEffect(() => {
        // Trigger request if searchTerm is not `null` (will run for empty string too)
        if (searchTerm !== null || identityFilter.dId) {
            getAllEntities.request()
        }
    }, [searchTerm, identityFilter.dId])

    useEffect(() => {
        setIdentityFilters({ dId: '' })
        if (identityFilter.rId) {
            getDistricts.request()
        }
    }, [identityFilter.rId])

    return (
        <Paper square className="flex flex-col gap-2.5 p-3">
            <h3 className="text-m text-black font-500">Filter</h3>

            <div className="flex items-center gap-2">
                <Select
                    variant="outlined"
                    displayEmpty
                    renderValue={(value) => (value ? regionOptions.find((v) => v.value === value)?.label : 'Choose region')}
                    sx={{ height: 37, width: '10rem' }}
                    value={identityFilter.rId}
                    onChange={(e) => setIdentityFilters({ rId: e.target.value })}
                >
                    {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                        <MenuItem key={menuItem.value} value={menuItem.value}>
                            {menuItem?.label}
                        </MenuItem>
                    )) : loadRegions.loading ? (
                        <MenuItem>
                            <Skeleton width="50%" height={30} />
                        </MenuItem>
                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                </Select>

                <Select
                    variant="outlined"
                    displayEmpty
                    renderValue={(value) => (value ? districtOptions.find((v) => v.value === value)?.label : 'Choose district')}
                    sx={{ height: 37, width: '10rem' }}
                    value={identityFilter.dId}
                    disabled={!districtOptions.length}
                    onChange={(e) => setIdentityFilters({ dId: e.target.value })}
                >
                    {!getDistricts.loading && districtOptions.length ? districtOptions.map((menuItem) => (
                        <MenuItem key={menuItem.value} value={menuItem.value}>
                            {menuItem?.label}
                        </MenuItem>
                    )) : getDistricts.loading ? (
                        <MenuItem>
                            <Skeleton width="50%" height={30} />
                        </MenuItem>
                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                </Select>
                <SelectInput
                    styles={{
                        minWidth: '12rem',
                        '& .MuiInputBase-root': {
                            minHeight: '30px', // Set minimum height for container
                            padding: '4px 8px', // Adjust padding for a compact look
                        },
                        '& .MuiSelect-select': {
                            padding: '4px 8px', // Adjust padding inside the select to reduce height
                            display: 'flex',
                            alignItems: 'center', // Vertically center text
                            minHeight: '20px', // Set the desired minimum height here
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            minHeight: '30px', // Match the outline to the container height
                        },
                        '& .MuiInputLabel-root': {
                            top: '-7px', // Move label slightly upward
                        },
                    }}
                    variant="outlined"
                    name="tenantEntityId"
                    label="Choose entity"
                    selectPlaceholder="Choose entity"
                    onChange={(e) => setIdentityFilters({ tenantEntityId: e.target.value })}
                    value={identityFilter.tenantEntityId}
                    menuItems={options}
                    loading={getAllEntities.loading}
                    searchable
                    placeholder="Search entities..."
                    onSearch={onSearchChange}
                    searchValue={searchValue}
                    tableMode
                />
                <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="identity-group-label">Identity Type</InputLabel>
                    <Select
                        labelId="identity-group-label"
                        id="event-type-select"
                        label="Identity Type"
                        disabled={!identityGroupOptions.length}
                        value={identityFilter.identityGroup}
                        onChange={(e) => setIdentityFilters({ identityGroup: e.target.value })}
                        variant="outlined"
                    >
                        {identityGroupOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {otherFilters.map((filterItem) => filterItem)}
            </div>
        </Paper>
    )
}
