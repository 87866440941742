import React from 'react'
import moment from 'moment'
import { Avatar, TableCell } from '@mui/material'

export default function SpeechTableItem({ item, index }) {
    const fullName = `${item.identity.firstName} ${item.identity.lastName}`

    const firstEntry = item.analytics.roiFirstEntrance || '-'
    const lastExit = item.analytics.roiLastExit || '-'

    // 2024-09-06 17:31:56 - get just hh:mm
    const formattedFirstEntry = moment(firstEntry).format('HH:mm')
    const formattedLastExit = moment(lastExit).format('HH:mm')

    return (
        <>
            <TableCell className="cursor-pointer">
                {index + 1}
            </TableCell>

            <TableCell className="cursor-pointer">
                <Avatar alt={fullName} src={item.identity.photo} />
            </TableCell>

            <TableCell className="cursor-pointer">
                {fullName}
            </TableCell>

            <TableCell className="cursor-pointer">
                {formattedFirstEntry !== 'Invalid date' && formattedFirstEntry} -
                {formattedLastExit !== 'Invalid date' && formattedLastExit}
            </TableCell>

        </>
    )
}
