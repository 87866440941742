import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Chip, Tooltip } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { LoadingSkeleton } from '../../../components/common/LoadingSkeleton'
import { TrashIcon } from '../../../components/svgs/Svgs'
import Modal from '../../../components/common/Modal'
import DeleteModal from '../../../components/common/DeleteModal'
import { NoUserPhoto } from '../../../components/svgs/SvgPictures'
import { isImageReal } from '../../../shared/utils/string'

function Skeleton({ idx }) {
    return (
        <tr key={idx} className={css(s.row)}>
            <td><LoadingSkeleton width={13} borderRadius={6} height={13} /></td>

            <td><LoadingSkeleton height={35} width={35} borderRadius="50%" /></td>

            <td><LoadingSkeleton height={13} /></td>

            <td><LoadingSkeleton height={13} /></td>

            <td />
        </tr>
    )
}

export default function SCameraClientsTableItem({ item, idx, smarCameraId, loading }) {
    const modalRef = useRef()

    // Check if the smartCameraId exists in the identitySmartCameras array
    const isActive = item.identitySmartCameras && item.identitySmartCameras.some((camera) => camera.smartCameraId === smarCameraId)
    const errors = item.errors || []

    return !loading ? (
        <tr className={css(s.row)}>
            <td>{idx + 1}.</td>

            <td>
                <div className={cn(css(s.userImg), 'flex-col items-start justify-center')}>
                    {isImageReal(item.photo)
                        ? <img className="rounded-[6px]" src={item.photo} alt="#USER" />
                        : <NoUserPhoto />}
                </div>
            </td>

            <td>{item.firstName}</td>

            <td>{item.lastName}</td>

            <td className="cursor-pointer">
                {errors.length !== 0 ? (
                    <Tooltip title={errors[0]?.errorMessage}>
                        <Chip
                            key={errors[0]?.id} // Use a unique identifier
                            label={errors[0]?.errorMessage} // Use a string property for the label
                            color="error"
                            style={{ backgroundColor: COLORS.error }}
                        />
                    </Tooltip>
                ) : '-'}
            </td>

            <td>
                {isActive && (
                    <div className="flex items-center justify-end gap-4">
                        <Modal
                            modalRef={modalRef}
                            closeOnEscape
                            trigger={(
                                <span onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }} className="pointer">
                                    <TrashIcon color={COLORS.disabled} />
                                </span>
                            )}
                            closeOnDocumentClick
                            contentStyle={{ height: 'calc(100% - 4rem)', zIndex: 2 }}>
                            <DeleteModal onClose={() => modalRef.current.close()} onDelete={() => {}} />
                        </Modal>
                    </div>
                )}
            </td>
        </tr>
    ) : <Skeleton idx={idx} />
}

const s = StyleSheet.create({
    row: {
        background: COLORS.white,
        color: COLORS.black,
        fontSize: 13,
        fontWeight: '400',
        userSelect: 'none',
        borderBottom: `1px solid ${COLORS.sepia}`,
        ':nth-child(1n) > td:first-child': {
            paddingLeft: 23,
        },
        ':nth-child(1n) > *': {
            textAlign: 'left',
            padding: '10px 0',
        },
        ':nth-child(1n) > td:last-child': {
            ':nth-child(1n) > :only-child': {
                paddingRight: 35,
            },
        },
    },
    userImg: {
        ':nth-child(1n) > img': {
            width: 46,
            height: 46,
            objectFit: 'cover',
        },
    },
})
