import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import uuid from 'react-uuid'
import { Box } from '@mui/material'
import Table from '../../../components/common/Table'
import { COLORS } from '../../../shared/utils/colors'
import SCameraClientsTableItem from './SCameraClientsTableItem'
import { useGetRequest } from '../../../shared/hooks/requests'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { IDENTITY_LIST,
    UNLOADED_IDENTITY_LIST,
    LOADED_IDENTITY_LIST,
    SCAMERA_WANTED_LIST,
    SYNC_IDENTITIES_SCAMERA,
    UPLOAD_IDENTITIES_SCAMERA } from '../../../shared/utils/urls'
import { SyncIcon } from '../../../components/svgs/Svgs'

const headerItems = [
    {
        id: 1,
        name: '№',
    },
    {
        id: 2,
        name: 'Photo',
    },
    {
        id: 3,
        name: 'First name',
    },
    {
        id: 4,
        name: 'Last name',
    },
    {
        id: 5,
        name: 'Error',
    },
    {
        id: 6,
        name: 'Action',
    },
]

const CAMERA_CLIENTS_URLS = {
    wanted: SCAMERA_WANTED_LIST,
    identity: IDENTITY_LIST,
    loadedIdentity: LOADED_IDENTITY_LIST,
    unloadedIdentity: UNLOADED_IDENTITY_LIST,
}

export default function SCameraClientsTable({
    section,
    id,
    tenantEntityId = '',
}) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const getSCameraClients = useGetRequest()
    const data = getSCameraClients.response ? getSCameraClients.response.items : []
    const meta = getSCameraClients.response ? getSCameraClients.response : {}

    const syncIdentities = useGetRequest({
        url: SYNC_IDENTITIES_SCAMERA,
        params: { smartCameraId: id },
    }, [])
    const uploadIdentities = useGetRequest({
        url: UPLOAD_IDENTITIES_SCAMERA,
        params: {
            smartCameraId: id,
            tenantEntityId,
        },
    }, [])

    useEffect(() => {
        if (section !== 'detail') {
            getSCameraClients.request({
                url: CAMERA_CLIENTS_URLS[section],
                params: {
                    size: pageSize,
                    page,
                    ...(section === 'loadedIdentity' || section === 'unloadedIdentity' ? { smartCameraId: id } : {}),
                },
            })
        }
    }, [section, page, pageSize])

    return (
        <div className={css(s.cont)}>
            <Table
                isHoverable
                setPage={setPage}
                setPageSize={setPageSize}
                pageCount={meta.pages}
                count={meta.total}
                page={page}
                pageSize={pageSize}
                headerClassName={s.tHead}
                items={data}
                loaderItemCount={5}
                columns={headerItems}
                loading={getSCameraClients.loading}
                renderItem={(item, idx) => (
                    <SCameraClientsTableItem
                        idx={idx}
                        item={item}
                        smarCameraId={id}
                        key={uuid()}
                        loading={getSCameraClients.loading}
                    />
                )}
            />
            <Box sx={{ display: 'flex' }}>
                {section === 'loadedIdentity' && (
                    <PrimaryBtn
                        title="Sync all identities"
                        Icon={SyncIcon}
                        iconProps={{
                            width: 18,
                            height: 18,
                        }}
                        loading={syncIdentities.loading}
                        styles={{
                            margin: '10px 0 0 20px',
                        }}
                        onClick={() => {
                            syncIdentities.request()
                        }}
                    />
                )}
                {section === 'unloadedIdentity' && (
                    <PrimaryBtn
                        title="Upload all identities"
                        iconProps={{
                            width: 18,
                            height: 18,
                        }}
                        loading={uploadIdentities.loading}
                        styles={{
                            margin: '10px 0 0 20px',
                        }}
                        onClick={() => {
                            uploadIdentities.request()
                        }}
                    />
                )}
            </Box>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '100%',
    },
    header: {
        background: COLORS.white,
        height: 60,
        padding: 18,
        ':nth-child(1n) > h3': {
            fontSize: 18,
            fontWeight: '600',
            color: COLORS.black,
        },
    },
    filter: {
        border: `1px solid ${COLORS.sepia}`,
        background: COLORS.white,
        padding: 14,
    },
    btnCont: {
        ':nth-child(1n) > :first-child': {
            borderRadius: '5px 0px 0px 5px',
            borderRight: `1px solid ${COLORS.darkBlue}`,
        },
        ':nth-child(1n) > :last-child': {
            borderRadius: '0px 5px 5px 0px',
        },
    },
    btns: {
        background: COLORS.sepia,
        padding: '8px 20px',
        fontWeight: '500',
        color: COLORS.mainBlueBg,
        fontSize: 14,
        ':hover': {
            background: COLORS.mainBlueBg,
            color: COLORS.white,
        },
    },
    active: {
        background: COLORS.mainBlueBg,
        color: COLORS.white,
    },
    tHead: {
        ':nth-child(1n) > tr': {
            ':nth-child(1n) > *': {
                textAlign: 'left',
            },
            ':nth-child(1n) > th:first-child': {
                width: '8%',
            },
            ':nth-child(1n) > th:last-child': {
                textAlign: 'right',
                paddingRight: 35,
            },
        },
    },
})
