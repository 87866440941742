/* eslint-disable */
import React, { useState } from 'react'
import { TableCell } from '@mui/material'
import { Update } from '../features/Update'

export default function TenantEntityTableItem({ item, setData }) {
    const [openDrawer, setOpenDrawer] = useState(false)

    const open = () => setOpenDrawer(true)
    const close = () => setOpenDrawer(false)
    return (
        <>
            <TableCell onClick={open} className="cursor-pointer">
                {item.name.length > 20 ? `${item.name.slice(0, 20)}...` : item.name}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.buildingCount}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.roomCount}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.smartCameraCount}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.allowedRadius === null ? '-' : item.allowedRadius}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.faceAuthThreshold === null ? '-' : item.faceAuthThreshold}
            </TableCell>
            <TableCell onClick={open} className="cursor-pointer">
                {item.spoofingThreshold === null ? '-' : item.spoofingThreshold}
            </TableCell>
            <TableCell style={{ paddingRight: 8 }} align="right">
                <div className="flex items-center justify-end">
                    <Update isOpen={openDrawer} open={open} close={close} item={item} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
