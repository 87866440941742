/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import SearchInput from '../../../shared/ui/SearchInput'
import Table from '../../../shared/ui/Table'
import { AttendanceAnalyticsTableItem } from './AttendanceAnalyticsTableItem'
import { AttendanceAnalyticsFilter } from './AttendanceAnalyticsFilter'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ATTENDANCE_ANALYTICS } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const cols = [
    { id: 1, title: 'Attendance ID' },
    { id: 2, title: 'Name' },
    { id: 3, title: 'kids count' },
    { id: 4, title: 'employee count' },
    { id: 5, title: 'kids total' },
    { id: 6, title: 'employees total' },
    { id: 7, title: 'attendace ratio' },
    { id: 8, title: 'Batafsil' },
]

export function AttendanceAnalyticsTable() {
    const { attendFilter, setAttendFilters } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadAttendanceData = useGetRequest({
        url: ATTENDANCE_ANALYTICS,
        params: {
            page,
            size: pageSize,
            attendance_date: attendFilter.date,
            ...(attendFilter.search !== '' ? { tenant_entity_name_search: attendFilter.search } : {}),
            ...(attendFilter.region !== '' ? { region_id: attendFilter.region } : {}),
            ...(attendFilter.district !== ''
            && attendFilter.region !== '' ? { district_id: attendFilter.district } : {}),
            ...(attendFilter.sort_type !== '' ? { attendance_sort_type: attendFilter.sort_type } : {}),
            ...(attendFilter.sort_role !== '' ? { attendance_sort_role: attendFilter.sort_role } : {}),
            ...(attendFilter.sort_quantity !== '' ? { attendance_sort_quantity: attendFilter.sort_quantity } : {}),
            ...(attendFilter.ratio_from !== '' ? { attendance_ratio_from: attendFilter.ratio_from } : {}),
            ...(attendFilter.ratio_to !== '' ? { attendance_ratio_to: attendFilter.ratio_to } : {}),
        },
    })

    useEffect(() => {
        if (attendFilter.region && attendFilter.district
            && attendFilter.sort_type && attendFilter.sort_role && attendFilter.sort_quantity) {
            loadAttendanceData.request()
        }
    }, [attendFilter, page, pageSize])

    const data = loadAttendanceData.response ? loadAttendanceData.response.items : []
    const meta = loadAttendanceData.response ? loadAttendanceData.response.total : 0

    const handleSearch = (value) => {
        setAttendFilters({ search: value })
    }

    useEffect(() => {
        if (attendFilter.search) {
            setPage(1)
        }
    }, [attendFilter.search])

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <AttendanceAnalyticsFilter />
            </div>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Attendance Analytics
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadAttendanceData.loading}
                    totalCount={meta}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <AttendanceAnalyticsTableItem
                            item={item}
                            key={item.id}
                            setData={loadAttendanceData.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
