import React from 'react'
import { TableCell } from '@mui/material'
import Details from '../features/details'
import { Photos } from '../features/photos'

export function ReviewPhotosTableItems({ item, setResponse }) {
    return (
        <>
            <TableCell>
                {item.individualPersonalNumber}
            </TableCell>
            <TableCell>
                <div className="w-[53px] h-[66px] py-[5px] rounded-[5px] overflow-hidden">
                    <img src={item.imageUrl} alt="img of the objectName"
                        className="w-full h-full rounded-[5px] object-cover" />
                </div>
            </TableCell>
            <TableCell>
                <span
                    className={`py-[4px] border rounded-[4px] px-[14px] ${item.status === 'pending'
                        ? 'border-[#FF7A00] bg-[#ff7a001f] text-[#FF7A00]'
                        : item.status === 'success' ? 'border-[#2B8906] bg-[#37B4001F] text-[#0B9E34]'
                            : 'border-[#FF0000] bg-[#FF00001F] text-[#FF0000]'}`}>
                    {item.status.toUpperCase()}
                </span>
            </TableCell>
            <TableCell>
                <div className="flex items-center justify-end gap-[14px]">
                    <Photos item={item} setResponse={setResponse} />
                    <Details personalNumber={item.individualPersonalNumber} />
                </div>
            </TableCell>
        </>
    )
}
