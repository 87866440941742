// All.jsx
import React, { useState, useEffect } from 'react'
import { Paper, Tabs, Tab } from '@mui/material'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import Table from './Table'
import { COLORS } from '../../../shared/utils/colors'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import { WORKSPACE_ANALYTICS_LIST,
    LINECROSSING_ANALYTICS_DAILY_REPORT,
    ANALYTICS_HISTORY } from '../../../shared/utils/urls'

const tabItems = [
    { id: 'working', label: 'ROI employing working hours' },
    { id: 'service', label: 'ROI employee service time' },
    { id: 'intruder', label: 'Intruder zone' },
    { id: 'lineCrossing', label: 'Line Crossing' },
]

const workingCols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Camera name' },
    { id: 3, title: 'Photo' },
    { id: 4, title: 'Full name' },
    { id: 5, title: 'At workplace' },
    { id: 6, title: 'At workplace min' },
    { id: 7, title: 'Date' },
]

const serviceCols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Camera name' },
    { id: 3, title: 'Photo' },
    { id: 4, title: 'Full name' },
    { id: 5, title: 'At service' },
    { id: 6, title: 'At service min' },
    { id: 7, title: 'Date' },
]

const lineCrossingCols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Date' },
    { id: 3, title: 'Line name' },
    { id: 4, title: 'Crossings' },
]

const intriderCols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Date' },
    { id: 3, title: 'Intruding' },
]

export default function All() {
    const [selectedTab, setSelectedTab] = useState('working')
    const [activeCols, setActiveCols] = useState(workingCols)
    const [tabData, setTabData] = useState({
        working: [],
        service: [],
        intruder: [],
        queue: [],
        lineCrossing: [],
    })
    const [loading, setLoading] = useState(false) // Added loading state

    const startPeriod = moment().subtract(6, 'days').format('YYYY-MM-DD')
    const endPeriod = moment().format('YYYY-MM-DD')

    const { streamAnalyticsFilter } = useFilter()
    const { id } = streamAnalyticsFilter

    // Initialize the API requests for each tab
    const getWorkspaceAnalytics = useGetRequest({
        url: WORKSPACE_ANALYTICS_LIST,
        params: {
            jetson_device_id: id,
            start_period: startPeriod,
            end_period: endPeriod,
            limit: 100,
        },
    })

    const getLineCrossingAnalytics = useGetRequest({
        url: LINECROSSING_ANALYTICS_DAILY_REPORT,
        params: {
            jetson_device_id: id,
            start_period: startPeriod,
            end_period: endPeriod,
            limit: 100,
            offset: 0,
        },
    })

    const getIntruderAnalytics = useGetRequest({
        url: ANALYTICS_HISTORY,
        params: {
            jetson_device_id: id,
            alarm_category: 'safe-zone',
            start_period: startPeriod,
            end_period: endPeriod,
            limit: 100,
            offset: 0,
        },
    })

    const fetchDataForTab = async (tabId) => {
        setLoading(true) // Start loading
        if (tabId === 'working' || tabId === 'service') {
            // Fetch data for the 'working' or 'service' tab
            const { response } = await getWorkspaceAnalytics.request()
            const data = response || []
            // Transform data as needed
            const copyData = data.map((item) => ({ ...item }))
            const duplicatedData = copyData.reduce((acc, curr) => {
                const { identity, analyticsReport } = curr
                if (analyticsReport.length > 0) {
                    const duplicated = analyticsReport.map((item) => ({ ...item, identity }))
                    return [...acc, ...duplicated]
                }
                // Add a placeholder or default analytics item
                const defaultAnalytics = {
                    analytics: {
                        roiFirstEntrance: null,
                        roiLastExit: null,
                        minutesWorkTime: 0,
                        minutesServiceTime: 0,
                    },
                    date: null, // or a default date
                    identity,
                }
                return [...acc, defaultAnalytics]
            }, [])
            setTabData((prevData) => ({
                ...prevData,
                working: duplicatedData,
                service: duplicatedData,
            }))
        } else if (tabId === 'lineCrossing') {
            // Fetch data for the 'lineCrossing' tab
            const { response } = await getLineCrossingAnalytics.request()
            const data = response || []
            setTabData((prevData) => ({
                ...prevData,
                [tabId]: data,
            }))
        } else if (tabId === 'intruder') {
            // Fetch data for the 'intruder' tab
            const { response } = await getIntruderAnalytics.request()
            const data = response || []
            setTabData((prevData) => ({
                ...prevData,
                [tabId]: data,
            }))
        } else {
            // Placeholder for other tabs; you can add actual API calls here
            setTabData((prevData) => ({
                ...prevData,
                [tabId]: [], // Initialize with empty array or fetched data
            }))
        }
        setLoading(false) // End loading
    }

    useEffect(() => {
        // setTabData({
        //     working: [],
        //     service: [],
        //     intruder: [],
        //     queue: [],
        //     lineCrossing: [],
        // })
        const fetchData = async () => {
            // Set active columns based on the selected tab
            if (selectedTab === 'working') {
                setActiveCols(workingCols)
            }
            if (selectedTab === 'service') {
                setActiveCols(serviceCols)
            }
            if (selectedTab === 'lineCrossing') {
                setActiveCols(lineCrossingCols)
            }
            if (selectedTab === 'intruder') {
                setActiveCols(intriderCols)
            }
            if (tabData[selectedTab].length === 0) {
                await fetchDataForTab(selectedTab)
            }
            // else if (selectedTab === 'service') {
            //     setActiveCols(serviceCols)
            // } else if (selectedTab === 'lineCrossing') {
            //     setActiveCols(lineCrossingCols)
            // } else {
            //     setActiveCols([])
            // }
        }
        fetchData()
    }, [selectedTab, streamAnalyticsFilter])

    return (
        <Paper square className="flex flex-col gap-2.5 p-3 mt-[14px]">
            <h3 className="text-m text-black font-600">All</h3>
            <TabFilter selectedTab={selectedTab} setSelectedTab={setSelectedTab} disabled={loading} />
            <Table
                data={tabData}
                cols={activeCols}
                selectedTab={selectedTab}
                loading={loading}
            />
        </Paper>
    )
}

function TabFilter({ selectedTab, setSelectedTab, disabled }) {
    const handleTabChange = (event, newValue) => {
        if (disabled) return
        setSelectedTab(newValue)
    }

    return (
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tab filters"
            className={css(s.tabs)}
            indicatorColor="none"
        >
            {tabItems.map((tab) => (
                <Tab
                    key={tab.id}
                    label={tab.label}
                    value={tab.id}
                    className={css(s.tab)}
                    classes={{
                        selected: css(s.tabSelected),
                    }}
                />
            ))}
        </Tabs>
    )
}

const s = StyleSheet.create({
    tabs: {
        backgroundColor: COLORS.sepia,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        minHeight: '38px',
        width: '100%',
    },
    tab: {
        minHeight: '38px',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        fontWeight: 400,
        padding: '6px 10px',
        textTransform: 'capitalize',
        flexGrow: 1,
        textAlign: 'center',
        maxWidth: '100%',
        ':nth-child(2n)': {
            borderRight: `1px solid ${COLORS.gainsboro}`,
            borderLeft: `1px solid ${COLORS.gainsboro}`,
        },
        ':last-child': {
            borderRight: 'none',
        },
        ':hover': {
            opacity: 0.8,
        },
    },
    tabSelected: {
        backgroundColor: COLORS.mainBlue,
        color: COLORS.white,
    },
})
