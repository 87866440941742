import React from 'react'
import moment from 'moment'
import { css, StyleSheet } from 'aphrodite'
import { Avatar, TableCell } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'

export default function AnalyticsTableItem({ item, index, handleOpen }) {
    const fullName = `${item.identity.firstName} ${item.identity.lastName}`

    const totalMinutes = item.analytics.minutesWorkTime || 0
    const firstEntry = item.analytics.roiFirstEntrance || '-'
    const lastExit = item.analytics.roiLastExit || '-'

    // 2024-09-06 17:31:56 - get just hh:mm
    const formattedFirstEntry = moment(firstEntry).format('HH:mm')
    const formattedLastExit = moment(lastExit).format('HH:mm')

    return (
        <>
            <TableCell className="cursor-pointer">
                {index + 1}
            </TableCell>

            <TableCell className="cursor-pointer">
                <Avatar alt={fullName} src={item.identity.photo} />
            </TableCell>

            <TableCell className="cursor-pointer">
                {fullName}
            </TableCell>

            <TableCell className="cursor-pointer">
                {formattedFirstEntry !== 'Invalid date' && formattedFirstEntry} -
                {formattedLastExit !== 'Invalid date' && formattedLastExit}
            </TableCell>

            <TableCell>{ totalMinutes.toFixed(0) } </TableCell>

            <TableCell>
                {/* <button onClick={() => setOpen(true)}>Details</button> */}
                <Button onClick={() => handleOpen(item)} className={css(s.actBtn)}>Details</Button>
            </TableCell>
        </>
    )
}

const s = StyleSheet.create({
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        ':hover': {
            background: COLORS.mainBlue,
        },
    },
})
