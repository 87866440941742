import React, { useState } from 'react'
import { Grid, Typography, Paper } from '@mui/material'
import { FaqIcon } from '../../../components/svgs/Svgs'
import Card from './Card'
import Sections from './Sections'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { useInfiniteScroll } from '../../../shared/hooks/requests'
import { TENANT_ENTITIES } from '../../../shared/utils/urls'
import Create from '../features/Create'
import { useAddQueryParams } from '../../../shared/hooks/addQueryParams'
import SearchInput from '../../../shared/ui/SearchInput'

export default function HeadQuarters() {
    const addQueryParams = useAddQueryParams()
    const { id } = useQueryParams()
    const [searchTerm, setSearchTerm] = useState(null)

    const getHeads = useInfiniteScroll(
        { url: TENANT_ENTITIES, params: { hierarchyLevel: 1, search: searchTerm } },
        true,
        [searchTerm],
    )

    const data = getHeads.response ? getHeads.response.items : []
    const meta = getHeads.response ? getHeads.response : {}

    const handleSearch = (value) => {
        setSearchTerm(value)
    }

    // useEffect(() => {
    //     if (searchTerm || searchTerm === '') {
    //         getHeads.request()
    //     }
    // }, [searchTerm, getHeads])

    return (
        <Paper sx={{ display: 'flex', flexDirection: 'column', gap: '40px', padding: '22px 18px' }}>
            <div className="flex-col gap-7_5">
                <div className="flex items-center justify-between">
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        Head Quarters (
                        {data && data.length}
                        )
                        <FaqIcon />
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <Create hierarchyLevel={1} setData={getHeads.setResponse} />
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>

                <Grid container spacing={2}>
                    {data && data.map((item) => (
                        <Card
                            item={item}
                            key={item.id}
                            loading={getHeads.loading}
                            setData={getHeads.setResponse}
                            isActive={item.id === +id}
                            onClick={() => addQueryParams({
                                id: item.id,
                                p_name: item.name,
                            })}
                        />
                    ))}
                </Grid>

                {getHeads.hasMore && meta.total > meta.size && <div ref={getHeads.ref} />}
            </div>

            <Sections headQuarters={data} />
        </Paper>
    )
}
