import React, { useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'
import { Autocomplete, TextField } from '@mui/material'
import { isImageReal } from '../../../shared/utils/string'
import { ACTIVE_CAMERAS_IP,
    FILE_UPLOAD,
    JETSON_DEVICES_BY_BUILDING,
    NETWORK_INTERFACES,
    ROOMS_TENANT_UNPAGINATED,
    JETSON_DEVICE_SNAPSHOTS } from '../../../shared/utils/urls'
import { PhotoCameraIcon } from '../../../components/svgs/Svgs'
import useImageUpload from '../../../shared/hooks/imageUploadHandler'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'

export const customFieldStyles = {
    '& .MuiInputBase-input': {
        paddingBottom: '8px',
        fontSize: 16,
        fontWeight: 400,
        color: COLORS.black,
    },
    '& .MuiInputLabel-root': {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'rgba(0, 0, 0, 0.54)',
    },
    // eslint-disable-next-line max-len
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#7C7D7E',
        borderBottomWidth: '1px',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        borderBottomWidth: '1px',
    },
}

const FORM_INITIAL_STATE = {
    name: '',
    description: '',
    rtspUrl: '',
    vpnRtspUrl: '',
    streamUrl: '',
    roomId: '',
    jetsonDeviceId: '',
    username: '',
    password: '',
    ipAddress: '',
    snapshotUrl: '',
    fileName: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    description: string(),
    rtspUrl: string().required('RTSP URL is required'),
    vpnRtspUrl: string().optional(),
    streamUrl: string().optional(),
    roomId: number().required('Room is required'),
    jetsonDeviceId: string().required('Jetson Device is required'),
    ipAddress: string().required('Camera IP is required'),
    snapshotUrl: string().optional(),
})

export default function CameraForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues = {},
    loading,
    isEdit = false,
}) {
    const { handleErrorMsg } = useHandleErrors()
    const inputRef = useRef()
    const params = useParams()
    const { onFileUpload } = useImageUpload()
    const { cameraFilter } = useFilter()
    const getJetsonDevices = useGetRequest({ url: JETSON_DEVICES_BY_BUILDING.replace('{id}', cameraFilter.bId || '') })
    const getCamerasIp = usePostRequest({ url: ACTIVE_CAMERAS_IP })
    const getNetworkInterfaces = usePostRequest({ url: NETWORK_INTERFACES })
    const getSnapshots = usePostRequest({ url: JETSON_DEVICE_SNAPSHOTS }, [])
    const getRooms = useGetRequest({
        url: ROOMS_TENANT_UNPAGINATED,
        params: { buildingId: params.id || cameraFilter.bId },
    })

    const formikRef = useRef()

    // Fetch rooms when the component mounts
    useEffect(() => {
        if (isEdit) {
            getRooms.request()
        }
    }, [isEdit])

    // Fetch Jetson devices when in edit mode and roomId is available
    useEffect(() => {
        if (isEdit && initialValues.roomId) {
            getJetsonDevices.request()
        }
    }, [isEdit, initialValues.roomId])

    // Set active Jetson device based on jetsonDeviceId from initialValues
    useEffect(() => {
        if (isEdit && initialValues.jetsonDeviceId && getJetsonDevices.response) {
            const jetsonOptions = getJetsonDevices.response.map((item) => ({
                value: item.deviceId,
                label: item.deviceName,
                id: item.id,
            }))

            const selectedOption = jetsonOptions.find(
                (option) => option.id === initialValues.jetsonDeviceId,
            )

            if (selectedOption && formikRef.current) {
                formikRef.current.setFieldValue('jetsonDeviceId', selectedOption.value)
            }
        }
    }, [isEdit, initialValues.jetsonDeviceId, getJetsonDevices.response])

    const roomOptions = getRooms.response && getRooms.response.length
        ? getRooms.response.map((r) => ({ value: r.id, label: r.name }))
        : []

    const jetsonOptions = getJetsonDevices.response
        ? getJetsonDevices.response.map((item) => ({
            value: item.deviceId,
            label: item.deviceName,
            id: item.id,
        }))
        : []

    const camerasIP = getCamerasIp.response ? getCamerasIp.response.map((ip) => ip) : []

    const getScanningNetwork = async (jetsonDeviceId, setValue) => {
        if (!jetsonDeviceId) return
        const { response, success, error } = await getNetworkInterfaces.request({
            data: { jetsonDeviceId },
        })
        if (success && response) {
            const ethernetInterfaces = response.filter((val) => {
                const interfaceName = val[0]
                return /^eth|^enp|^enx/.test(interfaceName)
            })
            if (ethernetInterfaces.length > 0) {
                setValue('scanningNetwork', ethernetInterfaces[0][1])
            } else {
                setValue('scanningNetwork', '')
            }
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const assembleRtspUrl = (values, password, setFieldValue) => {
        const { username, ipAddress } = values
        setFieldValue('rtspUrl', `rtsp://${username}:${password}@${ipAddress}/`)
    }

    const getSnapshot = async (values, setFieldValue) => {
        const url = `${values.rtspUrl}${values.streamUrl}`
        const { response, success } = await getSnapshots.request({
            data: {
                jetsonDeviceId: values.jetsonDeviceId,
                rtsp_connection_url: url,
                camera_id: values.id,
            },
        })

        if (success) {
            setFieldValue('snapshotUrl', response.snapshot)
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ handleChange, values, setFieldValue }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <div className="w-full flex items-center gap-4">
                            {isImageReal(values.snapshotUrl) ? (
                                <img
                                    src={values.snapshotUrl}
                                    alt="#OFFICE"
                                    className=" w-[60px] h-[60px] rounded"
                                />
                            ) : (
                                <div className="flex items-center justify-center bg-gray-100 w-[60px] h-[60px] rounded">
                                    <PhotoCameraIcon />
                                </div>
                            )}

                            <FormTextField
                                fullWidth
                                type="text"
                                label="Snapshot"
                                name="fileName"
                                onClick={() => inputRef.current.click()}
                            />

                            <input
                                id="file"
                                type="file"
                                name="snapshotUrl"
                                ref={inputRef}
                                readOnly
                                className="not-visible"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileUpload(e, setFieldValue, 'snapshotUrl', FILE_UPLOAD)}
                            />
                        </div>
                        {isEdit && (
                            <PrimaryBtn
                                onClick={() => getSnapshot(values, setFieldValue)}
                                title="Get Snapshot"
                                loading={getSnapshots.loading}
                                styles={{ width: 'fit-content' }}
                            />
                        )}

                        <FormTextField
                            label="Name"
                            name="name"
                            required
                            handleChange={handleChange}
                        />

                        <FormTextField
                            multiline
                            label="Description"
                            name="description"
                            handleChange={handleChange}
                        />

                        <SelectInput
                            required
                            name="roomId"
                            label="Room"
                            menuItems={roomOptions}
                            onOpen={() => getRooms.request()}
                            onChange={(e) => setFieldValue('roomId', e.target.value)}
                        />

                        <SelectInput
                            required
                            // disabled={!values.roomId}
                            name="jetsonDeviceId"
                            label="Select Jetson Device"
                            menuItems={jetsonOptions}
                            onOpen={() => getJetsonDevices.request()}
                            onChange={(e) => {
                                setFieldValue('jetsonDeviceId', e.target.value)
                                const selectedOption = jetsonOptions.find(
                                    (option) => option.value === e.target.value,
                                )
                                setFieldValue('jetsonDeviceIdNum', selectedOption.id)
                                getScanningNetwork(e.target.value, setFieldValue)
                            }}
                        />

                        <Autocomplete
                            freeSolo
                            disabled={!values.jetsonDeviceId || getNetworkInterfaces.loading}
                            options={camerasIP}
                            value={values.ipAddress || ''}
                            onOpen={() => {
                                const ip = values.scanningNetwork
                                let networkAddress = ip
                                const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/
                                if (ipRegex.test(ip)) {
                                    const ipParts = ip.split('.')
                                    // eslint-disable-next-line no-unused-vars
                                    networkAddress = `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.0/24`
                                } else {
                                    console.error('Invalid IP address format:', ip)
                                }
                                getCamerasIp.request({
                                    data: {
                                        jetsonDeviceId: values.jetsonDeviceId,
                                        // scanningNetwork: networkAddress,
                                        scanningNetwork: '10.20.50.0/24',
                                    },
                                })
                            }}
                            loading={getCamerasIp.loading}
                            noOptionsText="No Options"
                            onChange={(_, newValue) => setFieldValue('ipAddress', newValue)}
                            renderInput={(p) => (
                                <TextField
                                    required
                                    label={
                                        camerasIP.length ? 'Select Camera IP' : 'Enter Camera IP'
                                    }
                                    name="ipAddress"
                                    onChange={handleChange}
                                    variant="standard"
                                    sx={customFieldStyles}
                                    {...p}
                                />
                            )}
                        />

                        <div className="flex items-center gap-3">
                            <FormTextField
                                fullWidth
                                name="username"
                                label="Username"
                                handleChange={handleChange}
                            />

                            <FormTextField
                                fullWidth
                                name="password"
                                label="Password"
                                onChange={(e) => {
                                    handleChange(e)
                                    assembleRtspUrl(values, e.target.value, setFieldValue)
                                }}
                            />
                        </div>

                        <FormTextField
                            required
                            label="RTSP URL"
                            name="rtspUrl"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            label="VPN RTSP URL"
                            name="vpnRtspUrl"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            label="Stream URL"
                            name="streamUrl"
                            handleChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn onClick={onClose} title="Cancel" type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
