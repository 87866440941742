import React, { useState, useEffect } from 'react'
import { Typography, Paper } from '@mui/material'
import AnalyticsTableItem from './TableItem'
import { WORKSPACE_ANALYTICS_LIST, BUILDINGS_LIST, ROOMS_LIST } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import AnalyticsFilters from './AnalyticsFilter'
// import AnalyticsFilter from './AnalyticsFilter'
import Table from '../../../shared/ui/Table'

const cols = [
    { id: 1, title: '№' },
    { id: 2, title: 'Photo' },
    { id: 3, title: 'Full name' },
    { id: 4, title: 'At workplace' },
    { id: 5, title: 'At workplace min' },
    { id: 6, title: '' },
]

export default function AnalyticsTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [transformedData, setTransformedData] = useState([])
    const { workspaceFilter } = useFilter()
    const { jetsonDeviceId, startPeriod, endPeriod } = workspaceFilter
    const getAnalytics = useGetRequest({ url: WORKSPACE_ANALYTICS_LIST,
        params: { jetson_device_id: jetsonDeviceId,
            start_period: startPeriod,
            end_period: endPeriod,
            limit: 10 } })
    const data = getAnalytics.response ? getAnalytics.response : []

    // we have array of analyticsReport { identity, analytics } and we need to dublicate each item
    // according to the analytics inside it and finally we will have array of items with identity and analytics
    useEffect(() => {
        const copyData = data.map((item) => ({ ...item }))
        const dublicatedData = copyData.reduce((acc, curr) => {
            const { identity, analyticsReport } = curr
            const dublicated = analyticsReport.map((item) => ({ ...item, identity }))
            return [...acc, ...dublicated]
        }, [])
        setTransformedData(dublicatedData)
    }, [data])

    useEffect(() => {
        getAnalytics.request()
    }, [workspaceFilter])

    return (
        <div className="w-full flex flex-col gap-6">
            {/* <AnalyticsFilter jetsonUrl={JETSON_DEVICES} /> */}
            <AnalyticsFilters buildingUrl={BUILDINGS_LIST} roomUrl={ROOMS_LIST} />

            <Paper square className="flex flex-col gap-3">
                <Typography className="text-m font-600 px-4 pt-3">
                    Queue analytics
                </Typography>

                <Table
                    page={page - 1}
                    loading={getAnalytics.loading}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={transformedData}
                    renderItem={(item, i) => (
                        <AnalyticsTableItem item={item} index={i} key={item.id} />
                    )}
                />
            </Paper>
        </div>
    )
}
