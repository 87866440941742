import React from 'react'
import LogList from './components/LogList'

export default function Logs() {
    return (
        <div>
            <LogList />
        </div>
    )
}
