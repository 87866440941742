/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Telegram } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { FaceBook, Instagram, LinkedIn, YouTube } from '../../../../components/svgs/Svgs'

// eslint-disable-next-line import/prefer-default-export
export function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div className="w-[100%] bg-[#E7E7E7] dark:bg-headerBlack">
            <div className="w-full flex flex-col items-start
            gap-[29px] lg:max-w-[1440px] lg:m-auto sm:px-[15px]
            lg:px-[45px] sm:py-[28px] md:py-[50px] "
            >
                <div className="flex sm:flex-col md:flex-row  items-start gap-[25px]">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <span
                        onClick={() => navigate('/about')}
                        className="text-[16px] cursor-pointer font-normal text-black dark:text-white"
                    >
                        {t('landing.navbar.about')}
                    </span>
                    <span
                        onClick={() => navigate('/product')}
                        className="text-[16px] cursor-pointer font-normal text-black dark:text-white"
                    >
                        {t('landing.navbar.product')}
                    </span>
                    <span
                        onClick={() => navigate('/contact')}
                        className="text-[16px] cursor-pointer font-normal text-black dark:text-white"
                    >
                        {t('landing.navbar.contact')}
                    </span>
                </div>
                <div className="flex items-center gap-[16px]">
                    <div className="py-[9px] text-black dark:text-white">
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                            <FaceBook />
                        </a>
                    </div>
                    <div className="py-[9px] text-black dark:text-white">
                        <a href="https://www.linkedin.com/company/realsoftai/" target="_blank" rel="noopener noreferrer">
                            <LinkedIn />
                        </a>
                    </div>
                    <div className="py-[9px] text-black dark:text-white">
                        <a href="https://t.me/realsoftai" target="_blank" rel="noopener noreferrer">
                            <Telegram />
                        </a>
                    </div>
                    <div className="py-[9px] text-black dark:text-white">
                        <YouTube />
                    </div>
                    <div className="py-[9px] text-black dark:text-white">
                        <a href="https://www.instagram.com/realsoft.ai/" target="_blank" rel="noopener noreferrer">
                            <Instagram />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
