import { AmericaFlag, RussianFlag, UzbFlag } from '../../components/svgs/Svgs'

// service data

export const contentData = [
    {
        title: 'Unlock the Potential',
        text: 'Artificial Intelligence as the Key to New Horizons of Possibilities.',
        id: '1',
    },
    {
        title: 'Artificial Intelligence',
        text: 'New Horizons, New Benefits for Your Business',
        id: '2',
    },
    {
        title: 'Guide to the World of Possibilities',
        text: 'Artificial Intelligence as your competitive advantage.',
        id: '3',
    },
]

/// NavLink data

export const navLink = [
    {
        name: 'Products',
        id: 1,
        click: 'section1',
    },
    {
        name: 'Price',
        id: 2,

    },
    {
        name: 'About us',
        id: 3,
        click: 'section2',
    },
]

/// UserCards data

export const cardData = [
    {
        id: 1,
        personName: 'Kamoliddin Soliev',
        personPosition: 'CEO and Co-founder',
        personImg: 'https://s3.realsoft.ai/frontassets/kamoliddin.png',
    },
    {
        id: 2,
        personName: 'Muxitdinov Amirshox',
        personPosition: 'Backend developer',
        personImg: 'https://s3.realsoft.ai/frontassets/amirshoh.png',
    },
    {
        id: 3,
        personName: 'Jamshid Abdullajonov',
        personPosition: 'Backend developer',
        personImg: 'https://s3.realsoft.ai/frontassets/jamshid.png',
    },
    {
        id: 4,
        personName: 'Halilulloh Ahmadjonov',
        personPosition: 'Frontend developer',
        personImg: 'https://s3.realsoft.ai/frontassets/khaliulloh.png',
    },
    {
        id: 5,
        personName: 'Salahiddin Ruziyev',
        personPosition: 'Software Engineer and C++ developer',
        personImg: 'https://s3.realsoft.ai/frontassets/salohiddin.png',
    },
    {
        id: 6,
        personName: 'Aftondil Idiyev',
        personPosition: 'Backend developer,  MLops',
        personImg: 'https://s3.realsoft.ai/frontassets/aftondil.png',
    },
    {
        id: 7,
        personName: 'Qayumov Salohiddin',
        personPosition: 'Data scientist',
        personImg: 'https://s3.realsoft.ai/frontassets/qayumovsalohiddin.png',
    },
    {
        id: 8,
        personName: 'O`ktamov Shohjahon',
        personPosition: 'Intern Frontend developer',
        personImg: 'https://s3.realsoft.ai/frontassets/shokh.jpg',
    },
    // {
    //     id: 9,
    //     personName: 'Eleanor Pena',
    //     personPosition: 'CEO and Founder',
    //     personImg: Person2,
    // },

]

// statistics data

export const StatisticData = [
    {
        id: 1,
        categoryNumb: '01',
        categoryTitle: 'Профессионалы с высшим образованием',
        categoryPercentage: '95%',
    },
    {
        id: 2,
        categoryNumb: '02',
        categoryTitle: 'Профессионалы с высшим образованием',
        categoryPercentage: '10yr',
    },
    {
        id: 3,
        categoryNumb: '03',
        categoryTitle: 'Профессионалы с высшим образованием',
        categoryPercentage: '95%',
    },
]

// language data

export const langData = [
    { id: 1, lang: 'Uzb', code: 'uz', flag: UzbFlag },
    { id: 2, lang: 'Rus', code: 'ru', flag: RussianFlag },
    { id: 3, lang: 'Eng', code: 'en', flag: AmericaFlag },
]

// gifCard Data

export const gifData = [
    {
        id: 1,
        title: 'Face Recognition General Module',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-workspace.gif',
        topic: [
            {
                id: 1.2,
                text: '- Visitor Analytics: Track and analyze the volume and behavior of visitors '
                    + 'in commercial spaces like '
                    + 'shopping malls or public events to optimize layout and marketing strategies.',
            },
            {
                id: 1.3,
                text: '- Staff Attendance Management: Automate the process of staff '
                    + 'check-ins and check-outs, reducing the need for manual timekeeping and increasing accuracy.',
            },
            {
                id: 1.4,
                text: '- Blacklist Monitoring: Identify unwanted visitors or banned individuals instantly to '
                    + 'enhance security in sensitive areas such as schools, '
                    + 'government buildings, or private properties.',
            },
            {
                id: 1.5,
                text: '- Real-Time Predictive Analytics: '
                    + 'Utilize data gathered to predict high-traffic times, staff requirements, '
                    + 'and visitor preferences to optimize operations.',
            },
        ],
        benefitTopic: [
            {
                textB: '- Enhances security by quickly identifying individuals.',
            },
            {
                textB: '- Reduces overhead by automating attendance and access controls.',
            },
            {
                textB: '- Provides valuable insights into visitor behaviors, '
                    + 'helping businesses tailor services and environments.',
            },
        ],
    },
    {
        id: 2,
        title: 'RSAI ModelHub',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-fedl.gif',
        topic: [
            {
                id: 2.1,
                text: '- RSAI ModelHub: serves as a comprehensive platform where '
                    + 'users from various industries can create, '
                    + 'manage, and deploy machine learning models. '
                    + 'It operates as a model zoo, allowing users to establish'
                    + ' ML pipelines tailored to their specific needs.',
            },
            {
                id: 2.2,
                text: '- Users: can define the accessibility of these models by setting them to public or private. '
                    + 'The platform supports various data types, including categorical, binary, numeric, time '
                    + 'series, and text, making it versatile for different analytical tasks.',
            },
            {
                id: 2.3,
                text: '- Furthermore: RSAI ModelHub  incorporates federated learning capabilities,'
                    + ' which is a significant advantage for sectors like banking and healthcare.',
            },
            {
                id: 2.4,
                text: '-  This feature allows: models to be collaboratively trained across different'
                    + ' organizational datasets without exchanging raw data, thereby preserving privacy'
                    + ' and data security.',
            },
        ],
    },
    {
        id: 3,
        title: 'Workspace Analytics',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-bank.gif',
        topic: [
            {
                id: 3.1,
                text: '- Employee Engagement and Productivity Tracking: Monitor '
                    + 'how long employees spend on tasks and at their desks, '
                    + 'providing data for productivity analysis and optimization.',
            },
            {
                id: 3.2,
                text: '- Service Quality Assessment: Evaluate how effectively '
                    + 'staff interact with customers in environments such as banks or retail spaces.',
            },
            {
                id: 3.4,
                text: '- Factory Line Monitoring: Ensure that employees '
                    + 'on production lines adhere to operational protocols and timelines.',
            },
        ],
        benefitTopic: [
            {
                textB: '- Enhances security by quickly identifying individuals.',
            },
            {
                textB: '- Reduces overhead by automating attendance and access controls.',
            },
            {
                textB: '- Provides valuable insights into visitor behaviors, '
                    + 'helping businesses tailor services and environments.',
            },
        ],
    },
    {
        id: 4,
        title: 'Factory Analytics',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-factory_analytics.gif',
        topic: [
            {
                id: 4.1,
                text: '- Perimeter Security: Detect unauthorized access '
                    + 'or violations of predefined paths within industrial areas.',
            },
            {
                id: 4.2,
                text: '- Safety Compliance Monitoring: Ensure that all '
                    + 'personnel wear the correct uniforms and follow safety protocols.',
            },
            {
                id: 4.3,
                text: '- Efficiency Analysis: Assess the efficiency of movement'
                    + ' patterns within facilities to optimize the flow of personnel and materials.',
            },
        ],
        benefitTopic: [
            {
                textB: '- Enhances security by quickly identifying individuals.',
            },
            {
                textB: '- Reduces overhead by automating attendance and access controls.',
            },
            {
                textB: '- Provides valuable insights into visitor behaviors, '
                    + 'helping businesses tailor services and environments.',
            },
        ],
    },
    {
        id: 5,
        title: 'Face Recognition Module for Schools',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-fr-school.gif',
        topic: [
            {
                id: 5.1,
                text: '- Student Attendance Management: Automate the '
                    + 'recording of student attendance, saving time and reducing errors.',
            },
            {
                id: 5.2,
                text: '- Stranger Detection: Instantly identify and track the'
                    + ' movement of unrecognized individuals on school premises to ensure student safety.',
            },
            {
                id: 5.3,
                text: '- Blacklist Monitoring: Identify unwanted visitors or banned individuals instantly to '
                    + 'enhance security in sensitive areas such as schools, '
                    + 'government buildings, or private properties.',
            },
            {
                id: 5.4,
                text: '- Cross-Camera Tracking: Monitor and track individuals across multiple '
                    + 'cameras within the school, providing comprehensive coverage and security.',
            },
        ],
        benefitTopic: [
            {
                textB: '- Enhances security by quickly identifying individuals.',
            },
            {
                textB: '- Reduces overhead by automating attendance and access controls.',
            },
            {
                textB: '- Provides valuable insights into visitor behaviors, '
                    + 'helping businesses tailor services and environments.',
            },
        ],
    },
    {
        id: 6,
        title: 'Factory Guard',
        gifImg: 'https://s3.realsoft.ai/frontassets/animation-lpnr.gif',
        topic: [
            {
                id: 6.1,
                text: '- Vehicle Access Control: Monitor and control vehicle access at checkpoints,'
                    + ' ensuring only authorized vehicles can enter sensitive areas.',
            },
            {
                id: 6.2,
                text: '- License Plate Recognition: Automatically capture '
                    + 'and record vehicle license plates for security logging and access control.',
            },
            {
                id: 6.3,
                text: '- Security Checks: Facilitate thorough security checks '
                    + 'by identifying vehicles and associated individuals at entry points.',
            },
        ],
        benefitTopic: [
            {
                textB: '- Enhances security by quickly identifying individuals.',
            },
            {
                textB: '- Reduces overhead by automating attendance and access controls.',
            },
            {
                textB: '- Provides valuable insights into visitor behaviors, '
                    + 'helping businesses tailor services and environments.',
            },
        ],
    },

]

// videoData

export const videoData = [
    {
        id: 1,
        name: 'video1',
        src: 'https://s3.realsoft.ai/frontassets/video1.mp4',
    },
    {
        id: 2,
        name: 'video2',
        src: 'https://s3.realsoft.ai/frontassets/video2.mp4',
    },
    {
        id: 3,
        name: 'video3',
        src: 'https://s3.realsoft.ai/frontassets/video3.mp4',
    },
]

// audio Data

export const audioData = [
    {
        id: 1,
        name: 'audio1',
        src: 'https://s3.realsoft.ai/frontassets/sound1.mp3',
    },
    {
        id: 2,
        name: 'audio2',
        src: 'https://s3.realsoft.ai/frontassets/sound2.mp3',
    },
    {
        id: 3,
        name: 'audio3',
        src: 'https://s3.realsoft.ai/frontassets/sound3.mp3',
    },

]
