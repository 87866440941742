import React from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import { SMARTCAMERAS_TENANT } from '../../../shared/utils/urls'
import SmartCameraDetails from '../components/SmartCameraDetails'

export default function Update({ setData, item, isOpen, open, close }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateSmartCamera = usePutRequest()

    const update = async (values) => {
        const { success, response, error } = await updateSmartCamera.request({
            url: `${SMARTCAMERAS_TENANT}/${item.id}`,
            data: values,
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (val.id === item.id ? { ...val, ...response } : val)),
            }))
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={open}>
                <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '58rem' } }}
                anchor="right"
                open={isOpen}
                onClose={close}>
                <SmartCameraDetails
                    loading={updateSmartCamera.loading}
                    onEdit={update}
                    setData={setData}
                    onClose={close}
                    item={item} />
            </Drawer>
        </>
    )
}
