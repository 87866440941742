import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, css } from 'aphrodite'
import Sidebar from './components/Sidebar'
import FullScreenMap from './components/FullScreenMap'
import Filters from './components/Filters'
import Statistics from './components/Statistics'

function RealTimeFaceRecognition() {
    const [fullScreen, setFullScreen] = useState(false)
    const pageRef = useRef(null)

    useEffect(() => {
        const element = pageRef.current

        if (fullScreen) {
            // Request fullscreen
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.webkitRequestFullscreen) { // Safari
                element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) { // IE11
                element.msRequestFullscreen()
            }
        } else {
            // Exit fullscreen if the document is in fullscreen mode
            // eslint-disable-next-line no-lonely-if
            if (
                document.fullscreenElement
                || document.webkitFullscreenElement
                || document.msFullscreenElement
            ) {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.webkitExitFullscreen) { // Safari
                    document.webkitExitFullscreen()
                } else if (document.msExitFullscreen) { // IE11
                    document.msExitFullscreen()
                }
            }
        }
    }, [fullScreen])

    return (
        <div ref={pageRef} className={css(styles.page, fullScreen && styles.pageFull)}>
            <Filters pageRef={pageRef} />
            <Statistics />
            <Sidebar />
            <FullScreenMap />
            <button
                className={css(styles.fullScreenButton)}
                onClick={() => setFullScreen(!fullScreen)}
            >
                {fullScreen ? 'Exit Full Screen' : 'Full Screen'}
            </button>
        </div>
    )
}

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    pageFull: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
    },
    fullScreenButton: {
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '10px',
        backgroundColor: 'teal',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        opacity: 0,
        // add hover effect
        ':hover': {
            opacity: 1,
        },
    },
})

export default RealTimeFaceRecognition
