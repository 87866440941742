import React, { useState, useEffect, useCallback } from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material'
import { debounce } from 'lodash'
import { COLORS } from '../utils/colors'

export default function SearchInput({ onSearch, placeholder = 'Search...', initialValue = '', ...props }) {
    const [showInput, setShowInput] = useState(false)
    const [searchValue, setSearchValue] = useState(initialValue)

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
        debouncedSearch(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    // Debounce the onSearch callback
    const debouncedSearch = useCallback(
        debounce((value) => {
            if (onSearch) {
                onSearch(value)
            }
        }, 500),
        [onSearch],
    )

    // Clean up debounce on unmount
    useEffect(() => () => {
        debouncedSearch.cancel()
    }, [debouncedSearch])

    const handleSearchIconClick = () => {
        setShowInput((prev) => !prev)
        if (showInput) {
            // If closing the input, reset the search value
            setSearchValue('')
            if (onSearch) {
                onSearch('')
            }
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {showInput ? (
                <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', borderRadius: '20px', padding: '0 8px' }}
                    {...props}
                >
                    <InputBase
                        value={searchValue}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown} // Add keydown handler
                        placeholder={placeholder}
                        inputProps={{ 'aria-label': placeholder }}
                        sx={{ ml: 1, flex: 1 }}
                    />
                    <IconButton type="button" onClick={handleSearchIconClick}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Paper>
            ) : (
                <IconButton onClick={handleSearchIconClick}>
                    <SearchIcon fontSize="small" htmlColor={COLORS.gray} />
                </IconButton>
            )}
        </div>
    )
}
