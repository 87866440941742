import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useGetRequest } from '../../../../shared/hooks/requests'
import { BUILDINGS_TENANT_PAGINATED } from '../../../../shared/utils/urls'
import BuildingsTableItem from './BuildingsTableItem'
import Table from '../../../../shared/ui/Table'
import Create from '../features/Create'
import SearchInput from '../../../../shared/ui/SearchInput'

const cols = [
    { id: 1, title: 'Building Title' },
    { id: 2, title: 'Cameras' },
    { id: 3, title: 'Latitude', count: 0 },
    { id: 4, title: 'Longitude' },
    { id: 5, title: 'Created time' },
    { id: 6, title: 'Action', width: '10%', align: 'right' },
]

export default function BuildingsTable() {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadBuildings = useGetRequest({ url: BUILDINGS_TENANT_PAGINATED,
        params: {
            size: pageSize,
            page,
            search,
        } })
    const data = loadBuildings.response ? loadBuildings.response.items : []
    const meta = loadBuildings.response ? loadBuildings.response : {}
    useEffect(() => {
        if (!loadBuildings.loading) {
            loadBuildings.request({
                params: { size: pageSize, page, search },
            })
        }
    }, [page, pageSize, search])

    const handleSearch = (value) => {
        setSearch(value)
    }

    return (
        <Paper square>
            <div className="flex items-center justify-between h-[60px] px-4">
                <Typography className="flex-cent gap-1_5 text-m font-600">
                    Buildings
                </Typography>

                <div className="flex-cent gap-2">
                    <Create setData={loadBuildings.setResponse} />

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
            </div>

            <Table
                page={page - 1}
                loading={loadBuildings.loading}
                totalCount={meta.total}
                rowsPerPage={pageSize}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10))
                    setPage(1)
                }}
                cols={cols}
                rows={data}
                renderItem={(item) => (
                    <BuildingsTableItem
                        item={item}
                        key={item.id}
                        setData={loadBuildings.setResponse}
                    />
                )}
            />
        </Paper>
    )
}
