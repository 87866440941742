import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { COLORS } from '../../../shared/utils/colors'

export default function LogItem({ item }) {
    const { id, log, date } = item

    return (
        <div className={css(s.item)}>
            <h3>{id}.</h3>
            <div>
                <p>{log}</p>
                <p>{date}</p>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    item: {
        padding: '14px 14px 14px 30px',
        backgroundColor: COLORS.lightGrayBg,
        color: COLORS.darkGrayText,
        fontSize: '13px',
        lineHeight: '22px',
        display: 'flex',
        gap: '10px',
        borderLeft: `3px solid ${COLORS.btnBlue}`,
        ':nth-child(even)': {
            borderColor: '#305680',
        },
    },
    id: {

    },
})
