import React, { useEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import Table from '../../../shared/ui/Table'
import AuthLogTableItems from './AuthLogTableItems'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import { AUTHLOGPAGINATION } from '../../../shared/utils/urls'
import { AuthLogFilter } from './AuthLogFilter'
import SearchInput from '../../../shared/ui/SearchInput'

const cols = [
    { id: 1, title: 'User id', align: 'left' },
    { id: 2, title: 'Client Name', align: 'left' },
    { id: 3, title: 'Pinfl', align: 'center' },
    { id: 4, title: 'CreatedAt', align: 'center' },
    { id: 5, title: 'Mani photo', align: 'left', width: '10%' },
    { id: 6, title: 'Snapshot', align: 'left', width: '10%' },
    { id: 7, title: 'API Type', align: 'left' },
    { id: 8, title: 'Authenticated', align: 'left' },
    { id: 9, title: 'Distance', align: 'center' },
    { id: 10, title: 'FaceMatched', align: 'left' },
    { id: 11, title: 'Spoofed', align: 'left' },
    { id: 12, title: 'Score', align: 'center' },
    { id: 13, title: 'Action', align: 'right' },
]

// eslint-disable-next-line import/prefer-default-export
export function AuthLogTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)
    const { filterState, setAuthFilters } = useFilter()

    const params = useMemo(() => ({
        page,
        size: pageSize,
        ...(filterState.search !== '' ? { search: filterState.search } : {}),
        ...(filterState.authClient && filterState.authClient !== '' && { client_id: filterState.authClient }),
        ...(filterState.api && filterState.api !== '' && { api_type: filterState.api }),
        ...(filterState.authenticated && filterState.authenticated !== ''
            && { is_authenticated: filterState.authenticated }),
        ...(filterState.face_matched && filterState.face_matched !== ''
            && { is_face_matched: filterState.face_matched }),
        ...(filterState.is_spoofed && filterState.is_spoofed !== ''
            && { is_spoofed: filterState.is_spoofed }),
    }), [page, pageSize, filterState])

    const loadAuthLog = useFaceLoad({
        url: AUTHLOGPAGINATION,
        params,
    }, [params])

    const data = loadAuthLog.response ? loadAuthLog.response.items : []
    const meta = loadAuthLog.response ? loadAuthLog.response : {}

    const handleSearch = (value) => {
        setAuthFilters({ search: value })
    }

    useEffect(() => {
        setPage(1)
    }, [filterState])

    return (
        <div className="flex flex-col items-center gap-[21px] w-[100%]">
            <div className="w-[100%] bg-white flex flex-col items-start
            gap-[10px] h-auto pt-[14px] pb-[15px] px-[14px] shadow-sm"
            >
                <AuthLogFilter />
            </div>
            <div className="bg-white w-[100%]">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="text-[20px] font-normal text-black">
                        Latest API Calls (
                        {meta.total || 0}
                        )
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadAuthLog.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <AuthLogTableItems
                            key={item.id}
                            item={item}
                            setData={loadAuthLog.setResponse}
                        />
                    )}
                />
            </div>
        </div>
    )
}
