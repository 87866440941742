/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'

export function AttendanceAnalyticsTableItem({ item }) {
    return (
        <>
            {/* eslint-disable-next-line react/prop-types */}
            <TableCell>{item.tenantEntityId}</TableCell>
            <TableCell>{item.tenantEntityName}</TableCell>
            <TableCell>{item.kidsAttendanceCount}</TableCell>
            <TableCell>{item.employeesAttendanceCount}</TableCell>
            <TableCell>{item.kidsTotalCount}</TableCell>
            <TableCell>{item.employeesTotalCount}</TableCell>
            <TableCell>{item.attendanceRatio}</TableCell>
            <TableCell>
                <button className="py-[10px] px-[15px] text-white font-normal bg-main-300 rounded-[15px]">Batafsil</button>
            </TableCell>
        </>
    )
}
