/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import App from './app/App'
import './app/styles/styles.css'
import './app/styles/utility.css'
import './app/styles/calendar.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import store from './shared/store/store'
import i18n from './app/i18n'

Sentry.init({
    dsn: 'https://a83ae35d6fa91ecb58d3a6a8cf1743cd@sentry.platon.uz/4',
    integrations: [
        Sentry.browserTracingIntegration({
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0, // Capture 100% of transactions
    replaysSessionSampleRate: 0.1, // 10% session replay sampling
    replaysOnErrorSampleRate: 1.0, // 100% replay sampling on errors
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>,
)
