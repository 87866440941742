import React, { useState, useRef, useCallback } from 'react'
import { Paper, Grid } from '@mui/material'
import DeviceItem from './DeviceItem'
import DeviceModal from './DeviceModal'
import Modal from '../../../components/common/Modal'

const data = [
    {
        id: 1,
        name: 'Device 1',
        online: 4,
        offline: 0,
    },
    {
        id: 2,
        name: 'Device 2',
        online: 3,
        offline: 1,
    },
    {
        id: 3,
        name: 'Device 3',
        online: 1,
        offline: 3,
    },
    {
        id: 4,
        name: 'Device 4',
        online: 0,
        offline: 4,
    },
]

export default function DeviceList() {
    const modalRef = useRef()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState({})
    // eslint-disable-next-line no-shadow
    const handleOpen = useCallback((selected) => {
        setOpen(true)
        setSelected(selected)
    }, [])

    return (
        <Paper square className="flex flex-col gap-2.5 h-full p-[20px]">
            <Modal
                modalRef={modalRef}
                open={open}
                onClose={() => setOpen(false)}
                // trigger={<button>Details</button>}
            >
                <DeviceModal item={selected} modalRef={modalRef} />
            </Modal>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <Grid item xs={4}>
                        <DeviceItem key={item.id} item={item} handleOpen={handleOpen} />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    )
}
