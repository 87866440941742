/* eslint-disable */
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import {ProductCard} from './components/ProductCard'
import OneSystemImage from '../../../static/images/onesystemImage.jpg'
import AIAdvokat from '../../../static/images/AiAdvokat.webp'
import BgGradient from '../../../static/images/bggradient.png'
import FeedbackSection from '../components/feedback'
import ProductVideo from '../../../static/productVideo.webm'
import UniPass from '../../../static/images/unipass.webp'

const usData = [
    {
        index: '1',
        image: OneSystemImage,
        title: 'Vision Analytics',
        text: 'VisitonAnalytics is an innovative solution comprising multiple interconnected subprojects, all powered by cutting-edge computer vision technology.' +
            ' Designed to enhance productivity and safety, it offers tailored analytics and monitoring capabilities for large organizations, such as offices, banks, and government institutions.'
    },
    {
        index: '2',
        image: UniPass,
        title: 'UniPass',
        text: 'Uni-pass is a cutting-edge biometric authentication platform that combines advanced face recognition ' +
            'technology with secure electronic document signing capabilities. Designed for modern businesses and individuals, Uni-pass provides a streamlined and highly' +
            ' secure way to access third-party applications and handle digital agreements.'
    },
    {
        index: '3',
        image: AIAdvokat,
        title: 'ML hub',
        text: 'ML Hub is a centralized collection of powerful machine learning models, seamlessly integrated into your services and ' +
            'accessible from anywhere—whether through a mobile app or a web application. Designed for scalability and convenience, ML Hub enables developers' +
            ' and organizations to leverage cutting-edge AI capabilities with ease.'
    }
]

// eslint-disable-next-line import/prefer-default-export
export const Product = React.memo(() => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        // Apply smooth scroll behavior to the document
        document.documentElement.style.scrollBehavior = 'smooth'

        // Smooth scroll to the top when the route changes
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [location]) // Triggered when location (route) changes

    return (
        <>
            <div className="w-full flex  flex-col px-[24px] lg:max-w-[1440px] lg:m-auto lg:px-[20px] gap-[100px]">
                <section
                    style={{
                        backgroundImage: `url(${BgGradient})`,
                    }}
                >
                    <div className="flex flex-col pt-[38px] items-center gap-[15px]">
                        <span className="text-[28px] text-center font-[300]
                        text-[#262626] dark:text-white"
                        >
                            Customized Solutions for Your Business
                        </span>
                        <div className="flex items-center">
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                            jsx-a11y/click-events-have-key-events */}
                            <span
                                onClick={() => navigate('/')}
                                className="text-[14px] cursor-pointer font-[300] dark:text-white text-[#262626]"
                            >
                                Home
                            </span>
                            <span className="text-[14px] cursor-pointer
                            font-normal dark:text-white text-[#262626]"
                            >
                                {location.pathname}
                            </span>
                        </div>
                        <div className="w-[100%] relative overflow-hidden
                        sm:h-[179px] md:h-[250px] lg:h-[570px] lg:px-[40px]"
                        >
                            <video
                                src={ProductVideo}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                                playsInline
                            />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="flex w-[100%] lg:mb-[110px] md:mb-[57px]
                    lg:px-[24px] flex-col items-center sm:gap-[22px] md:gap-[100px] lg:gap-[120px]"
                    >
                        {usData.map((item) => (
                            <ProductCard
                             index={item.index}
                             title={item.title}
                             text={item.text}
                             image={item.image}
                            />
                        ))}
                    </div>
                </section>
            </div>
            <div className="w-[100%] mt-[20px]">
                <FeedbackSection/>
            </div>
        </>
    )
})
