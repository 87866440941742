/* eslint-disable */
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup'
import HeroImage from '../../../static/images/discoverImg.jpg'
import { PersonCard } from './components/PersonCard'
import BgGradient from '../../../static/images/bggradient.png'
import FeedbackSection from '../components/feedback'
import AboutVideo from '../../../static/aboutVideo.webm'

const stats = [
    { value: 100, suffix: '+', key: 'clients' },
    { value: 25, suffix: '+', key: 'projects' },
    { value: 2, suffix: ' YRS', key: 'experience' },
    { value: 24, suffix: '/7', key: 'support' },
    { value: 300, suffix: '%', key: 'growth' },
    { value: 10, suffix: '', key: 'partners' },
]

export const About = React.memo(() => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])

    return (
        <>
            <div className="w-full flex flex-col lg:max-w-[1440px] lg:m-auto px-[24px] lg:px-[20px] gap-[100px]">
                <section style={{ backgroundImage: `url(${BgGradient})` }}>
                    <div className="flex flex-col pt-[38px] items-center gap-[15px]">
                        <span className="text-[28px] font-[300] text-[#262626] dark:text-white">
                            {t('landing.about.title')}
                        </span>
                        <div className="flex items-center">
                            <span
                                onClick={() => navigate('/')}
                                className="text-[14px] cursor-pointer font-[300] dark:text-white text-[#262626]"
                            >
                                {t('landing.about.breadcrumb.home')}/
                            </span>
                            <span className="text-[14px] cursor-pointer font-normal dark:text-white text-[#262626]">
                                {t('landing.about.breadcrumb.current')}
                            </span>
                        </div>
                        <div className="w-[100%] relative overflow-hidden sm:h-[179px] md:h-[250px] lg:h-[570px] lg:px-[40px]">
                            <video
                                src={AboutVideo}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                                playsInline
                            />
                        </div>
                    </div>
                </section>
                <section className="w-full flex sm:flex-col lg:flex-row lg:items-center gap-[32px]">
                    <div className="sm:w-full relative overflow-hidden lg:w-[50%]">
                        <img
                            src={HeroImage}
                            alt={t('landing.about.discover.title')}
                            loading="lazy"
                            className="w-full h-full transition-transform duration-700 ease-in-out transform hover:scale-110 object-cover"
                        />
                    </div>
                    <div className="sm:w-full flex flex-col lg:w-[50%] items-start gap-[33px]">
                        <div className="flex flex-col items-start gap-[15px]">
                            <div className="flex flex-col items-start gap-[6px]">
                                <span className="text-[12px] font-normal text-[#0068B5] dark:text-white">
                                    {t('landing.about.discover.title')}
                                </span>
                                <span className="text-[31px] font-[300] text-[#262626] dark:text-white w-[80%]">
                                    {t('landing.about.discover.subtitle')}
                                </span>
                            </div>
                            <span className="text-[14px] font-normal text-[#262626] dark:text-white lg:w-[90%]">
                                {t('landing.about.discover.description')}
                            </span>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="flex flex-col lg:px-[24px] items-start gap-[10px]">
                        <span className="text-[12px] lg:text-[16px] font-normal text-[#0068B5] dark:text-white">
                            {t('landing.about.stats.title')}
                        </span>
                        <span className="text-[31px] font-[300] text-[#262626] dark:text-white">
                            {t('landing.about.stats.subtitle')}
                        </span>
                    </div>
                    <div className="mx-auto px-4 py-16">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
                            {stats.map((stat, index) => (
                                <div key={index} className="text-center relative">
                                    <div className="text-[2.5rem] font-bold text-[#0068B5] dark:text-white mb-2">
                                        <CountUp
                                            end={stat.value}
                                            duration={10}
                                            suffix={stat.suffix}
                                            start={0}
                                        />
                                    </div>
                                    <div className="text-gray-900 dark:text-white pb-[43px] text-lg">
                                        {t(`landing.about.stats.list.${stat.key}`)}
                                    </div>
                                    {index < 5 && (
                                        <div className="absolute bottom-0 left-0 right-0 h-px bg-[#ECECEC] dark:bg-[#383838] md:hidden" />
                                    )}
                                    {index % 3 !== 2 && (
                                        <div className="absolute top-0 bottom-0 right-0 w-px bg-[#ECECEC] dark:bg-[#383838] hidden md:block" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section>
                    <div className="w-full flex flex-col mb-[70px] gap-[50px]">
                        <div className="flex flex-col lg:px-[24px] items-start gap-[10px]">
                            <span className="text-[12px] font-normal text-[#0068B5] dark:text-white">
                                {t('landing.about.team.title')}
                            </span>
                            <span className="text-[31px] font-[300] text-[#262626] dark:text-white">
                                {t('landing.about.team.subtitle')}
                            </span>
                        </div>
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[100%] lg:px-[24px] lg:grid-cols-3 xl:grid-cols-4 sm:gap-y-[30px] lg:gap-x-[30px] lg:gap-y-[50px]">
                            {Object.entries(t('landing.about.team.members', { returnObjects: true })).map(
                                ([key, member]) => (
                                    <PersonCard
                                        key={key}
                                        img={`https://s3.realsoft.ai/frontassets/${member?.image}`}
                                        personName={member.name}
                                        bio={member.role}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </section>
            </div>
            <FeedbackSection />
        </>
    )
})
