import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'
import { CAMERAS_TENANT } from '../../../shared/utils/urls'
import CameraDetails from '../components/CameraDetails'

export default function Update({ setData, item }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateCamera = usePutRequest()

    const close = () => setOpen(false)

    const update = async (values) => {
        if (updateCamera.loading || !values) return

        const { success, response, error } = await updateCamera.request({
            url: `${CAMERAS_TENANT}/${item.id}`,
            data: { ...values,
                jetsonDeviceId: values.jetsonDeviceIdNum },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (val.id === item.id ? { ...val, ...response } : val)),
            }))
            showMessage('Successfully updated', 'success-msg')
            close()
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton className="p-0" onClick={() => setOpen(true)}>
                <Edit htmlColor={COLORS.white} className="cursor-pointer" fontSize="medium" />
            </IconButton>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '45rem' } }}
                anchor="right"
                open={open}
                onClose={close}>
                <CameraDetails
                    loading={updateCamera.loading}
                    onEdit={update}
                    onClose={close}
                    item={item} />
            </Drawer>
        </>
    )
}
