/* eslint-disable */
import { useLocation, useNavigate } from 'react-router-dom'
import { usePostRequest } from '../../../shared/hooks/requests'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Card } from './components/card'
import { CallCenter, Comment, Email, FormEmail, map, Name, PhoneNumber } from '../../../components/svgs/Svgs'
import { Input } from './components/input'
import BgGradient from '../../../static/images/bggradient.png'
import { useMessage } from '../../../shared/hooks/message'
import { SEND_MESSAGE } from '../../../shared/utils/urls'

export const Contact = React.memo(() => {
    const { t } = useTranslation()
    const showMessage = useMessage()
    const postMessage = usePostRequest({ url: SEND_MESSAGE })
    const { register, handleSubmit, reset } = useForm()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])

    const submit = async (data) => {
        const { success } = await postMessage.request({
            data: {
                name: data.name,
                phone: data.phone,
                email: data.email,
                description: data.message,
            },
        })
        if (success) {
            reset()
            showMessage(t('landing.contact.message.success'), 'success-msg')
        }
    }

    return (
        <div className="w-full flex flex-col px-[24px] lg:max-w-[1440px] lg:m-auto lg:px-[20px] gap-[38px] lg:gap-[100px]">
            <section style={{ backgroundImage: `url(${BgGradient})` }}>
                <div className="flex flex-col pt-[38px] items-center gap-[15px]">
                    <span className="text-[28px] text-center font-[300] text-[#262626] dark:text-white">
                        {t('landing.contact.title')}
                    </span>
                    <div className="flex items-center">
                        <span
                            onClick={() => navigate('/')}
                            className="text-[14px] cursor-pointer font-[300] dark:text-white text-[#262626]"
                        >
                            {t('landing.contact.breadcrumb.home')}/
                        </span>
                        <span className="text-[14px] cursor-pointer font-normal dark:text-white text-[#262626]">
                            {t('landing.contact.breadcrumb.current')}
                        </span>
                    </div>
                    <div className="w-[100%] sm:h-[179px] md:h-[250px] lg:h-[570px]">
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe
                            style={{ border: 0 }}
                            src={t('landing.contact.mapUrl')}
                            width="100%"
                            height="100%"
                            allowFullScreen
                            loading="lazy"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="grid grid-cols-1 lg:grid-cols-3 w-[100%] md:w-[70%] lg:w-[100%] m-auto gap-[24px] lg:gap-[70px]">
                    <Card
                        link={t('landing.contact.cards.address.link')}
                        icon={map}
                        title={t('landing.contact.cards.address.title')}
                        bio={t('landing.contact.cards.address.description')}
                    />
                    <Card
                        link={t('landing.contact.cards.email.link')}
                        icon={Email}
                        title={t('landing.contact.cards.email.title')}
                        bio={t('landing.contact.cards.email.description')}
                    />
                    <Card
                        link={t('landing.contact.cards.phone.link')}
                        icon={CallCenter}
                        title={t('landing.contact.cards.phone.title')}
                        bio={t('landing.contact.cards.phone.description')}
                    />
                </div>
            </section>
            <section>
                <div className="w-[100%] mb-[70px] flex flex-col items-start gap-[18px]">
                    <span className="text-[34px] font-normal lg:font-[300] text-black dark:text-white">
                        {t('landing.contact.form.title')}
                    </span>
                    <form
                        onSubmit={handleSubmit(submit)}
                        className="w-[100%] flex flex-col items-center gap-[14px] lg:gap-[40px]"
                    >
                        <div className="flex sm:flex-col lg:flex-row lg:items-center lg:gap-[39px] gap-[14px] w-[100%]">
                            <Input
                                name="name"
                                register={register}
                                type="text"
                                placeholder={t('landing.contact.form.fields.name')}
                                icon={Name}
                            />
                            <Input
                                name="phone"
                                register={register}
                                type="phone"
                                placeholder={t('landing.contact.form.fields.phone')}
                                icon={PhoneNumber}
                            />
                            <Input
                                name="email"
                                register={register}
                                type="email"
                                placeholder={t('landing.contact.form.fields.email')}
                                icon={FormEmail}
                            />
                        </div>
                        <div className="relative w-[100%] border lg:h-[300px] border-[#C2C2C2] dark:border-[#656565] p-4 bg-[#F9F9F9] dark:bg-[#424242]">
                            <textarea
                                placeholder={t('landing.contact.form.fields.message')}
                                className="w-[94%] h-[100%] bg-transparent text-black dark:text-white outline-none pr-10"
                                {...register('message', { required: true })}
                            />
                            <button
                                type="button"
                                className="absolute w-[6%] flex items-center justify-end top-4 right-4 text-blue-500 hover:text-blue-700"
                            >
                                <Comment />
                            </button>
                        </div>
                        <div className="flex items-center justify-start w-[100%]">
                            <button
                                className="py-[10px] w-[100%] lg:w-[15%] bg-[#0068B5] dark:bg-white text-center text-white dark:text-black text-[14px] font-normal"
                            >
                                {t('landing.contact.form.submit')}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
})
