/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { ENTITY_MANAGMENT } from '../../../shared/utils/urls'
import TenantEntityFilter from './TenantEntityFilter'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import Table from '../../../shared/ui/Table'
import TenantEntityTableItem from './TenantEntityTableItem'
import SearchInput from '../../../shared/ui/SearchInput'

const cols = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Building' },
    { id: 3, title: 'Room' },
    { id: 4, title: 'SmartCamera' },
    { id: 5, title: 'AllowedRadius' },
    { id: 6, title: 'FaceAuthThreshold' },
    { id: 7, title: 'SpoofingThreshold' },
    { id: 8, title: 'Action', align: 'right' },
]

export default function TenantEntityTable() {
    const { managmentFilter, setManagmentFilters } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const loadEntityManagment = useGetRequest(
        { url: ENTITY_MANAGMENT,
            params:
            { page,
                size: pageSize,
                regionId: managmentFilter.region,
                districtId: managmentFilter.district,
                search: managmentFilter.search } },
        [page, pageSize, managmentFilter.region, managmentFilter.district, managmentFilter.search],
    )
    const data = loadEntityManagment.response ? loadEntityManagment.response.items : []
    const meta = loadEntityManagment.response ? loadEntityManagment.response.total : []

    console.log(data)

    const handleSearch = (value) => {
        setManagmentFilters({ search: value })
    }

    useEffect(() => {
        if (managmentFilter.district) {
            loadEntityManagment.request()
        }
    }, [managmentFilter.district, managmentFilter.search, page, pageSize])

    return (
        <div className="w-full flex flex-col gap-6">
            <TenantEntityFilter />

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Tenant Entity Managment
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={loadEntityManagment.loading}
                    totalCount={meta}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <TenantEntityTableItem
                            item={item}
                            key={item.id}
                            setData={[]}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
