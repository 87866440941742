// WebSocketService.js

class WebSocketService {
    // Static property to hold instances
    static instances = {}

    constructor(url, params = {}, maxReconnectAttempts = 999) {
        const urlWithParams = this.constructUrl(url, params)
        const instanceKey = urlWithParams

        // If an instance for this URL already exists, return it
        if (WebSocketService.instances[instanceKey]) {
            // eslint-disable-next-line no-constructor-return
            return WebSocketService.instances[instanceKey]
        }

        // Otherwise, create a new instance
        this.url = urlWithParams
        this.listeners = new Set()
        this.maxReconnectAttempts = maxReconnectAttempts
        this.reconnectAttempts = 0
        this.reconnectExponentialDelay = 1000
        this.manualClose = false

        this.initializeWebSocket()

        // Cache the instance
        WebSocketService.instances[instanceKey] = this
    }

    // eslint-disable-next-line class-methods-use-this
    constructUrl(url, params) {
        // Filter out undefined, null, or empty string parameters
        const filteredParams = Object.fromEntries(
            // eslint-disable-next-line no-unused-vars
            Object.entries(params).filter(([_, v]) => v != null && v !== ''),
        )

        const queryString = Object.keys(filteredParams)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
            .join('&')

        if (queryString) {
            const separator = url.includes('?') ? '&' : '?'
            return `${url}${separator}${queryString}`
        }
        return url
    }

    initializeWebSocket() {
        this.ws = new WebSocket(this.url)
        this.ws.onmessage = (message) => {
            const data = JSON.parse(message.data)
            this.listeners.forEach((listener) => listener(data))
        }

        this.ws.onclose = () => {
            if (!this.manualClose) {
                console.log('WebSocket closed unexpectedly. Attempting to reconnect...')
                this.attemptReconnect()
            } else {
                console.log('WebSocket closed manually.')
            }
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                this.reconnectAttempts++
                console.log(`Reconnect attempt #${this.reconnectAttempts}`)
                this.initializeWebSocket()
            }, this.reconnectExponentialDelay * this.reconnectAttempts)
        } else {
            console.error('Max WebSocket reconnection attempts reached.')
        }
    }

    addListener(listener) {
        this.listeners.add(listener)
    }

    removeListener(listener) {
        this.listeners.delete(listener)
        // If no listeners are left, close the connection
        if (this.listeners.size === 0) {
            this.close()
        }
    }

    close() {
        this.manualClose = true
        if (this.ws) {
            this.ws.close()
        }
        // Remove the instance from the cache
        delete WebSocketService.instances[this.url]
    }
}

export default WebSocketService
