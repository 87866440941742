/* eslint-disable */
import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import { TenantEntityManagementForm } from './TenantEntityManagmentForm'

const navItems = [
    { id: 'Edit', name: 'Edit' },
]

export default function TenantEntityManagmentDetails({ item, onClose, onEdit, loading }) {
    const [section, setSection] = useState('Edit')


    const handleSubmit = (values) => {
        onEdit(values)
    }

    return (
        <DrawerLayout
            topic="Tenant Entity Managment details"
            onClose={onClose}
            name={item.name}
            icons={[]}
        >
            <>
                <div className={css(s.detailNav)}>
                    <ul className="w-full flex items-center">
                        {navItems.map(((i) => (
                            <li
                                key={i.id}
                                onClick={() => setSection(i.id)}
                                className={css(s.navItem, section === i.id && s.active)}
                            >
                                {i.name}
                            </li>
                        )))}
                    </ul>
                </div>

                <div className="h-layout overflow-y-auto">
                    {section === 'Edit' ? (
                        <TenantEntityManagementForm
                            item={item}
                            isEdit
                            loading={loading}
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            initialValues={item}
                        />
                    ) : null}
                </div>
            </>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
        paddingBottom: 1,
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 14,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        transition: 'all 0.3s linear',
    },
    detailCont: {
        paddingTop: 9,
        height: 'calc(100% - 10rem)',
        overflowY: 'scroll',
    },
})
