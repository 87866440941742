import React from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function Card({ icon: IconComponent, title, bio, link }) {
    const { t } = useTranslation()
    return (
        <div className="bg-[#404040] flex flex-col border
        border-[#404040] dark:border-none items-start gap-[25px] py-[35px]
        px-[24px] transition-all duration-500 hover:bg-transparent group dark:hover:bg-[#404040]"
        >
            {IconComponent && (
                <div className="text-white group-hover:text-[#404040] dark:group-hover:text-white">
                    <IconComponent />
                </div>
            )}
            <div className="flex flex-col items-start gap-[10px]">
                <span className="text-[25px] font-normal text-white
                 group-hover:text-[#404040] dark:group-hover:text-white"
                >
                    {title}
                </span>
                <span className="text-[14px] font-normal text-white
                 group-hover:text-[#404040] dark:group-hover:text-white"
                >
                    {bio}
                </span>
            </div>
            <a href={link} target="_blank" rel="noopener noreferrer">
                <button
                    type="button"
                    className="px-[47px] py-[10px] border
                border-white text-white font-normal text-[14px]
                 group-hover:bg-[#404040] group-hover:text-white
                 group-hover:border-[#404040] dark:group-hover:bg-[#404040]
                 dark:group-hover:text-white dark:group-hover:border-white"
                >
                    {t('btn.open')}
                </button>
            </a>
        </div>
    )
}
