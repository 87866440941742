import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import ModalHeader from '../../../components/common/ModalHeader'
import devicePreview from '../../../static/images/device-preview.png'

export default function DeviceModal({ item, modalRef }) {
    const deviceName = item.name
    const modalTitle = `Device Preview > ${deviceName}`
    return (
        <div className={css(s.modalWrapper)}>
            <ModalHeader title={modalTitle} onClose={() => modalRef.current.close()} />
            <div className={css(s.modalBody)}>
                <img className={css(s.deviceImage)} src={devicePreview} alt="device" />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    modalWrapper: {
        width: '100vh',
        maxWidth: '1050px',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    modalBody: {
        padding: '0 30px 20px 30px',
    },
})
