/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Formik, Form } from 'formik'
import { Dialog, DialogContent, Grid, IconButton, MenuItem, Select, Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { XIconSizeS } from '../../../components/svgs/Svgs'
import { useLoad, usePostRequest } from '../../../shared/hooks/requests'
import { SMARTCAMERAS_TENANT, NO_REGISTERED_CAMERAS } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import SmartCameraForm from '../components/SmartCameraForm'

export default function Create({ setData, disabled }) {
    const [open, setOpen] = useState(false)
    const [openUnregistered, setOpenUnregistered] = useState(false)
    const [selectedUnregistered, setSelectedUnregistered] = useState(null)
    const [initialValues, setInitialValues] = useState({
        deviceName: '',
        deviceId: '',
        deviceMac: '',
        libPlatformVersion: '',
        softwareVersion: '',
        libAiVersion: '',
        deviceIp: '',
        deviceLat: '',
        deviceLong: '',
        streamUrl: '',
        rtspUrl: '',
    })

    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createSmartCamera = usePostRequest({ url: SMARTCAMERAS_TENANT })

    const loadUnregistered = useLoad({ url: NO_REGISTERED_CAMERAS }, [])
    const unregistered = loadUnregistered.response ? loadUnregistered.response : []
    const unregisteredOptions = unregistered.map((b) => ({ value: b.deviceId, label: b.deviceId || '-' }))

    const create = async (values) => {
        if (createSmartCamera.loading) return

        const { response, success, error } = await createSmartCamera.request({ data: values })

        if (success) {
            showMessage('Successfully created', 'success-msg')
            setData((prev) => ({
                ...prev,
                items: [...(prev.items || []), response].every((v) => v.roomId === values.roomid)
                    ? [...prev.items, response]
                    : prev.items
                        ? [...prev.items, response]
                        : [response],
            }))
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    useEffect(() => {
        if (selectedUnregistered) {
            const selected = unregistered.find((v) => v.deviceId === selectedUnregistered)
            setInitialValues((prev) => ({
                ...prev,
                ...selected,
            }))
        }
    }, [selectedUnregistered])

    const handleUnregisteredClose = () => {
        setSelectedUnregistered(null) // Reset the selected option
        setOpenUnregistered(false)
    }

    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                disabled={disabled}
                sx={disabled ? { opacity: 0.5 } : {}}
            >
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Create Smart Camera</h3>
                    <XIconSizeS className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>
                <DialogContent style={{ padding: 0 }}>
                    <SmartCameraForm
                        loading={createSmartCamera.loading}
                        initialValues={initialValues}
                        onClose={() => setOpen(false)}
                        openUnregistered={() => setOpenUnregistered(true)}
                        onSubmit={create}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={openUnregistered} onClose={handleUnregisteredClose} fullWidth>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <h3>Select Unregistered Camera</h3>
                    <XIconSizeS className="cursor-pointer" onClick={handleUnregisteredClose} />
                </div>
                <DialogContent style={{ padding: 0 }}>
                    <Box p={3}>
                        <Select
                            variant="outlined"
                            displayEmpty
                            renderValue={(value) => (value
                                ? unregisteredOptions.find((v) => v.value === value)?.label
                                : 'Select Camera')}
                            sx={{ height: 37, width: '10rem', margin: '0 auto' }}
                            value={selectedUnregistered}
                            onChange={(e) => setSelectedUnregistered(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '300px',
                                    },
                                },
                            }}
                        >
                            {!loadUnregistered.loading && unregisteredOptions.length ? (
                                unregisteredOptions.map((menuItem) => (
                                    <MenuItem key={menuItem.value} value={menuItem.value}>
                                        {menuItem.label}
                                    </MenuItem>
                                ))
                            ) : loadUnregistered.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : (
                                <MenuItem value="">Nothing Found</MenuItem>
                            )}
                        </Select>

                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={[]}
                        >
                            {({ handleChange, values }) => (
                                <Form className="flex flex-col justify-between p-4 gap-3">
                                    <Grid container spacing="12px" columnSpacing={2}>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceName"
                                                label="Device Name"
                                                value={values.deviceName}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                required
                                                name="deviceId"
                                                label="Device ID"
                                                value={values.deviceId}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceMac"
                                                label="Device Mac"
                                                value={values.deviceMac}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="libPlatformVersion"
                                                label="Lib Platform Version"
                                                value={values.libPlatformVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="softwareVersion"
                                                label="Software Version"
                                                value={values.softwareVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="libAiVersion"
                                                label="Lib AI Version"
                                                value={values.libAiVersion}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width="50%">
                                            <FormTextField
                                                fullWidth
                                                disabled
                                                name="deviceIp"
                                                label="Device IP"
                                                value={values.deviceIp}
                                                handleChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    <div className="flex items-center justify-end gap-2.5 m-[10px]">
                        <PrimaryBtn
                            title="Apply"
                            iconProps={{ fill: '#fff' }}
                            onClick={handleUnregisteredClose}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
