import React, { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import Paper from '@mui/material/Paper'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ALLOWED_REGIONS, DISTRICTS_LIST } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export default function TenantEntityFilter() {
    const { managmentFilter, setManagmentFilters } = useFilter()
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: managmentFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []

    const regionsOption = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOption = districts.map((district) => ({ value: district.id, label: district.name }))

    useEffect(() => {
        if (managmentFilter.region) {
            getDistricts.request()
        }
    }, [managmentFilter.region])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="w-[100%] flex items-center gap-2">
                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="region-label">Region</InputLabel>
                        <Select
                            labelId="region-label"
                            id="region-select"
                            label="Region"
                            disabled={!regionsOption.length}
                            value={managmentFilter.region}
                            onChange={(e) => setManagmentFilters({ region: e.target.value })}
                            variant="outlined"
                        >
                            {!loadRegions.loading && regionsOption.length ? regionsOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            )) : loadRegions.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="district-label">District</InputLabel>
                        <Select
                            labelId="district-label"
                            id="district-select"
                            label="Region"
                            disabled={!districtOption.length}
                            value={managmentFilter.district}
                            onChange={(e) => setManagmentFilters({ district: e.target.value })}
                            variant="outlined"
                        >
                            {!getDistricts.loading && districtOption.length ? districtOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            )) : loadRegions.loading ? (
                                <MenuItem>
                                    <Skeleton width="50%" height={30} />
                                </MenuItem>
                            ) : <MenuItem value="">Nothing Found</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
