import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Paper, Tabs, Tab, Box, Typography } from '@mui/material'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import { useLoad } from '../../../shared/hooks/requests'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import useWebSocket from '../../../shared/hooks/websocket'
import { useMessage } from '../../../shared/hooks/message'
import { STREAM_ANALYTICS_NOTIFICATIONS, ANALYTICS_HISTORY } from '../../../shared/utils/urls'
import NotificationModal from './NotificationModal'
import Modal from '../../../components/common/Modal'
import Pagination from '../../../components/common/Pagination'

const initialTabItems = [
    { id: 'suspicious', label: 'Suspicious Item' },
    { id: 'gun', label: 'Gun Detection' },
    { id: 'safe-zone', label: 'Intruder Zone' },
    { id: 'line', label: 'Line Cross' },
    { id: 'overcrowd', label: 'Overcrowd Detection' },
]

export default function Notifications() {
    const modalRef = useRef()
    const showMessage = useMessage()
    const { streamAnalyticsFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [selected, setSelected] = useState({})
    const [open, setOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState('safe-zone')
    const socketUrl = process.env.REACT_APP_API_SOCKET_URL
        + STREAM_ANALYTICS_NOTIFICATIONS.replace('{id}', streamAnalyticsFilter.jetsonDeviceId)

    const { filteredData = [] } = useWebSocket(socketUrl)

    const loadHistory = useLoad(
        { url: ANALYTICS_HISTORY,
            params: { jetsonDeviceId: streamAnalyticsFilter.id,
                alarm_category: selectedTab,
                // date of yesterday (2024-10-08)
                start_period: moment().format('YYYY-MM-DD'),
                end_period: moment().format('YYYY-MM-DD'),
                limit: 10,
                offset: 0 } },
        [page],
    )

    useEffect(() => {
        if (!notificationsByType[selectedTab]) {
            console.log('notificationsByType[selectedTab]', notificationsByType[selectedTab])
        }
        // check if there is already data in the notification by current tab
        if (!notificationsByType[selectedTab] || notificationsByType[selectedTab].length <= 8) {
            loadHistory.request()
        }
    }, [streamAnalyticsFilter, selectedTab])

    useEffect(() => {
        // Exit early if loadHistory.response is not available
        if (!loadHistory.response) {
            return
        }
        // Merging and modifying reports from loadHistory.response
        const reportsMerged = loadHistory.response.reduce((mergedReports, report) => {
            // Check if the report has a 'reports' array to merge
            if (report.reports && Array.isArray(report.reports)) {
                // Map through each report item and modify the structure
                const modifiedReports = report.reports.map((item) => (
                    {
                        camera_name: report.roi.name,
                        frame_image: item.entranceFrameImage,
                        // make from 2024-10-08T19:00:45.567000 - timestamp
                        timestamp: moment(item.entranceTime).format('YYYY-MM-DD HH:mm:ss'),
                    }
                ))
                // Concatenate the modified reports with the mergedReports array
                return mergedReports.concat(modifiedReports)
            }
            return mergedReports
        }, [])
        // You can now set the merged and modified reports into state
        setNotificationsByType((prevNotifications) => {
            const updatedNotifications = { ...prevNotifications }
            // leave 10 last notifications
            updatedNotifications[selectedTab] = reportsMerged.slice(-10).reverse()
            return updatedNotifications
        })
    }, [loadHistory.response])
    // Состояние для хранения уведомлений по типу
    const [notificationsByType, setNotificationsByType] = useState({})
    const notifications = notificationsByType[selectedTab] || []
    const totalCount = notifications.length
    const pageCount = Math.ceil(totalCount / pageSize)

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
        setPage(1)
    }
    const handleOpen = useCallback((item) => {
        setSelected(item)
        setOpen(true)
    }, [])

    // Используем useRef для хранения обработанных идентификаторов сообщений
    const processedMessageIds = useRef(new Set())

    useEffect(() => {
        if (page > pageCount && pageCount > 0) {
            setPage(pageCount)
        }
    }, [page, pageCount])

    const displayedNotifications = notifications.slice((page - 1) * pageSize, page * pageSize)

    useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            // Получаем только новые сообщения, которые ещё не были обработаны
            const newMessages = filteredData.filter(
                (message) => !processedMessageIds.current.has(message.report_id),
            )

            if (newMessages.length > 0) {
                setNotificationsByType((prevNotifications) => {
                    const updatedNotifications = { ...prevNotifications }
                    newMessages.forEach((notification) => {
                        const type = notification.notification_type
                        if (!updatedNotifications[type]) {
                            updatedNotifications[type] = []
                        }
                        // Добавляем новое уведомление в начало массива
                        updatedNotifications[type] = [notification, ...updatedNotifications[type]]
                        // Добавляем идентификатор сообщения в набор обработанных
                        processedMessageIds.current.add(notification.report_id)
                    })
                    return updatedNotifications
                })

                showMessage('New notifications received')
            }
        }
    }, [filteredData])

    // when jetsonDeviceId changes, reset notifications

    useEffect(() => {
        setNotificationsByType({
            suspicious: [],
            gun: [],
            'safe-zone': [],
            line: [],
            overcrowd: [],
        })
        processedMessageIds.current = new Set()
    }, [streamAnalyticsFilter.jetsonDeviceId])

    return (
        <Paper square className="flex flex-col gap-2.5 h-full">
            <Modal
                modalRef={modalRef}
                open={open}
                onClose={() => setOpen(false)}
            >
                <NotificationModal item={selected} modalRef={modalRef} />
            </Modal>
            <Typography variant="h6" className={cn(css(s.title))}>
                Notifications
            </Typography>
            <Box className={cn(css(s.tabsWrapper))}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="notification tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    className={cn(css(s.tabs))}
                    indicatorColor="none"
                >
                    {initialTabItems.map((tab) => (
                        <Tab
                            key={tab.id}
                            label={tab.label}
                            value={tab.id}
                            className={cn(css(s.tab))}
                            classes={{
                                selected: css(s.tabSelected),
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box className={cn(css(s.notificationsList))}>
                {displayedNotifications.length > 0 ? (
                    displayedNotifications.map((notification) => (
                        <NotificationItem
                            key={notification.id || notification.report_id}
                            notification={notification}
                            handleOpen={handleOpen}
                        />
                    ))
                ) : (
                    <Typography variant="body1" align="center" sx={{ mt: '20px' }}>
                        No notifications
                    </Typography>
                )}
            </Box>
            <Pagination
                page={page}
                showSelectRows={false}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageCount={pageCount}
                count={totalCount}
            />
        </Paper>
    )
}

function NotificationItem({ notification, handleOpen }) {
    const cameraName = notification.camera_name || '-'
    const frameImage = notification.frame_image
    // Получаем дату и время из created_at
    const date = moment(notification.timestamp).format('DD.MM.YYYY')
    const time = moment(notification.timestamp).format('HH:mm')

    return (
        <div className={cn(css(s.notificationItem))}>
            <div className={cn(css(s.notificationImageWrapper))}>
                <img src={frameImage} alt="camera" className={cn(css(s.notificationImage))} />
                {notification.notification_type === 'gun' && (
                    <img src={frameImage} alt="camera" className={cn(css(s.notificationImage))} />
                )}
            </div>
            <div className={cn(css(s.notificationBodyWrapper))}>
                <div>
                    <span className={cn(css(s.titleText))}>Camera: </span>
                    <span className={cn(css(s.opacityText))}>{cameraName}</span>
                </div>
                <div>
                    <span className={cn(css(s.titleText))}>Date: </span>
                    <span className={cn(css(s.opacityText))}>{date}</span>
                </div>
                <div>
                    <span className={cn(css(s.titleText))}>Time: </span>
                    <span className={cn(css(s.opacityText))}>{time}</span>
                </div>
            </div>
            <div className={cn(css(s.btnWrapper))}>
                <Button className={css(s.actBtn)} onClick={() => handleOpen(notification)}>Details</Button>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    tabsWrapper: {
        overflowX: 'auto',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '30px',
    },
    tabs: {
        backgroundColor: COLORS.sepia,
        display: 'flex',
        minHeight: '30px',
        justifyContent: 'center',
        borderRadius: '6px',
    },
    tab: {
        minHeight: '30px',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        fontWeight: 400,
        padding: '6px 10px',
        textTransform: 'capitalize',
        ':nth-child(2n)': {
            borderRight: `1px solid ${COLORS.gainsboro}`,
            borderLeft: `1px solid ${COLORS.gainsboro}`,
        },
        ':last-child': {
            borderRight: 'none',
        },
        ':hover': {
            opacity: 0.8,
        },
    },
    tabSelected: {
        backgroundColor: COLORS.mainBlue,
        color: COLORS.white,
    },
    notificationsList: {
        marginTop: '16px',
        maxHeight: '100%',
        overflowY: 'auto',
    },
    title: {
        margin: '16px 0 0 12px',
        fontSize: 18,
        fontWeight: 600,
    },
    notificationItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 16px',
        borderBottom: `1px solid ${COLORS.sepia}`,
        gap: '40px',
        ':last-child': {
            borderBottom: 'none',
        },
    },
    btnWrapper: {
        marginLeft: 'auto',
        marginRight: '20px',
    },
    notificationImageWrapper: {
        display: 'flex',
        gap: '5px',
    },
    notificationImage: {
        width: '53px',
        height: '59px',
        borderRadius: '10px',
        marginRight: '10px',
    },
    notificationBodyWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
    },
    titleText: {
        color: COLORS.black,
        fontWeight: 500,
    },
    opacityText: {
        color: '#878787',
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '4px 9px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        ':hover': {
            background: COLORS.mainBlue,
        },
    },
})
