import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../shared/utils/colors'
import devicePreview from '../../../static/images/device-preview.png'

export default function DeviceItem({ item, handleOpen }) {
    return (
        <div className={css(s.deviceItem)} onClick={() => handleOpen(item)}>
            <img className={css(s.deviceItemImage)} src={devicePreview} alt="device" />
            <div className={css(s.deviceItemBody)}>
                <h2 className={css(s.deviceTitle)}>{item.name}</h2>
                <div className={css(s.deviceBadgeWrapper)}>
                    { item.online > 0 && <p className={css(s.diveceBadge, s.deviceOnline)}>{item.online}</p> }
                    { item.offline > 0 && <p className={css(s.diveceBadge, s.deviceOffline)}>{item.offline}</p> }
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    deviceItem: {
        borderRadius: '5px',
        backgroundColor: COLORS.cardInnerBg,
        border: `1px solid ${COLORS.sepia}`,
        cursor: 'pointer',
        transition: '0.3s',
        ':hover': {
            transform: 'scale(1.01)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        },
    },
    deviceItemImage: {
        width: '100%',
        height: 'auto',
    },
    deviceItemBody: {
        padding: '12px',
    },
    deviceTitle: {
        fontSize: '16px',
        fontWeight: 800,
        marginBottom: '16px',
    },
    deviceBadgeWrapper: {
        display: 'flex',
        gap: '6px',
    },
    diveceBadge: {
        padding: '4px 18px',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 500,
    },
    deviceOnline: {
        backgroundColor: '#07D9424D',
        color: '#0B9E34',
    },
    deviceOffline: {
        backgroundColor: '#D907144D',
        color: '#B1000B',
    },
})
