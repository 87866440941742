import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar } from '@mui/material'
import moment from 'moment'

export default function CustomTable({ data, cols, selectedTab, loading }) {
    const [transformedData, setTransformedData] = useState([])

    useEffect(() => {
        setTransformedData([])
        let transformed = []

        if ((selectedTab === 'working' || selectedTab === 'service') && data.length > 0) {
            transformed = data[selectedTab].reduce((acc, curr) => {
                const { identity } = curr
                const analyticsReport = curr.analytics_report || []
                const duplicated = analyticsReport.map((item) => ({ ...item, identity }))
                return [...acc, ...duplicated]
            }, [])
        } else if (selectedTab === 'lineCrossing' && data.length > 0) {
            transformed = data[selectedTab]
        } else {
            transformed = data[selectedTab]
        }

        setTransformedData(transformed)
    }, [data, selectedTab])

    return (

        <Table aria-label="custom table">
            <TableHead>
                <TableRow>
                    {cols.map((col) => (
                        <TableCell key={col.id}>{col.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            {
                loading ? (
                    <TableRow>
                        <TableCell colSpan={cols.length} align="center">
                            Loading...
                        </TableCell>
                    </TableRow>
                ) : (
                    <TableBody>
                        {transformedData.map((item, index) => {
                            if (selectedTab === 'lineCrossing') {
                                return <LineCrossingTableItem key={item.id || index} item={item} index={index} />
                                // eslint-disable-next-line no-else-return
                            } else if (selectedTab === 'working') {
                                return <WorkspaceTableItem key={item.id || index} item={item} index={index} />
                            } else if (selectedTab === 'service') {
                                return <WorkspaceTableItem key={item.id || index} item={item} index={index} service />
                            } else {
                                return <DefaultTableItem key={item.id || index} item={item} index={index} />
                            }
                        })}
                        {transformedData.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={cols.length} align="center">
                                    No data
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                )
            }
        </Table>
    )
}

function WorkspaceTableItem({ item, index, service = false }) {
    // check if item is null or undefined or empty object
    if (!item || Object.keys(item).length === 0) {
        return null
    }
    const firstName = (item.identity && item.identity.firstName) || '-'
    const lastName = (item.identity && item.identity.lastName) || '-'
    const fullName = `${firstName} ${lastName}`
    const roiName = (item.roi && item.roi.name) || '-'
    const photo = (item.identity && item.identity.photo) || '-'

    const totalMinutes = (item.analytics && item.analytics.minutesWorkTime) || 0
    const totalMinutesService = (item.analytics && item.analytics.minutesServiceTime) || 0

    if (!service && totalMinutes === 0) {
        return null
    }

    if (service && totalMinutesService === 0) {
        return null
    }

    const firstEntry = (item.analytics && item.analytics.roiFirstEntrance) || '-'
    const lastExit = (item.analytics && item.analytics.roiLastExit) || '-'

    const formattedFirstEntry = firstEntry !== '-' ? moment(firstEntry).format('HH:mm') : '-'
    const formattedLastExit = lastExit !== '-' ? moment(lastExit).format('HH:mm') : '-'

    // date from 2024-10-18 to 18.10.2024
    const date = item.date ? moment(item.date).format('DD.MM.YYYY') : '-'
    return (
        <TableRow key={item.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{roiName}</TableCell>
            <TableCell>
                <Avatar alt={fullName} src={photo} />
            </TableCell>
            <TableCell>{fullName}</TableCell>
            <TableCell>
                {formattedFirstEntry} - {formattedLastExit}
            </TableCell>
            <TableCell>{service ? totalMinutesService : totalMinutes}</TableCell>
            <TableCell>{date}</TableCell>
        </TableRow>
    )
}

function LineCrossingTableItem({ item, index }) {
    if (!item || Object.keys(item).length === 0) {
        return null
    }
    const date = item.date ? moment(item.date).format('DD.MM.YYYY') : '-'
    const lineName = (item.line && item.line.name) || '-'
    const crossings = item.crossings || []

    return (
        <TableRow key={item.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{date}</TableCell>
            <TableCell>{lineName}</TableCell>
            <TableCell>{crossings.length}</TableCell>
            {/* Add other cells as needed */}
        </TableRow>
    )
}

function DefaultTableItem({ item, index }) {
    if (!item || Object.keys(item).length === 0) {
        return null
    }
    // Placeholder for other tabs; customize as needed
    return (
        <TableRow key={item.id || index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{/* Add appropriate data fields */}</TableCell>
            {/* Add more TableCell components based on your data structure */}
        </TableRow>
    )
}
