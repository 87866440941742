/* eslint-disable */
import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import PhoneInput from '../../../shared/ui/PhoneInput'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    fileName: '',
    description: '',
}

const VALIDATION_SCHEMA = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().test(
        'is-email',
        'Given email address is not valid',
        (value) => (`${value}`).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ).required('Email is required'),
    phone: string().required('Phone is required'),
    photo: string().optional(),
    fileName: string().optional(),
    description: string().optional(),
})

export default function TenantAdminForm({
    onSubmit = () => {},
    onClose = () => {},
    isEdit,
    loading = false,
    initialValues,
}) {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ handleChange }) => (
                <Form className="h-auto flex flex-col justify-between gap-10 overflow-y-auto p-4">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <FormTextField
                            label="First name"
                            name="firstName"
                            required
                            handleChange={handleChange}
                        />

                        <FormTextField
                            required
                            label="Last name"
                            name="lastName"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            label="Email"
                            name="email"
                            required
                            handleChange={handleChange}
                        />

                        <PhoneInput
                            required
                            label="Phone number"
                            name="phone"
                            handleChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button"
                        />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
