import React, { useRef, useState } from 'react'
import { Dialog, DialogContent, TableCell, Typography } from '@mui/material'
import Details from '../feature/details'
import { AuthCheck, XIconSizeM } from '../../../components/svgs/Svgs'
import { usePostFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import { IMAGE_UPLOADER } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'

function ImageUploader({ onImageUpload }) {
    const fileInputRef = useRef(null)

    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '' // Reset the file input before a new upload
            fileInputRef.current.click() // Open file selection dialog
        }
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0]
        if (file) {
            const base64Image = await convertToBase64(file)
            onImageUpload(base64Image) // This will now simply set state in parent
        }
    }

    const convertToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })

    return (
        <div>
            <div onClick={handleIconClick}>
                <AuthCheck />
            </div>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
        </div>
    )
}

export default function AuthLogTableItems({ item }) {
    const [personImage, setPersonImage] = useState('')
    const [showFull, setShowFull] = useState(false)
    const postImage = usePostFaceRequest()
    const showMessage = useMessage()

    const toggleUserId = () => {
        setShowFull(!showFull)
    }
    const handleImageUpload = async (base64Image) => {
        const response = await postImage.request({
            url: IMAGE_UPLOADER,
            data: {
                individual_personal_number: item.individualPersonalNumber,
                image_data: base64Image,
            },
        })

        if (response.error && response.error.status === 400) {
            showMessage(response.error.data.detail, 'error-msg')
        } else if (response.success === true) {
            showMessage('Image uploaded successfully', 'success-msg')
        }
    }

    return (
        <>
            <TableCell onClick={toggleUserId}>
                {item.externalUserId ? (showFull ? item.externalUserId
                    : `${item.externalUserId.slice(0, 3)}`) : 'N/A'}
            </TableCell>
            <TableCell>{item.client.clientName}</TableCell>
            <TableCell>{item.individualPersonalNumber}</TableCell>
            <TableCell className="whitespace-nowrap">
                {item.createdAt.slice(0, 16).replace('T', ' ')}
            </TableCell>
            <TableCell align="center" onClick={() => setPersonImage(item.referenceImageUrl)}>
                <div className="flex cursor-pointer rounded w-[53px] my-1 border
                    border-sepia-100 h-inherit items-center justify-center overflow-hidden">
                    <img src={item.referenceImageUrl} alt={item.referenceImageObjectName}
                        className="h-full w-full object-cover" />
                </div>
            </TableCell>
            <TableCell align="center" onClick={() => setPersonImage(item.targetImageUrl)}>
                <div className="flex cursor-pointer rounded w-[53px] my-1 border
                    border-sepia-100 h-inherit items-center justify-center overflow-hidden">
                    <img src={item.targetImageUrl} alt={item.targetImageObjectName}
                        className="h-full w-full object-cover" />
                </div>
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.apiType}
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.isAuthenticated ? <span>True</span> : <span>False</span>}
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.distance.toFixed(3)}
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.isFaceMatched ? <span>True</span> : <span>False</span>}
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.isSpoofed ? <span>True</span> : <span>False</span>}
            </TableCell>
            <TableCell className="flex items-center justify-center" align="center">
                {item.spoofingScore.toFixed(3)}
            </TableCell>
            <TableCell style={{ paddingRight: 0 }} align="center">
                <div className="w-[100%] flex items-center justify-center gap-[9px]">
                    <ImageUploader onImageUpload={handleImageUpload} />
                    <Details item={item} />
                </div>
            </TableCell>
            <Dialog fullWidth onClose={() => setPersonImage('')} open={Boolean(personImage)}>
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Image</Typography>
                    <XIconSizeM className="cursor-pointer" onClick={() => setPersonImage('')} />
                </div>
                <DialogContent style={{ padding: 0 }}>
                    <img className="object-cover w-full h-full" src={personImage} alt="PERSONIMAGE" />
                </DialogContent>
            </Dialog>
        </>
    )
}
