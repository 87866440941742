import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ProtectedRoute } from '../components/ProtectedRoute'
import NotFound from '../errors/NotFound'
import GlobalContext from './contexts/GlobalContext'
import { routes } from './routes'
import FilterContextProvider from './contexts/FilterContextProvider'
import Layout from './layouts/Layout'
import HomePage from '../modules/corporative-page'
import './styles/index.css'

export default function App() {
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <BrowserRouter>
            <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} preventDuplicate maxSnack={2}>
                <GlobalContext>
                    <FilterContextProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Routes>
                                <Route element={<Layout />}>
                                    {routes.filter((r) => !r.noLayer).map(
                                        ({
                                            path,
                                            component: Component,
                                            protectedBy,
                                            redirectPath,
                                        }) => (
                                            <Route
                                                key={path}
                                                path={path}
                                                element={(
                                                    <ProtectedRoute
                                                        protectedBy={protectedBy}
                                                        redirectPath={redirectPath}
                                                    >
                                                        <Component />
                                                    </ProtectedRoute>
                                                )}
                                            />
                                        ),
                                    )}
                                </Route>

                                <Route element={<HomePage />}>
                                    {routes.filter((r) => r.corporative && r.noLayer).map(
                                        ({
                                            path,
                                            component: Component,
                                            protectedBy,
                                            redirectPath,
                                        }) => (
                                            <Route
                                                key={path}
                                                path={path}
                                                element={(
                                                    <ProtectedRoute
                                                        protectedBy={protectedBy}
                                                        redirectPath={redirectPath}
                                                    >
                                                        <Component />
                                                    </ProtectedRoute>
                                                )}
                                            />
                                        ),
                                    )}
                                </Route>

                                {routes.filter((r) => r.noLayer).map(
                                    ({
                                        path,
                                        component: Component,
                                        protectedBy,
                                        redirectPath,
                                    }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            element={(
                                                <ProtectedRoute
                                                    protectedBy={protectedBy}
                                                    redirectPath={redirectPath}
                                                >
                                                    <Component />
                                                </ProtectedRoute>
                                            )}
                                        />
                                    ),
                                )}
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </LocalizationProvider>
                    </FilterContextProvider>
                </GlobalContext>
            </SnackbarProvider>
        </BrowserRouter>
    )
}
