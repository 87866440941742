/* eslint-disable */
import React from 'react'
import { Formik, Form } from 'formik'
import { number, object, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

// Validation schema for the form
const VALIDATION_SCHEMA = object().shape({
    name: string().min(2, 'Name must be at least 2 characters long').required('Name is required'),
    allowedRadius: number().nullable(),
    faceAuthThreshold: number().nullable(),
    spoofingThreshold: number().nullable(),
})

// eslint-disable-next-line import/prefer-default-export
export function TenantEntityManagementForm({
    item,
    onSubmit = () => {},
    onClose = () => {},
    loading,
}) {
    // Define initial form state with current values
    const FORM_INITIAL_STATE = {
        name: item.name || '',
        allowedRadius: item.allowedRadius || '',
        faceAuthThreshold: item.faceAuthThreshold || '',
        spoofingThreshold: item.spoofingThreshold || '',
    }

    return (
        <Formik
            enableReinitialize
            initialValues={FORM_INITIAL_STATE}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ handleChange }) => (
                <Form className="h-auto flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    {/* Form Fields */}
                    <div className="flex-col gap-4 overflow-y-auto">
                        {/* Name Field */}
                        <FormTextField
                            label="Name"
                            name="name"
                            handleChange={handleChange}
                        />

                        {/* Allowed Radius Field */}
                        <FormTextField
                            type="number"
                            label="Allowed Radius"
                            name="allowedRadius"
                            handleChange={handleChange}
                            overrideStyles={{ height: 'auto' }}
                        />

                        {/* Face Auth Threshold Field */}
                        <FormTextField
                            type="number"
                            label="Face Auth Threshold"
                            name="faceAuthThreshold"
                            handleChange={handleChange}
                            overrideStyles={{ height: 'auto' }}
                        />

                        {/* Spoofing Threshold Field */}
                        <FormTextField
                            type="number"
                            label="Spoofing Threshold"
                            name="spoofingThreshold"
                            handleChange={handleChange}
                            overrideStyles={{ height: 'auto' }}
                        />
                    </div>

                    {/* Buttons */}
                    <div className="flex items-center justify-end gap-2.5">
                        {/* Cancel Button */}
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button"
                        />

                        {/* Submit Button */}
                        <PrimaryBtn
                            loading={loading}
                            title="Edit"
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
