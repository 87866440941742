import React, { useState } from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { ReviewIcon, XIconSizeM } from '../../../components/svgs/Svgs'
import { useGetFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import { REVIEW_PHOTOS } from '../../../shared/utils/urls'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

export default function Details({ personalNumber }) {
    const [open, setOpen] = useState(false)
    const getUserPhotos = useGetFaceRequest({ url: REVIEW_PHOTOS })
    const data = getUserPhotos.response ? getUserPhotos.response.images : []

    // Function to handle opening the dialog and sending the request
    const handleOpen = () => {
        setOpen(true)
        getUserPhotos.request({
            url: `${REVIEW_PHOTOS}${personalNumber}`,
        })
    }

    return (
        <>
            <div onClick={handleOpen}>
                <ReviewIcon />
            </div>

            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        width: 'auto', // Change width as needed
                        height: 'auto', // Change height as needed
                        maxWidth: 'none',
                        maxHeight: 'none',
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Change history</Typography>
                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 1, width: '100%' }}>
                    <div className="w-[582px] flex flex-col items-center  h-[40%] overflow-scroll">
                        <div className=" w-[100%] py-[20px] px-[35px]
                         border-b border-[#EEE] flex items-center justify-between">
                            <div className="w-[33%]  flex items-center justify-start">
                                <span className="text-[14px] font-[500] text-black">Date</span>
                            </div>
                            <div className="w-[33%]  flex items-center justify-start">
                                <span className="text-[14px] font-[500] text-black">Time</span>
                            </div>
                            <div className="w-[33%]  flex items-center justify-center">
                                <span className="text-[14px] font-[500] text-black">Photo</span>
                            </div>
                        </div>
                        <div className="w-[100%] h-[500px] overflow-scroll">
                            {data.length > 0 ? (
                                data.map((item, index) => {
                                    const [date, time] = item.createdAt.split('T') // Split date and time from createdAt

                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={index} className="w-[100%] py-[12px] px-[35px]
                                        border-b border-[#EEE] flex items-center justify-between">
                                            <div className="w-[33%] flex items-center justify-start">
                                                {/* Display Date */}
                                                <span className="text-[14px] font-normal text-black">{date}</span>
                                            </div>
                                            <div className="w-[33%] flex items-center justify-start">
                                                {/* Display Time */}
                                                <span className="text-[14px] font-normal text-black">
                                                    {time.split('.')[0]}
                                                </span> {/* Remove milliseconds */}
                                            </div>
                                            <div className="w-[33%] flex items-center justify-center">
                                                <div className="w-[53px] h-[66px] rounded-[5px] overflow-hidden">
                                                    <img src={item.imageUrl} alt="img of mine"
                                                        className="w-[100%] h-[100%] object-cover" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="flex w-[100%] h-[100%] flex-col items-center justify-center">
                                    <LoadingSpinner />
                                </div>
                            )}
                        </div>
                        <div className="w-[100%] pt-[20px] pb-[14px] pr-[26px] flex items-center justify-end">
                            <span onClick={() => setOpen(false)}
                                className="py-[8px] cursor-pointer px-[35px] rounded-[8px] bg-main-100 text-white">OK
                            </span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
