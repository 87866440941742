/* eslint-disable */
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeroImage from '../../../static/images/discoverImg.jpg'
import { BusinessCard } from './components/BusinessCard'
import OneSystemLogo from '../../../static/images/onesystemLogo.png'
import UniPass from '../../../static/images/unipass.png'
import { PartnersCard } from './components/PartnersCard'
import OneSystemImage from '../../../static/images/onesystemImage.jpg'
import AIAdvokat from '../../../static/images/AiAdvokat.webp'
import UniPassXL from '../../../static/images/unipass.webp'

export const MainPage = React.memo(() => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const usData = [
        {
            id: 1,
            img: OneSystemImage,
            title: 'Vision Analytics',
            text: t('landing.cards.visionAnalytics.text'),
            demo: 'https://realsoft.ai/auth?demo=true',
        },
        {
            id: 2,
            img: UniPassXL,
            title: 'UniPass',
            text: t('landing.cards.uniPass.text'),
            demo: 'https://uni-pass.uz/auth?demo=true',
        },
        {
            id: 3,
            img: AIAdvokat,
            title: 'ML Hub',
            text: t('landing.cards.mlHub.text'),
        },
    ]

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])

    return (
        <div className="w-full flex flex-col lg:max-w-[1440px] lg:m-auto lg:px-[20px] gap-[100px]">
            {/* discover page */}
            <section className="w-full flex sm:flex-col h-[100%] lg:flex-row pt-[38px] lg:items-center gap-[32px]">
                <div className="sm:w-full relative overflow-hidden lg:w-[50%]">
                    <img
                        src={HeroImage}
                        loading="lazy"
                        alt={t('landing.main.hero.alt')}
                        className="w-full h-full object-cover transition-transform duration-700 ease-in-out
                        transform hover:scale-110"
                    />
                </div>
                <div className="sm:w-full flex flex-col lg:w-[50%] items-start px-[24px] gap-[33px]">
                    <div className="flex flex-col items-start gap-[15px]">
                        <div className="flex flex-col items-start gap-[6px]">
                            <span className="text-[12px] font-normal text-[#0068B5] dark:text-white">
                                {t('landing.main.hero.title')}
                                /
                            </span>
                            <span className="text-[31px] font-[300] text-[#262626] dark:text-white w-[80%]">
                                {t('landing.main.hero.subtitle')}
                            </span>
                        </div>
                        <span className="text-[14px] font-normal text-[#262626] dark:text-white lg:w-[90%]">
                            {t('landing.main.hero.description')}
                        </span>
                    </div>
                    <button
                        type="button"
                        className="text-white dark:text-black font-normal border hover:border-[#0068B5]
                        hover:bg-transparent hover:text-[#0068B5] transition-all duration-500 text-[12px] px-[18px]
                        py-[10px] bg-[#0068B5] dark:bg-white"
                        onClick={() => navigate('/about')}
                    >
                        {t('landing.main.hero.button')}
                    </button>
                </div>
            </section>
            {/* cards page */}
            <section>
                <div className="flex flex-col sm:px-[24px] gap-[50px]">
                    <div className="flex flex-col items-start gap-[10px]">
                        <span className="text-[#0068B5] dark:text-white font-normal text-[14px]">
                            {t('landing.main.cards.title')}
                        </span>
                        <span className="sm:text-[31px] font-[300] text-[#262626] dark:text-white">
                            {t('landing.main.cards.subtitle')}
                        </span>
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 sm:gap-[30px] lg:gap-[70px]">
                        {usData.map((item) => (
                            <BusinessCard data={item} key={item.title} />
                        ))}
                    </div>
                </div>
            </section>
            {/* partners page */}
            <section>
                <div className="flex flex-col sm:gap-[24px] mb-[70px] sm:px-[24px] lg:gap-[50px]">
                    <div className="flex flex-col items-start gap-[5px]">
                        <span className="text-[14px] font-normal text-[#0068B5] dark:text-white">
                            {t('landing.main.partners.title')}
                        </span>
                        <span className="text-[31px] font-[300] text-[#262626] dark:text-white">
                            {t('landing.main.partners.subtitle')}
                        </span>
                    </div>
                    <div className="flex sm:flex-col md:flex-row items-center gap-[14px] lg:gap-[30px]">
                        <div className="sm:py-[56px] lg:py-[93px] sm:px-[50px] lg:px-[120px] bg-[#404040] flex
                        flex-col items-center justify-center"
                        >
                            <span className="text-[91px] font-normal text-white">10</span>
                            <span className="text-[21px] whitespace-nowrap font-normal text-white">
                                {t('landing.main.partners.counter')}
                            </span>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 sm:gap-[14px] lg:gap-[30px]">
                            <PartnersCard img={OneSystemLogo} />
                            <PartnersCard img={UniPass} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
})
