import React from 'react'
import { MenuItem, Skeleton, InputBase, ListSubheader, Box, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material'
import FormTextField from './FormTextField'

export default function SelectInput({
    label,
    name,
    onOpen,
    loading = false,
    menuItemClassName,
    menuItems = [],
    onChange,
    maxWidth,
    selectProps,
    searchable = false,
    placeholder = 'Search...',
    selectPlaceholder = 'Select an option',
    onSearch,
    searchValue = '',
    styles = {},
    tableMode = false, // New prop to enable table mode
    ...attributes
}) {
    const handleSearchKeyDown = (event) => {
        event.stopPropagation()
    }

    const handleSearchChange = (e) => {
        if (onSearch) {
            onSearch(e.target.value)
        }
    }

    return (
        <FormTextField
            label={label}
            name={name}
            select
            overrideStyles={{
                '& .MuiInputBase-input': {
                    paddingBottom: '8px',
                    ':focus': {
                        backgroundColor: 'transparent',
                    },
                    maxWidth,
                },
                ...styles,
            }}
            SelectProps={{
                onOpen,
                sx: { maxWidth },
                MenuProps: {
                    autoFocus: false,
                    sx: { '& .MuiPaper-root': { maxHeight: 300 } },
                    MenuListProps: {
                        disablePadding: true,
                    },
                },
                renderValue: (selected) => {
                    if (selected === undefined || selected === '') return selectPlaceholder
                    const selectedItem = menuItems.find((item) => item.value === selected)
                    return selectedItem ? selectedItem.label : ''
                },
                ...selectProps,
            }}
            handleChange={onChange}
            placeholder={selectPlaceholder}
            {...attributes}
        >
            {searchable && (
                <ListSubheader>
                    <InputBase
                        placeholder={placeholder}
                        fullWidth
                        sx={{ padding: '8px' }}
                        onChange={handleSearchChange}
                        value={searchValue}
                        autoFocus
                        onKeyDown={handleSearchKeyDown}
                    />
                </ListSubheader>
            )}
            {menuItems && !loading && menuItems.length ? (
                menuItems.map((menuItem) => (
                    <MenuItem key={menuItem.value} className={menuItemClassName} value={menuItem.value}>
                        {tableMode ? (
                            // Display menu items in table format
                            <Box sx={{ display: 'flex', width: '100%' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><Typography sx={{ fontWeight: 600 }}>{menuItem.col1}</Typography></TableCell>
                                            <TableCell><Typography>{menuItem.col2}</Typography></TableCell>
                                            <TableCell><Typography>{menuItem.col3}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        ) : (
                            // Default list mode
                            menuItem.label
                        )}
                    </MenuItem>
                ))
            ) : loading ? (
                <MenuItem>
                    <Skeleton width="50%" height={30} />
                </MenuItem>
            ) : (
                <MenuItem disabled>
                    Nothing Found
                </MenuItem>
            )}
        </FormTextField>
    )
}
