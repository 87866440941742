/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, Select, Typography } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { COLORS } from '../../../shared/utils/colors'
import { ChevronNextSizeM } from '../../../components/svgs/Svgs'
import Card from './Card'
import { useQueryParams } from '../../../shared/hooks/queryString'
import { useAddQueryParams } from '../../../shared/hooks/addQueryParams'
import { DISTRICTS_LIST, ALLOWED_REGIONS, TENANT_ENTITIES_CHILDREN } from '../../../shared/utils/urls'
import { useGetRequest, useInfiniteScroll, useLoad } from '../../../shared/hooks/requests'
import Branches from './Branches'
import Create from '../features/Create'
import SearchInput from '../../../shared/ui/SearchInput'

export default function Sections({ headQuarters }) {
    const addQueryParams = useAddQueryParams()
    const [filters, setFilters] = useState({
        rId: '',
        dId: '',
    })
    const [searchTerm, setSearchTerm] = useState('')
    const { p_name, sec_id, id } = useQueryParams()
    const getSections = useInfiniteScroll({ url: TENANT_ENTITIES_CHILDREN.replace('{id}', id), params: { search: searchTerm, region_id: filters.rId, district_id: filters.dId } }, Boolean(id), [id, searchTerm, filters.dId, filters.rId])
    const data = getSections.response ? getSections.response.items : []
    const meta = getSections.response ? getSections.response : {}

    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: filters.rId } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOptions = districts.length ? districts.map((district) => ({ value: district.id, label: district.name })) : []

    const options = headQuarters ? headQuarters.map((entity) => (
        { value: entity.id, label: entity.name })) : []

    const handleSearch = (value) => {
        setSearchTerm(value)
    }

    useEffect(() => {
        setFilters((prevState) => ({ ...prevState, dId: '' }))
        if (filters.rId) {
            getDistricts.request()
        }
    }, [filters.rId])

    return (
        <div className="flex flex-col gap-10">
            <div className="flex-col gap-7_5">
                <div className="flex items-center justify-between">
                    <div className="flex gap-5">
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            Sections ( {data.length} )

                            <ChevronNextSizeM color={COLORS.disabled} />

                            {data.length ? p_name : null}
                        </Typography>
                        <div className="flex items-center gap-2">
                            <Select
                                variant="outlined"
                                displayEmpty
                                renderValue={(value) => (value ? regionOptions.find((v) => v.value === value).label : 'Choose region')}
                                sx={{
                                    height: 37,
                                    width: '10rem',
                                }}
                                value={filters.rId}
                                onChange={(e) => setFilters((prevState) => ({
                                    ...prevState,
                                    rId: e.target.value,
                                }))}
                            >
                                {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                                    <MenuItem key={menuItem.value} value={menuItem.value}>
                                        {menuItem.label}
                                    </MenuItem>
                                )) : loadRegions.loading ? (
                                    <MenuItem>
                                        <Skeleton width="50%" height={30} />
                                    </MenuItem>
                                ) : <MenuItem value="">Nothing Found</MenuItem>}
                            </Select>

                            <Select
                                variant="outlined"
                                displayEmpty
                                renderValue={(value) => (value ? districtOptions.find((v) => v.value === value).label : 'Choose district')}
                                sx={{
                                    height: 37,
                                    width: '10rem',
                                }}
                                value={filters.dId}
                                disabled={!districtOptions.length}
                                onChange={(e) => setFilters((prevState) => ({
                                    ...prevState,
                                    dId: e.target.value,
                                }))}
                            >
                                {!getDistricts.loading && districtOptions.length ? districtOptions.map((menuItem) => (
                                    <MenuItem key={menuItem.value} value={menuItem.value}>
                                        {menuItem.label}
                                    </MenuItem>
                                )) : getDistricts.loading ? (
                                    <MenuItem>
                                        <Skeleton width="50%" height={30} />
                                    </MenuItem>
                                ) : <MenuItem value="">Nothing Found</MenuItem>}
                            </Select>
                        </div>
                    </div>

                    <div className="flex-cent gap-4_5">
                        <Create parentOptions={options} hierarchyLevel={2} setData={getSections.setResponse} />

                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>

                {data.length ? (
                    <Grid container spacing={2}>
                        {data.map((item) => (
                            <Card
                                item={item}
                                key={item.id}
                                loading={getSections.loading}
                                setData={getSections.setResponse}
                                parentOptions={options}
                                isActive={item.id === +sec_id}
                                onClick={() => addQueryParams({
                                    sec_id: item.id,
                                    sec_name: item.name,
                                })} />
                        ))}
                    </Grid>
                ) : null}

                {getSections.hasMore && meta.total > meta.size && <div ref={getSections.ref} />}
            </div>

            <Branches sections={data} />
        </div>
    )
}
