import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import CustomAccordion from '../../../shared/ui/CustomAccordion'
import { CaretDownIconSizeM } from '../../../components/svgs/Svgs'
import { COLORS } from '../../../shared/utils/colors'
import { useLoad, usePostRequest } from '../../../shared/hooks/requests'
import { CAMERA_ROI,
    CAMERA_LINES,
    UPDATE_DEEPSTREAM_ROI,
    UPDATE_DEEPSTREAM_LINE } from '../../../shared/utils/urls'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

function List({
    cameraName,
    cameraId,
    selectAll,
    selectAllLines,
    checkedCamera,
    checkedCameraLines,
    select,
    selectLine,
    checked,
    checkedLines,
}) {
    const getRois = useLoad({ url: CAMERA_ROI.replace('{id}', cameraId) }, [])
    const getLines = useLoad({ url: CAMERA_LINES.replace('{id}', cameraId) }, [])

    const roilist = getRois.response ? getRois.response : []
    const linesList = getLines.response ? getLines.response : []

    return (
        <CustomAccordion
            summary={cameraName}
            action={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="flex items-center">
                    <Tooltip title="Select all ROIs" placement="top">
                        <Checkbox
                            sx={{
                                color: COLORS.disabled,
                                '&.Mui-checked': {
                                    color: COLORS.orange,
                                },
                            }}
                            checked={checkedCamera[cameraId] || false}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                selectAll(cameraId, roilist)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Select all Lines" placement="top">
                        <Checkbox
                            sx={{
                                color: COLORS.disabled,
                                '&.Mui-checked': {
                                    color: COLORS.orange,
                                },
                                marginLeft: '8px',
                            }}
                            checked={checkedCameraLines[cameraId] || false}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                selectAllLines(cameraId, linesList)
                            }}
                        />
                    </Tooltip>
                </div>
            }
            contClassName="border-[1px] border-sepia-100"
            detailClassName={
                `border-[1px] max-h-[15rem] flex flex-col gap-4 border-sepia-200 mx-2 mb-3
            rounded-[2px] max-h-[30rem] overflow-y-scroll no-scrollbar`
            }
            summaryClassName="flex flex-row-reverse gap-2 m-0"
            summaryStyles={{ margin: 0 }}
            expandIcon={<CaretDownIconSizeM color={COLORS.placeholder} width={10} height={10} />}
            overrideStyles={{
                ':before': {
                    display: 'none',
                },
                width: '100%',
                boxShadow: 'none',
                borderRadius: '3px',
                '& .MuiAccordionSummary-root': {
                    padding: '0 0 0 10px',
                },
            }}
        >
            <div className="flex flex-col gap-2">
                <Typography sx={{ fontWeight: 500 }}>
                    ROI
                </Typography>

                <FormControlLabel
                    control={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Checkbox
                            sx={{
                                color: COLORS.disabled,
                                '&.Mui-checked': {
                                    color: COLORS.orange,
                                },
                            }}
                            checked={checkedCamera[cameraId] || false}
                            onClick={(e) => {
                                e.stopPropagation()
                                selectAll(cameraId, roilist)
                            }}
                        />
                    }
                    label="Select All"
                    sx={{ margin: 0, color: COLORS.placeholder }}
                />

                {roilist.map((roi) => (
                    <FormControlLabel
                        key={roi.id}
                        sx={{ margin: 0, color: COLORS.placeholder }}
                        className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                        labelPlacement="start"
                        label={roi.name}
                        control={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <Checkbox
                                sx={{
                                    color: COLORS.disabled,
                                    '&.Mui-checked': {
                                        color: COLORS.orange,
                                    },
                                }}
                                checked={checked[roi.id] || false}
                                onClick={() => select(roi.id, roi, cameraId)}
                            />
                        }
                    />
                ))}
            </div>

            <div className="flex flex-col gap-2">
                <Typography sx={{ fontWeight: 500 }}>
                    Line crossing
                </Typography>

                <FormControlLabel
                    control={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Checkbox
                            sx={{
                                color: COLORS.disabled,
                                '&.Mui-checked': {
                                    color: COLORS.orange,
                                },
                            }}
                            checked={checkedCameraLines[cameraId] || false}
                            onClick={(e) => {
                                e.stopPropagation()
                                selectAllLines(cameraId, linesList)
                            }}
                        />
                    }
                    label="Select All"
                    sx={{ margin: 0, color: COLORS.placeholder }}
                />

                {linesList.map((line) => (
                    <FormControlLabel
                        key={line.id}
                        sx={{ margin: 0, color: COLORS.placeholder }}
                        className="pl-3 flex-1 flex items-center justify-between pr-1 py-1 bg-defaultInpBg"
                        labelPlacement="start"
                        label={line.name}
                        control={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <Checkbox
                                sx={{
                                    color: COLORS.disabled,
                                    '&.Mui-checked': {
                                        color: COLORS.orange,
                                    },
                                }}
                                checked={checkedLines[line.id] || false}
                                onClick={() => selectLine(line.id, line, cameraId)}
                            />
                        }
                    />
                ))}
            </div>
        </CustomAccordion>
    )
}

export default function NvdsAnalytics({ attachedCameras, appName, version }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const { jetsonFilter: { jetsonDeviceId } } = useFilter()

    const [checked, setChecked] = useState({})
    const [checkedCamera, setCheckedCamera] = useState({})
    const [selected, setSelected] = useState([])

    const [checkedLines, setCheckedLines] = useState({})
    const [checkedCameraLines, setCheckedCameraLines] = useState({})
    const [selectedLines, setSelectedLines] = useState([])

    const updateROI = usePostRequest({ url: UPDATE_DEEPSTREAM_ROI })
    const updateLines = usePostRequest({ url: UPDATE_DEEPSTREAM_LINE })

    const handleRoiSelect = (id, data, cameraId) => {
        const isChecked = !checked[id]
        setChecked((prevState) => ({
            ...prevState,
            [id]: isChecked,
        }))

        if (isChecked) {
            setSelected((prev) => [...prev, { ...data, cameraId }])
        } else {
            setSelected((prev) => prev.filter((v) => v.id !== data.id))
        }
    }

    const handleSelectAll = (cameraId, data) => {
        const isAllSelected = !!checkedCamera[cameraId]

        const updatedChecked = { ...checked }
        const updatedCheckedCamera = { ...checkedCamera }
        let updatedSelected = [...selected]

        updatedCheckedCamera[cameraId] = !isAllSelected

        data.forEach((roi) => {
            updatedChecked[roi.id] = !isAllSelected
            if (!isAllSelected) {
                if (!updatedSelected.some((selectedRoi) => selectedRoi.id === roi.id)) {
                    updatedSelected.push({ ...roi, cameraId })
                }
            } else {
                updatedSelected = updatedSelected.filter((selectedRoi) => selectedRoi.id !== roi.id)
            }
        })

        setChecked(updatedChecked)
        setCheckedCamera(updatedCheckedCamera)
        setSelected(updatedSelected)
    }

    const handleLineSelect = (id, data, cameraId) => {
        const isChecked = !checkedLines[id]
        setCheckedLines((prevState) => ({
            ...prevState,
            [id]: isChecked,
        }))

        if (isChecked) {
            setSelectedLines((prev) => [...prev, { ...data, cameraId }])
        } else {
            setSelectedLines((prev) => prev.filter((v) => v.id !== data.id))
        }
    }

    const handleSelectAllLines = (cameraId, data) => {
        const isAllSelected = !!checkedCameraLines[cameraId]

        const updatedCheckedLines = { ...checkedLines }
        const updatedCheckedCameraLines = { ...checkedCameraLines }
        let updatedSelectedLines = [...selectedLines]

        updatedCheckedCameraLines[cameraId] = !isAllSelected

        data.forEach((line) => {
            updatedCheckedLines[line.id] = !isAllSelected
            if (!isAllSelected) {
                if (!updatedSelectedLines.some((selectedLine) => selectedLine.id === line.id)) {
                    updatedSelectedLines.push({ ...line, cameraId })
                }
            } else {
                updatedSelectedLines = updatedSelectedLines.filter((selectedLine) => selectedLine.id !== line.id)
            }
        })

        setCheckedLines(updatedCheckedLines)
        setCheckedCameraLines(updatedCheckedCameraLines)
        setSelectedLines(updatedSelectedLines)
    }

    const submitChanges = async () => {
        if ((!selected.length && !selectedLines.length) || updateROI.loading || updateLines.loading) return

        let roiSuccess = true
        let lineSuccess = true

        if (selected.length) {
        // Group selected ROIs by cameraId
            const roisByCamera = selected.reduce((acc, roi) => {
                const { cameraId } = roi
                if (!acc[cameraId]) {
                    acc[cameraId] = []
                }
                const coordinates = roi.points
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    .flatMap((point) => [point.x, point.y])
                acc[cameraId].push({
                    roi_id: roi.id,
                    coordinates,
                })
                return acc
            }, {})

            // Construct streams array
            const streams = Object.keys(roisByCamera).map((cameraId) => ({
                camera_id: parseInt(cameraId, 10),
                rois: roisByCamera[cameraId],
            }))

            const data = {
                applicationName: appName,
                applicationVersion: version,
                jetsonDeviceId,
                streams,
            }

            const { response, success, error } = await updateROI.request({ data })
            roiSuccess = success
            if (success) {
                showMessage(response.description, response.status === 'succeded' ? 'success-msg' : 'error-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        }

        if (selectedLines.length) {
        // Group selected Lines by cameraId
            const linesByCamera = selectedLines.reduce((acc, line) => {
                const { cameraId } = line
                if (!acc[cameraId]) {
                    acc[cameraId] = []
                }
                const coordinates = [line.dx1, line.dy1, line.dx2, line.dy2, line.x1, line.y1, line.x2, line.y2]
                acc[cameraId].push({
                    line_id: line.id,
                    coordinates,
                })
                return acc
            }, {})

            // Construct streams array
            const streams = Object.keys(linesByCamera).map((cameraId) => ({
                camera_id: parseInt(cameraId, 10),
                lines: linesByCamera[cameraId],
            }))

            const dataLines = {
                applicationName: appName,
                applicationVersion: version,
                jetsonDeviceId,
                streams,
            }

            const { response, success, error } = await updateLines.request({ data: dataLines })
            lineSuccess = success
            if (success) {
                showMessage(response.description, response.status === 'succeded' ? 'success-msg' : 'error-msg')
            } else if (error) {
                handleErrorMsg(error)
            }
        }

        if (roiSuccess && lineSuccess) {
            showMessage('Changes applied successfully', 'success-msg')
        } else {
            showMessage('Failed to apply some changes', 'error-msg')
        }
    }

    return (
        <div className="py-3.5 px-5 flex flex-col items-end gap-2.5 overflow-y-auto max-h-[100%]">
            {attachedCameras.map((camera) => (
                <List
                    key={camera.id}
                    selected={selected}
                    selectedLines={selectedLines}
                    checked={checked}
                    checkedLines={checkedLines}
                    checkedCamera={checkedCamera}
                    checkedCameraLines={checkedCameraLines}
                    cameraId={camera.id}
                    selectAll={handleSelectAll}
                    selectAllLines={handleSelectAllLines}
                    select={handleRoiSelect}
                    selectLine={handleLineSelect}
                    cameraName={camera.name}
                />
            ))}

            <PrimaryBtn
                onClick={submitChanges}
                title="Apply changes"
                loading={updateROI.loading || updateLines.loading}
                styles={{ width: 'fit-content', marginTop: '10px' }}
            />
        </div>
    )
}
