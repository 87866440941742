import React from 'react'
import { Formik, Form } from 'formik'
import { Grid } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { object, number, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { ROOMS_TENANT_UNPAGINATED, SCAMERA_PROFILES } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useLoad } from '../../../shared/hooks/requests'

const INITIAL_FORM_STATE = {
    name: '',
    deviceId: '',
    deviceMac: '',
    libPlatformVersion: '',
    softwareVersion: '',
    libAiVersion: '',
    deviceIp: '',
    timeStamp: '',
    deviceName: '',
    deviceLat: '',
    deviceLong: '',
    streamUrl: '',
    rtspUrl: '',
    username: '',
    password: '',
    type: '',
    roomId: '',
    smartCameraProfileId: '',
}

const VALIDATION_SCHEMA = object().shape({
    name: string().required('Name is required'),
    deviceId: string().required('Device Id is required'),
    deviceMac: string(),
    libPlatformVersion: string(),
    softwareVersion: string(),
    libAiVersion: string(),
    deviceIp: string(),
    timeStamp: number(),
    deviceName: string(),
    deviceLat: number(),
    deviceLong: number(),
    streamUrl: string(),
    rtspUrl: string(),
    username: string(),
    password: string(),
    type: string().required('Type is required'),
    roomId: number().required('Room is required'),
    smartCameraProfileId: number().required('Smart Camera Profile is required'),
})

const typeOptions = [
    { value: 'enter', label: 'enter' },
    { value: 'exit', label: 'exit' },
]

export default function SmartCameraForm({ initialValues, onClose, onSubmit, isEdit, loading, openUnregistered }) {
    const { identityFilter } = useFilter()
    const loadRooms = useLoad({
        url: ROOMS_TENANT_UNPAGINATED,
        params: { tenantEntityId: identityFilter.tenantEntityId },
    })
    const loadSCameraProfiles = useLoad({ url: SCAMERA_PROFILES })
    const roomOptions = loadRooms.response && loadRooms.response.length
        ? loadRooms.response.map((r) => ({ value: r.id, label: r.name })) : []
    const profileOpts = loadSCameraProfiles.response && loadSCameraProfiles.response.length
        ? loadSCameraProfiles.response.map((p) => ({ value: p.id, label: p.name })) : []

    return (
        <Formik
            enableReinitialize
            initialValues={{ ...INITIAL_FORM_STATE, ...initialValues }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
            initialTouched={{ remote: true }}
        >
            {({ handleChange, setFieldValue }) => (
                <Form className="flex flex-col justify-between p-4 gap-3">
                    <FormTextField
                        required
                        name="name"
                        label="Name"
                        handleChange={handleChange} />

                    <Grid container spacing="12px" columnSpacing={2}>
                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                required
                                name="deviceId"
                                label="Device ID"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="deviceMac"
                                label="Device Mac"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="libPlatformVersion"
                                label="Lib Platform Version"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="softwareVersion"
                                label="Software Version"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="libAiVersion"
                                label="Lib AI Version"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="deviceIp"
                                label="Device IP"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                type="number"
                                name="timeStamp"
                                label="Timestamp"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="deviceName"
                                label="Device Name"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                type="number"
                                name="deviceLat"
                                label="Device Lat"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                type="number"
                                name="deviceLong"
                                label="Device Long"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="streamUrl"
                                label="Stream Url"
                                handleChange={handleChange} />
                        </Grid>

                        <Grid item width="50%">
                            <FormTextField
                                fullWidth
                                name="rtspUrl"
                                label="RTSP Url"
                                handleChange={handleChange} />
                        </Grid>

                        {!isEdit ? (
                            <>
                                <Grid item width="50%">
                                    <FormTextField
                                        fullWidth
                                        name="username"
                                        label="Username"
                                        handleChange={handleChange} />
                                </Grid>

                                <Grid item width="50%">
                                    <FormTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        handleChange={handleChange} />
                                </Grid>
                            </>
                        )
                            : null}

                        <Grid item width="50%">
                            <SelectInput
                                required
                                fullWidth
                                name="roomId"
                                label="Room"
                                menuItems={roomOptions}
                                onChange={(e) => {
                                    setFieldValue('roomId', e.target.value)
                                }} />
                        </Grid>

                        <Grid item width="50%">
                            <SelectInput
                                required
                                fullWidth
                                name="smartCameraProfileId"
                                label="Smart Camera Profile"
                                menuItems={profileOpts}
                                onChange={(e) => {
                                    setFieldValue('smartCameraProfileId', e.target.value)
                                }} />
                        </Grid>

                        <Grid item width="50%">
                            <SelectInput
                                required
                                fullWidth
                                name="type"
                                label="Type"
                                menuItems={typeOptions}
                                onChange={(e) => {
                                    setFieldValue('type', e.target.value)
                                }} />
                        </Grid>
                        <Grid item width="100%">
                            <PrimaryBtn
                                loading={loading}
                                styles={{ mt: '1rem' }}
                                title="Unregistered cameras"
                                onClick={openUnregistered} />
                        </Grid>
                    </Grid>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
