import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import DrawerLayout from '../../../app/layouts/DrawerLayout'
import { COLORS } from '../../../shared/utils/colors'
import Button from '../../../components/common/Button'
import EntityUserForm from './EntityUserForm'
import EntityUserErrors from './EntityUserErrors'
import EntityUserScameras from './EntityUserScameras'

const navItems = [
    { id: 'details', name: 'Details' },
]

export default function EntityUserDetails({ item, onEdit, onClose, loading }) {
    const [section, setSection] = useState('details')

    console.log('item role id: ', item.roleId)

    const initialValues = {
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        pinfl: item.pinfl || '',
        jetsonDeviceId: item.jetsonDeviceId || '',
        description: item.description || '',
        phone: item.phone || '',
        email: item.email || '',
        photo: item.photo,
        roleId: item.roleId,
        fileName: item.photo ? item.photo.split('/')[item.photo.split('/').length - 1] : '',
    }

    return (
        <DrawerLayout
            topic="User details"
            onClose={onClose}
            name={item.email}
            icons={[]}>
            <React.Fragment>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li key={i.id} onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                {item.errors || item.identitySmartCameras ? (
                    <div className={cn(css(s.btnsCont), 'flex items-center gap-2_5')}>
                        <Button onClick={() => setSection('error')} className={css(s.actBtn)}>
                            Error
                        </Button>

                        <Button onClick={() => setSection('scamera')} className={css(s.actBtn)}>
                            Smartcamera
                        </Button>
                    </div>
                ) : null}

                {section === 'details' ? (
                    <EntityUserForm
                        isEdit
                        onClose={onClose}
                        onSubmit={onEdit}
                        loading={loading}
                        isUser={!!item.roleId}
                        initialValues={initialValues} />
                ) : section === 'error' ? (
                    <EntityUserErrors errors={item.errors} />
                ) : (
                    <EntityUserScameras data={item.identitySmartCameras} />
                )}
            </React.Fragment>
        </DrawerLayout>
    )
}

const s = StyleSheet.create({
    sBody: {
        padding: '9px 0',
        height: 'calc(100% - 7.5rem)',
    },
    detailNav: {
        borderBottom: `1px solid ${COLORS.sepia}`,
    },
    btnsCont: {
        padding: '12px 12px 0 12px',
    },
    navItem: {
        width: 'fit-content',
        listStyle: 'none',
        padding: '9px 10px',
        fontSize: 16,
        fontWeight: '500',
        position: 'relative',
        color: COLORS.disabled,
        cursor: 'pointer',
        paddingBottom: 8,
        transition: 'color 0.3s ease',
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -1,
            width: 0,
            borderBottom: `solid 1.5px ${COLORS.mainBlueBg}`,
            transition: 'width 0.3s ease',
        },
        ':hover': {
            color: COLORS.mainBlueBg,
        },
        ':hover::after': {
            width: '100%',
        },
    },
    active: {
        color: COLORS.mainBlueBg,
        ':after': {
            width: '100%',
        },
    },
    actBtn: {
        borderRadius: 5,
        background: COLORS.mainBlueBg,
        padding: '8px 12px',
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
    },
})
