import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { ATTENDACE_TYPE, ATTENDACE_TYPE_CUSTOMER } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useLoad } from '../../../shared/hooks/requests'
import Table from '../../../shared/ui/Table'
import AttendanceAnalyticsFilter from './AttendanceAnalyticsFilter'
import AttendaceTypeTableItem from './AttendanceAnalyticsTableItem'
import SearchInput from '../../../shared/ui/SearchInput'

const cols = [
    { id: 1, title: 'Attendance ID' },
    { id: 2, title: 'Identity ID' },
    { id: 3, title: 'Main photo', padding: '0 0 0 16px' },
    { id: 4, title: 'Attendance photo' },
    { id: 5, title: 'Tenant entity name' },
    { id: 6, title: 'First name' },
    { id: 7, title: 'Last name' },
    { id: 8, title: 'PINFL' },
    { id: 9, title: 'Created at' },
    { id: 10, title: 'Comp_Score' },
    { id: 11, title: 'Spoofing result' },
    { id: 12, title: 'Spoofing score' },
    { id: 13, title: 'App Version Name' },
    { id: 14, title: 'Device Model' },
    { id: 15, title: 'Is mobile' },
    { id: 16, title: 'Event type' },
]

export default function AttendanceAnalyticsTable() {
    const { attendaceFilter, setAttendanceFilters } = useFilter()
    const { dateFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const userType = localStorage.getItem('userType')
    const formatUrl = userType === 'tenant_admin' ? ATTENDACE_TYPE : ATTENDACE_TYPE_CUSTOMER
    const loadAttendace = useLoad(
        {
            url: formatUrl,
            params: {
                page,
                size: pageSize,
                start_date: dateFilter.startPeriod,
                end_date: dateFilter.endPeriod,
                ...(attendaceFilter.search !== '' ? { search: attendaceFilter.search } : {}),
                ...(attendaceFilter.entity !== ''
                    && attendaceFilter.region !== '' ? { tenant_entity_id: attendaceFilter.entity } : {}),
                ...(attendaceFilter.region !== '' ? { region_id: attendaceFilter.region } : {}),
                ...(attendaceFilter.district !== ''
                    && attendaceFilter.region !== '' ? { district_id: attendaceFilter.district } : {}),
                ...(attendaceFilter.mobile !== '' ? { by_mobile: attendaceFilter.mobile } : {}),
                ...(attendaceFilter.unique !== '' ? { unique: attendaceFilter.unique } : {}),
                ...(attendaceFilter.eventType !== '' ? { event_type: attendaceFilter.eventType } : {}),
                ...(attendaceFilter.consumer_from !== '' ? { from_comp_score: attendaceFilter.consumer_from } : {}),
                ...(attendaceFilter.consumer_to !== '' && attendaceFilter.consumer_from !== ''
                    ? { to_comp_score: attendaceFilter.consumer_to } : {}),
                ...(attendaceFilter.identityGroup !== '' ? { identityGroup: attendaceFilter.identityGroup } : {}),
            },
        },
        [page, pageSize, attendaceFilter, dateFilter],
    )
    const data = loadAttendace.response ? loadAttendace.response.items : []
    const meta = loadAttendace.response ? loadAttendace.response.total : {}

    useEffect(() => {
        setPage(1)
    }, [attendaceFilter, dateFilter])

    const handleSearch = (value) => {
        setAttendanceFilters({ search: value })
    }

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <AttendanceAnalyticsFilter />
            </div>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Identity Attendance Analytics
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadAttendace.loading}
                    totalCount={meta}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <AttendaceTypeTableItem
                            item={item}
                            key={item.id}
                            setData={loadAttendace.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
